import { FC } from 'react'

import _isEmpty from 'lodash/isEmpty'

import stringToColor from 'string-to-color'
import getLettersFromName from './helpers/get-letters-from-name'

import { IAvatar } from 'src/common/interfaces'
import useStyles from './styles'

const Avatar: FC<IAvatar>= ({
  online,
  avatar,
  userName,
  size = 40,
}) => {
  const classes = useStyles()

  return (
    <div style={{maxWidth: `${size}px`}} className={classes.wrapper}>
      <div
        style={_isEmpty(avatar) ?
          {backgroundColor: stringToColor(userName), width: `${size}px`, height: `${size}px`, borderRadius: `${size}px`}
          :
          {width: `${size}px`, height: `${size}px`, borderRadius: `${size}px`}
        }
        className={classes.avatarWrapper}
      >
        {!_isEmpty(avatar) ?
          <img className={classes.image} src={avatar} alt={`writer ${userName}`}  style={{minHeight: `${size}px`, maxHeight: `${size}px`}}/> :
          userName && <p className={classes.letters}>{!_isEmpty(userName) && getLettersFromName(userName)}</p>
        }
      </div>
      {online && <div className={classes.onlineBadge} />}
    </div>
  )
}

export default Avatar
