import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  rigthSidebar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '287px',
    minWidth: '287px',
    maxWidth: '287px',
    overflow: 'scroll',
  },

  emptyBlock: {
    width: '100%',
    height: '100%',
    background: '#F1F2F4',
  },
})

export default makeStyles(styles)
