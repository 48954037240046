import { createRef, FC, LegacyRef, useCallback, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import { setArchiveListEndVisibility, setCurrentChatRoomData, setCurrentGroupData } from 'src/store/actions/appControl'

import useStyles from './styles'
import ChatListItem from './chat-list-item'
import { useDispatch, useSelector } from 'react-redux'
import { IStore } from 'src/store/types'
import { ClipLoader } from 'react-spinners'
import ArchiveHeader from '../archive-header'
import { fetchMessagesData } from 'src/store/actions/messagesData'

interface IChatList {
}

const ArchiveList: FC<IChatList> = () => {
  const classes = useStyles()

  const [ref, inView] = useInView()
  const archiveListWrapperRef = createRef<HTMLElement>()

  const dispatch = useDispatch()
  const handleSetArchiveListEndInVisibility = useCallback((node) => dispatch(setArchiveListEndVisibility(node)), [dispatch])
  const handleSetCurrentChatGroupData = useCallback((orderId, data) => dispatch(setCurrentGroupData(orderId, data)), [dispatch])
  const handleSetCurrentChatRoomData = useCallback((data) => dispatch(setCurrentChatRoomData(data)), [dispatch])
  const handleFetchMessagesByRoom = useCallback((orderId, writerId) => dispatch(fetchMessagesData(orderId, writerId)), [dispatch])

  const archiveData = useSelector((state: IStore) => state.archiveData.data)
  const fetchArchivePending = useSelector((state: IStore) => state.archiveData.fetchArchivePending)
  const currentChatGroupData = useSelector((state: IStore) => state.appData.currentChatGroupData)
  const currentRoomData = useSelector((state: IStore) => state.appData.currentChatRoomData)
  const messages = useSelector((state: IStore) => state.messagesData.data)

  useEffect(() => {
    handleSetArchiveListEndInVisibility(inView)
  }, [inView, handleSetArchiveListEndInVisibility])

  const handleSetCurrentGroupData = useCallback((orderId: number) => {
    archiveData?.size && handleSetCurrentChatGroupData(orderId, archiveData.get(orderId))
    handleSetCurrentChatRoomData(null)
  }, [archiveData, handleSetCurrentChatGroupData, handleSetCurrentChatRoomData])

  const handleSetCurrentRoomData = useCallback((orderId: number, writerId: number) => {
    archiveData?.size && handleSetCurrentChatGroupData(orderId, archiveData.get(orderId))
    archiveData?.size && archiveData.get(orderId) && handleSetCurrentChatRoomData(archiveData.get(orderId)?.chats[`${writerId}`])

    if (!messages[`${orderId}${writerId}`]?.fetched) {
      handleFetchMessagesByRoom(orderId, writerId)
    }
  }, [
    archiveData,
    messages,
    handleFetchMessagesByRoom,
    handleSetCurrentChatGroupData,
    handleSetCurrentChatRoomData,
  ])

  return (
    <>
      <ArchiveHeader archiveListWrapperRef={archiveListWrapperRef}/>
      <div className={classes.chatListWrapper} ref={archiveListWrapperRef as LegacyRef<HTMLDivElement> | undefined}>
        {archiveData && Array.from(archiveData).map(([key, value], index) => (
          <ChatListItem
            key={key}
            groupData={value}
            groupIndex={index}
            currentChatGroupData={currentChatGroupData}
            currentRoomData={currentRoomData}
            handleSetCurrentGroupData={handleSetCurrentGroupData}
            handleSetCurrentRoomData={handleSetCurrentRoomData}
          />
        ))}
        {fetchArchivePending && (
          <div className={classes.clipLoaderWrapper}>
            <ClipLoader size={'25px'} />
          </div>
        )}
        <div ref={ref as LegacyRef<HTMLDivElement> | undefined} />
      </div>
    </>
  )
}

export default ArchiveList
