import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  roomsWrapper: {
    position: 'relative',
    height: '0px',
    overflow: 'hidden',
  },

  chatGroupWrapper: {
    transition: 'border 0.3s',
  },

  activeGroupWrapper: {
    borderLeft: '3px solid #28447B',

    '& $roomsWrapper': {
      height: '100%',
      overflow: 'initial',
    },
  }
})

export default makeStyles(styles)
