import { FC, useCallback, useEffect, SetStateAction, Dispatch } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _isEmpty from 'lodash/isEmpty'

import { fetchTemplatePhrases } from 'src/store/actions/templatePhrases'
import { IStore } from 'src/store/types'

import useStyles from './styles'
import TemplatePhrasesSearchBox from './template-phrases-search-box'

interface ITemplatesButton {
  message: string
  setMessage: Dispatch<SetStateAction<string>>
  showTemplatePhrases: boolean
  setShowTemplatePhrases: Dispatch<SetStateAction<boolean>>
  setActiveTemplateIndex: Dispatch<SetStateAction<number>>
  activeTemplateIndex: number
  handleChangeTemplateValue: (id: string) => void
  handleAddTemplateToMessageText: () => void
  templateValue: string
  setTemplateValue: Dispatch<SetStateAction<string>>
  templatesListRef: any
  textAreaRef: any
}

const TemplatesButton: FC<ITemplatesButton> = ({
  message,
  setMessage,
  showTemplatePhrases,
  setShowTemplatePhrases,
  setActiveTemplateIndex,
  activeTemplateIndex,
  handleChangeTemplateValue,
  handleAddTemplateToMessageText,
  templateValue,
  setTemplateValue,
  templatesListRef,
  textAreaRef,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const handleFetchTemplatePhrases = useCallback(() => dispatch(fetchTemplatePhrases()), [dispatch])

  const templatePhrasesList = useSelector((state: IStore) => state.templatePhrases.data)

  useEffect(() => {
    _isEmpty(templatePhrasesList) && handleFetchTemplatePhrases()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClick = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()

    !showTemplatePhrases && setMessage(`${message} #`)
    setShowTemplatePhrases(!showTemplatePhrases)

    // @ts-ignore
    textAreaRef.current && textAreaRef.current.focus()
  }, [setMessage, message, setShowTemplatePhrases, showTemplatePhrases, textAreaRef])

  return (
    <>
      <div className={classes.templatesButton} role='button' onClick={handleClick}>#</div>
      {showTemplatePhrases && (
        <TemplatePhrasesSearchBox
          message={message}
          templatePhrasesList={templatePhrasesList}
          activeTemplateIndex={activeTemplateIndex}
          handleChangeTemplateValue={handleChangeTemplateValue}
          handleAddTemplateToMessageText={handleAddTemplateToMessageText}
          templateValue={templateValue}
          setTemplateValue={setTemplateValue}
          setActiveTemplateIndex={setActiveTemplateIndex}
          templatesListRef={templatesListRef}
          textAreaRef={textAreaRef}
        />
      )}
    </>
  )
}

export default TemplatesButton
