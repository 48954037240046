import { APIv1 } from 'src/api'

import { call, put } from 'redux-saga/effects'

import {
  FETCH_TEMPLATE_PHRASES_SUCCEEDED,
  FETCH_TEMPLATE_PHRASES_PENDING,
  FETCH_TEMPLATE_PHRASES_FAILED,
} from 'src/store/types/templatePhrases'

const fetchTemplatePhrasesRequest = (): Promise<any> => APIv1.get('/data/template-phrases.json')

export default function* fetchTemplatePhrases(action: any) {
  try {
    yield put({ type: FETCH_TEMPLATE_PHRASES_PENDING })

    // @ts-ignore
    const result = yield call(fetchTemplatePhrasesRequest)

    yield put({
      type: FETCH_TEMPLATE_PHRASES_SUCCEEDED,
      payload: result.data
    })
  } catch (e) {
    yield put({ type: FETCH_TEMPLATE_PHRASES_FAILED, payload: e })
  }
}
