import { FC } from 'react'
import { useSelector } from 'react-redux'
import ClipLoader from 'react-spinners/ClipLoader'

import ChatArea from './chat-area'

import {
  useListenSocketIncoming,
  useSocketConnect,
} from 'src/socket/socketControl'

import { IStore } from 'src/store/types'

import useStyles from './styles'
import { NavbarButtonValues } from 'src/common/constants'
import LeftSidebar from './left-side-bar'
import RightSidebar from './right-sidebar'
import AdminMenu from './admin-menu'
import ChatHelmet from './ChatHelmet'

const Chat: FC = () => {
  const classes = useStyles()

  const showAdminMenu = useSelector((state: IStore) => state.adminData.showAdminMenu)
  const isPendingChatsData = useSelector((state: IStore) => state.chatData.pending)
  const currentChatGroupData = useSelector((state: IStore) => state.appData.currentChatGroupData)
  const currentRoomData = useSelector((state: IStore) => state.appData.currentChatRoomData)
  const activeTab = useSelector((state: IStore) => state.appData.activeNavbarTab)
  const supportConnected = useSelector((state: IStore) => state.appData.supportConnected)

  useSocketConnect()
  useListenSocketIncoming()

  if (showAdminMenu) {
    return <AdminMenu />
  }

  if (isPendingChatsData) {
    return (
      <div className={classes.chatWrapper}>
        <div className={classes.spinnerWrapper}>
          <ClipLoader />
        </div>
      </div>
    )
  }

  return (
    <>
      <ChatHelmet />
      <div className={classes.chatWrapper}>
        <LeftSidebar
          activeTab={activeTab}
          currentChatGroupData={currentChatGroupData}
          currentRoomData={currentRoomData}
        />
        <ChatArea
          currentChatGroupData={currentChatGroupData}
          currentRoomData={currentRoomData}
          // this is kostul' - possibility to write messages from archive
          // hideFormInput={currentRoomData?.closed || activeTab === NavbarButtonValues.ARCHIVE || !supportConnected}
          hideFormInput={currentRoomData?.closed || !supportConnected}
        />
        <RightSidebar
          currentChatGroupData={currentChatGroupData}
        />
      </div>
    </>
  )
}

export default Chat
