import { useRef, useEffect } from 'react'
import throttle from 'lodash/throttle'

const _useEventListener = (element: HTMLElement, eventName: string, handler: any, dependencies: any[] = [], { useThrottle = false, delay = 100 } = {}) => {
  const savedHandler = useRef()

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(
    () => {
      const isSupported = element && element.addEventListener

      if (!isSupported) {
        return
      }

      // @ts-ignore
      const eventListener = (event) => savedHandler.current(event)

      const updatedEventListener =
        useThrottle ?
          throttle((e) => { eventListener(e) }, delay)
          :
          eventListener

      element.addEventListener(eventName, updatedEventListener)
      return () => {
        element.removeEventListener(eventName, updatedEventListener)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventName, element, ...dependencies],
  )
}

export default _useEventListener
