import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  adminSidebar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '249px',
    minWidth: '249px',
    maxWidth: '249px',
    maxHeight: '100vh',
  },

  navWrapper: {
    position: 'relative',
    height: '100%',
    maxHeight: 'calc(100vh - 60px)',
    borderRight: '1px solid #E6E6E6',
    overflowY: 'scroll',
  },

  navItem: {
    padding: '12px 20px',
    cursor: 'pointer',
    transition: 'background 0.3s, border 0.3s',

    '&:hover': {
      background: 'rgba(40, 68, 123, 0.03)',
    }
  },

  navActive: {
    background: 'rgba(40, 68, 123, 0.03)',
    borderLeft: '3px solid #28447B',

    '& $navItemTitle': {
      fontWeight: 700,
    },
  },

  navItemTitle: {

  },

  chatsMenuWrapper: {

  },

  navItemChats: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '&:hover': {
      '& $icon': {
        width: '14px',
        height: '7px',
      },
    }
  },

  chatsExpanded: {

  },

  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '14px',
    height: '100%',
    transition: 'transform 0.3s',
  },

  iconWrapperExpanded: {
    transform: 'rotateX(180deg)',
  },

  icon: {
    width: '11px',
    height: '6px',
  },

  chatsSubMenu: {
    paddingLeft: '20px',
  },
})

export default makeStyles(styles)
