import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  supportsListItemsWrapper: {
  },

  spinnerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },

  supportListItemWrapper: {
    marginBottom: '10px',
    padding: '7px 10px',
    borderRadius: '15px',
    background: '#FFFFFF',
  },
})

export default makeStyles(styles)
