import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  writerInfoWrapper: {
    position: 'absolute',
    top: '0',
    right: '0',
    display: 'flex',
    zIndex: 99,
  },

  writerInfoOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.3)',
    cursor: 'pointer',
  },

  writerInfoInner: {
    position: 'absolute',
    top: '0',
    right: '-660px',
    width: '600px',
    minWidth: '600px',
    height: '100vh',
    maxHeight: '100vh',
    minHeight: '100vh',
    background: '#fff',
    padding: '0 30px',
    overflowY: 'scroll',
    transition: 'transform 0.4s ease',
  },

  writerInfoInnerOpened: {
    transform: 'translate(-660px, 0)',
  },

  writerInfoHeader: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: '27px',
    paddingTop: '40px',
  },

  writerInfoImageWrapper: {
    display: 'flex',
    width: '134px',
    height: '134px',
    marginRight: '27px',
    borderRadius: '10px',
    overflow: 'hidden',
  },

  writerInfoImage: {
    objectFit: 'cover',
  },

  headerDetaisWrapper: {
  },

  headerDetaisMainInfo: {
    marginBottom: '18px'
  },

  mainInfoNameWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 3px',
  },

  mainInfoName: {
    marginRight: '4px',
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '21px',
    color: '#333',
  },

  headerDetaisStatsWrapper: {
    display: 'flex',
  },

  headerDetaisStatsItem: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% / 3)',

    '&:not(:first-child)': {
      paddingLeft: '28px',
    },

    '&:not(:last-child)': {
      paddingRight: '28px',
      borderRight: '1px solid rgba(0, 0, 0, 0.1)',
    },
  },

  statsItemValue: {
    margin: '0 0 3px',
    fontSize: '26px',
    fontWeight: 700,
    lineHeight: '25px',
    color: '#28447B',
  },

  statsItemDescription: {
    margin: '0',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#333',
  },

  writerInfoBioWrapper: {
    marginBottom: '14px',
  },

  writerInfoBioHeaderWrapper: {
    display: 'flex',
    alignItems: 'center',
  },

  writerInfoBioIconWrapper: {
    width: '14px',
    height: '18px',
    marginRight: '8px',
  },

  writerInfoBioHeader: {
    margin: '0',
    fontSize: '22px',
    lineHeight: '26px',
    color: '#28447B',
  },

  writerInfoBioText: {
    margin: '0',
    fontSize: '16px',
    lineHeight: '26px',
    color: '#333',
  },

  writerInfoFooter: {
    paddingBottom: '30px',
  },

  writerInfoFooterTitle: {
    margin: '0 0 7px',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#333',
  },

  subjectsList: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },

  subjectsListItem: {
    margin: '0 0 10px',
    padding: '0 9px',
    borderRadius: '20px',
    background: 'rgba(50, 3, 157, 0.05)',
    fontSize: '14px',
    lineHeight: '22px',
    color: '#337AB7',

    '&:not(:last-child)': {
      marginRight: '5px',
    },
  },
})

export default makeStyles(styles)
