import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  supportsModalContent: {
    width: '100%',
  },

  modalTitle: {
    margin: 0,
    padding: '16px 0 14px',
    textAlign: 'center',
    color: '#28447B',
    fontSize: '20px',
    lineHeight: '23px',
    background: '#FAFAFA',
    borderRadius: '10px 10px 0 0',
  },

  modalControlsWrapper: {
    padding: '20px',
  },

  modalInput: {
    marginBottom: '10px',
    width: '100%',
    padding: '12px 16px 14px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '10px',
    background: '#F2F3F5',
  },

  modalButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '45px',
    width: '100%',
    border: 'none',
    borderRadius: '5px',
    background: '#28447B',
    color: '#FFFFFF',
    cursor: 'pointer',
    transition: 'background 0.3s',

    '&:hover': {
      background: 'hsl(220, 51%, 42%)',
    }
  },

  dropzoneWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginBottom: '10px',
    padding: '25px 0',
    border: '1px dashed rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
    background: 'rgba(240, 240, 240, 0.75)',
    cursor: 'pointer',
  },

  dropzoneWrapperActive: {
    background: 'rgba(240, 240, 240, 1)',
  },

  dropzoneText: {
    margin: '0 0 0 6px',
    padding: '0 10px',
    color: '#336699',
    fontSize: '16px',
    lineHeight: '19px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
})

export default makeStyles(styles)
