import { FC, useState } from 'react'
import Modal from 'src/common/modal'

import useStyles from './styles'
import SupportsHeader from './supports-header'
import SupportsList from './supports-list'
import SupportsModalInner from './supports-modal'

const SupportsView: FC = () => {
  const classes = useStyles()
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  return (
    <>
      <div className={classes.supportsViewWrapper}>
        <div className={classes.supportsViewInner}>
          <SupportsHeader
            setModalOpen={setModalOpen}
          />
          <SupportsList />
        </div>
      </div>
      <Modal
        open={modalOpen}
        setOpen={setModalOpen}
      >
        <SupportsModalInner setModalOpen={setModalOpen} />
      </Modal>
    </>
  )
}

export default SupportsView
