import { FC, useCallback, useState } from 'react'
import clsx from 'clsx'

import Avatar from 'src/components/chat/avatar'
import { ISupportItem } from 'src/store/reducers/admin-data'

import useStyles from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { IStore } from 'src/store/types'
import { deleteUser } from 'src/store/actions/adminData'
import EditView from './edit-view'
import notifyer from 'src/common/notifyer'

interface ISupportListItem {
  support: ISupportItem
}

const SupportListItem: FC<ISupportListItem> = ({ support }) => {
  const classes = useStyles()
  const [editMode, setEditMode] = useState<boolean>(false)

  const deleteUserPending = useSelector((state: IStore) => state.adminData.deleteUserPending)

  const dispatch = useDispatch()
  const handleDeleteUser = useCallback(() => dispatch(deleteUser(support.id!)), [dispatch, support])

  const handleEdit = useCallback(() => {
    if (support.status) {
      notifyer.warning('Edit mode is disabled for ONLINE users')

      return
    }

    setEditMode(true)
  }, [support, setEditMode])

  const handleDelete = useCallback(() => {
    if (support.status) {
      notifyer.warning('You cannot delete the currently ONLINE user')

      return
    }

    if(window.confirm(`Are you sure to delete ${support.name} account`)) {
      handleDeleteUser()
    } else {
      return
    }
  }, [support, handleDeleteUser])

  if (editMode) {
    return <EditView support={support} setEditMode={setEditMode} />
  }

  return (
    <div className={clsx(classes.supportListItemWrapper, classes.grid)}>
      <div className={classes.avatarWrapper}>
        <Avatar
          avatar={support.avatar}
          size={37}
          online={support.status}
        />
      </div>
      <p className={classes.supportName}>{support.name}</p>
      <p className={classes.supportEmail}>{support.email}</p>
      <p className={classes.supportPassword}>********</p>
      <div className={classes.buttonsGroup}>
        <button className={clsx(classes.editButton, classes.button)} onClick={handleEdit}>
          Edit
        </button>
        <button disabled={deleteUserPending} className={clsx(classes.deleteButton, classes.button)} onClick={handleDelete}>
          Delete
        </button>
      </div>
    </div>
  )
}

export default SupportListItem
