import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { persistStore } from 'redux-persist'

import reducers from 'src/store/reducers'
import sagas from 'src/store/sagas'
import thunk from 'redux-thunk'

const sagaMiddleware = createSagaMiddleware()

export const store = createStore(combineReducers(reducers), compose(applyMiddleware(
  sagaMiddleware,
  thunk,
)))

export const persistor = persistStore(store)

for (const saga of sagas) {
  sagaMiddleware.run(saga)
}
