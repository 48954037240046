import { Dispatch } from 'redux'

import {
  CREATE_TEMPLATE_SAGA,
  DELETE_TEMPLATE_SAGA,
  EDIT_TEMPLATE_SAGA,
  FETCH_TEMPLATE_PHRASES_SAGA, SET_FILTERED_TEMPLATES_KEYS,
} from 'src/store/types/templatePhrases'
import { ITemplatePhrase } from '../reducers/template-phrases'
import { ITemplateToEdit } from '../sagas/admin-edit-template/editTemplate'

export const fetchTemplatePhrases = () => (dispatch: Dispatch) => {
  dispatch({
    type: FETCH_TEMPLATE_PHRASES_SAGA,
  })
}

export const setFilteredTemplatesKeys = (keysArray: string[]) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_FILTERED_TEMPLATES_KEYS,
    payload: keysArray,
  })
}

export const createTemplate = (template: ITemplatePhrase) => (dispatch: Dispatch) => {
  dispatch({
    type: CREATE_TEMPLATE_SAGA,
    payload: template,
  })
}

export const editTemplate = (template: ITemplateToEdit) => (dispatch: Dispatch) => {
  dispatch({
    type: EDIT_TEMPLATE_SAGA,
    payload: template,
  })
}

export const deleteTemplate = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: DELETE_TEMPLATE_SAGA,
    payload: id,
  })
}
