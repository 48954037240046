import { Dispatch, useCallback, useEffect } from "react";
import { AnyAction } from "redux";
import { Socket } from "socket.io-client";
import notifyer from "src/common/notifyer";

export const useChatClosed = (socket: Socket, dispatch: Dispatch<any>) => {
  const handleCloseChat = useCallback((data) => dispatch({ ...data }), [dispatch]) // SOCKET_CHAT_CLOSED

  useEffect(() => {
    const handler = (data: AnyAction) => {
      handleCloseChat(data)

      !data.payload.silent && notifyer.warning(`One of chats was closed by order #${data.payload.orderId}`)
    }

    socket.on('chat-closed', handler)
    return () => {
      socket.off('chat-closed', handler)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
