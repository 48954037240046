import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'

import Avatar from '../../avatar'

import { ReactComponent as ChevronIcon } from 'src/media/images/chevronIcon.svg'

import useStyles from './styles'
import SupportControlsMenu from './controls-menu'
import { IStore } from 'src/store/types'

const SupportControl: FC = () => {
  const classes = useStyles()
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const supportConnected = useSelector((state: IStore) => state.appData.supportConnected)
  const authData = useSelector((state: IStore) => state.authData.data)

  const toggleMenu = () => {
    setOpenMenu(!openMenu)
  }

  return (
    <div className={classes.supportControlWrapper}>
      <SupportControlsMenu open={openMenu} setOpenMenu={setOpenMenu} />
      <div className={classes.supportControlInner} onClick={toggleMenu}>
        <div className={classes.supportMainInfo}>
          <Avatar
            userName={authData.supportName}
            avatar={authData.supportAvatar}
            online={supportConnected}
          />
          <p className={classes.supportName}>
            {authData.supportName}
          </p>
        </div>
        <div className={clsx(classes.supportIconButton, openMenu && classes.supportIconButtonOpened)}>
          <ChevronIcon className={classes.icon} />
        </div>
      </div>
    </div>
  )
}

export default SupportControl
