import moment from 'moment'
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { findArchive } from 'src/store/actions/archiveData'
import { IArchiveRequestProps } from 'src/store/sagas/find-in-archive/fetchArchive'
import { IStore } from 'src/store/types'

interface IUsePagination {
  page: number
  setPage: Dispatch<SetStateAction<number>>
  orderId: number | undefined
  supportIds: string[]
  startDate: Date | null
  endDate: Date | null
}

export const usePagination = ({
  page,
  setPage,
  orderId,
  supportIds,
  startDate,
  endDate,
}: IUsePagination) => {

  const dispatch = useDispatch()
  const handleFindArchive = useCallback((options : IArchiveRequestProps) => dispatch(findArchive({...options})), [dispatch])

  const archiveListEndVisibility = useSelector((state: IStore) => state.appData.archiveListEndVisibility)
  const archiveChatsData = useSelector((state: IStore) => state.archiveData.data)
  const fetchArchivePending = useSelector((state: IStore) => state.archiveData.fetchArchivePending)
  const emptyArchiveResponseRecived = useSelector((state: IStore) => state.archiveData.emptyResponseRecived)

  useEffect(() => {
    if (!emptyArchiveResponseRecived && !fetchArchivePending && archiveListEndVisibility && archiveChatsData && archiveChatsData.size) {
      handleFindArchive({
        page: page + 1,
        orderId,
        supportIds,
        dateFrom: moment(startDate).startOf('day').valueOf(),
        dateTo: moment(endDate).endOf('day').valueOf(),
      })
      setPage(page + 1)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archiveListEndVisibility, emptyArchiveResponseRecived])
}
