import React, { FC, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _isEmpty from 'lodash/isEmpty'

import { fetchTemplatePhrases } from 'src/store/actions/templatePhrases'
import { IStore } from 'src/store/types'

import useStyles from './styles'
import TemplateItem from './template-item'
import { ClipLoader } from 'react-spinners'

const TemplatesList: FC = () => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const handleFetchTemplatePhrases = useCallback(() => dispatch(fetchTemplatePhrases()), [dispatch])

  const templatePhrasesList = useSelector((state: IStore) => state.templatePhrases.data)
  const templateListChanged = useSelector((state: IStore) => state.templatePhrases.templateListChanged)
  const pending = useSelector((state: IStore) => state.templatePhrases.pending)

  useEffect(() => {
    _isEmpty(templatePhrasesList) && handleFetchTemplatePhrases()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (templateListChanged) {
      handleFetchTemplatePhrases()
    }
  }, [templateListChanged, handleFetchTemplatePhrases])

  if (pending) {
    return (
      <div className={classes.templatesListWrapper}>
        <div className={classes.spinnerWrapper}>
          <ClipLoader />
        </div>
      </div>
    )
  }

  return (
    <div className={classes.templatesListWrapper}>
      {Object.entries(templatePhrasesList).map(([key, item]) => (
        <React.Fragment key={key}>
          <TemplateItem template={item} />
        </React.Fragment>
      ))}
    </div>
  )
}

export default TemplatesList
