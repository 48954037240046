export const FETCH_CHAT_DATA_SAGA: 'FETCH_CHAT_DATA_SAGA' = 'FETCH_CHAT_DATA_SAGA'
export const FETCH_CHAT_DATA_SUCCEEDED: 'FETCH_CHAT_DATA_SUCCEEDED' = 'FETCH_CHAT_DATA_SUCCEEDED'
export const FETCH_CHAT_DATA_FAILED: 'FETCH_CHAT_DATA_FAILED' = 'FETCH_CHAT_DATA_FAILED'
export const FETCH_CHAT_DATA_PENDING: 'FETCH_CHAT_DATA_PENDING' = 'FETCH_CHAT_DATA_PENDING'

export const SOCKET_CLIENT_WAITING: 'SOCKET_CLIENT_WAITING' = 'SOCKET_CLIENT_WAITING'
export const SOCKET_CLIENT_QUEUED: 'SOCKET_CLIENT_QUEUED' = 'SOCKET_CLIENT_QUEUED'
export const SOCKET_WRITER_PAID: 'WRITER_PAID' = 'WRITER_PAID'
export const SOCKET_TOOK_CHAT: 'WORKER_TOOK_CHAT' = 'WORKER_TOOK_CHAT'
export const SOCKET_NEW_WRITER_BY_ORDER: 'CHAT_INITIATED' = 'CHAT_INITIATED'
export const SOCKET_CHAT_GROUP_CLOSED: 'CHAT_GROUP_CLOSED' = 'CHAT_GROUP_CLOSED'
export const SOCKET_CHAT_CLOSED: 'CHAT_CLOSED' = 'CHAT_CLOSED'
export const SOCKET_CHAT_UPDATED: 'CHAT_UPDATED' = 'CHAT_UPDATED'

export const FETCH_NEW_GROUP_SUCCEEDED: 'FETCH_NEW_GROUP_SUCCEEDED' = 'FETCH_NEW_GROUP_SUCCEEDED'
export const FETCH_NEW_GROUP_FAILED: 'FETCH_NEW_GROUP_FAILED' = 'FETCH_NEW_GROUP_FAILED'
export const FETCH_NEW_GROUP_PENDING: 'FETCH_NEW_GROUP_PENDING' = 'FETCH_NEW_GROUP_PENDING'

export const DECREMENT_UNREAD_QUANTITY: 'DECREMENT_UNREAD_QUANTITY' = 'DECREMENT_UNREAD_QUANTITY'
export const INCREMENT_UNREAD_QUANTITY: 'INCREMENT_UNREAD_QUANTITY' = 'INCREMENT_UNREAD_QUANTITY'

export const UPDATE_LAST_ROOM_MESSAGE: 'UPDATE_LAST_ROOM_MESSAGE' = 'UPDATE_LAST_ROOM_MESSAGE'
export const ORDER_DETAILS_UPDATE_VIEWED: 'ORDER_DETAILS_UPDATE_VIEWED' = 'ORDER_DETAILS_UPDATE_VIEWED'

export const UPDATE_UNANSWERED_CHATS: 'UPDATE_UNANSWERED_CHATS' = 'UPDATE_UNANSWERED_CHATS'
