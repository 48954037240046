import { FC } from 'react'
import _isEmpty from 'lodash/isEmpty'

import OrderDetails from './order-details'
import ClientInfo from './client-info'

import useStyles from './styles'
import { IOrderChatGroup } from 'src/common/interfaces'

interface IRightSidebar {
  currentChatGroupData: IOrderChatGroup | null
}

const RightSidebar: FC<IRightSidebar> = ({
	currentChatGroupData,
}) => {
  const classes = useStyles()

  if (!currentChatGroupData || (currentChatGroupData && _isEmpty(currentChatGroupData))) {
    return null
  }

  const activeChatsCount = Object.values(currentChatGroupData.chats).filter((chat) => {
    return !chat.closed
  }).length

  return (
    <div className={classes.rigthSidebar}>
      <OrderDetails
        order={currentChatGroupData.order}
      />
      <ClientInfo
        activeChatsCount={activeChatsCount}
        customer={currentChatGroupData.customer}
      />
    </div>
  )
}

export default RightSidebar
