import { Dispatch, useCallback, useEffect } from "react";
import { Socket } from "socket.io-client";
import { IMessageData } from "src/common/interfaces";
import { incrementUnreadQuantityOnNewMessage, updateLastRoomMessage } from "src/store/actions/chatData";
import { setNewMessageFromWS, updateTypedData } from "src/store/actions/messagesData";

import newMessageSound from 'src/media/sounds/incoming_message.wav'
import { playAudio } from "src/common/play-audio";
import { UserRole } from "src/common/constants";

export const useEmitMessage = (socket: Socket, dispatch: Dispatch<any>) => {
  const handleSetNewMessage = useCallback((message) => dispatch(setNewMessageFromWS(message)), [dispatch])
  const handleIncrementUnreadQuantity = useCallback((message: IMessageData) => dispatch(
    incrementUnreadQuantityOnNewMessage(
      message.sender.role,
      message.identifiyers.group,
      message.identifiyers.identifyer
    )
  ), [dispatch])

  const handleUpdateLastRoomMessage = useCallback((message: IMessageData) => dispatch(
    updateLastRoomMessage(
      message,
    )
  ), [dispatch])

  const handleUpdateTypedData = useCallback((message: IMessageData) => dispatch(
    updateTypedData({
      message: '',
      group: message.identifiyers.group,
      identifyer: message.identifiyers.identifyer,
    })
  ), [dispatch])

  useEffect(() => {
    const sound = new Audio(newMessageSound)
    playAudio({ sound, muted: true, volume: 0.2 }) // the audio won't play the first time it is loaded when the tab is unfocused as a browser performance optimization mechanic - this solve the problem

    const handler = (message: IMessageData) => {
      handleSetNewMessage(message)
      handleIncrementUnreadQuantity(message)
      handleUpdateLastRoomMessage(message)

      if(message && message?.sender?.role && message.sender.role === UserRole.CLIENT) {
        playAudio({ sound })

        handleUpdateTypedData(message)
      }
    }

    socket.on('emit-msg', handler)
    return () => {
      socket.off('emit-msg', handler)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
