import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  controlsMenuWrapper: {
    position: 'relative',
    maxHeight: 0,
    overflow: 'hidden',
    borderRight: '1px solid #E6E6E6',
    borderRadius: '8px 8px 0px 0px',
    boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.15)',
    transition: 'max-height 0.4s',
  },

  controlsMenuWrapperOpened: {
    maxHeight: '100%',
  },

  controlsTitle: {
    padding: '12px 16px',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '16px',
    color: '#1B1A57',
  },

  controlsItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 27px 16px 16px',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#1B1A57',
    cursor: 'pointer',
    transition: 'all 0.3s',

    '&:hover': {
      background: '#E1E9FD',
      fontWeight: 700,
      color: '#2F80ED',
    }
  },

  controlsItemButtons: {
    display: 'flex',
    alignItems: 'center',
  }
})

export default makeStyles(styles)
