import { AnyAction as Action } from 'redux'
import _omit from 'lodash/omit'

import { IOrderChatGroup } from 'src/common/interfaces'
import { persist } from 'src/store/localforage'
import { AUTH_LOG_OUT, SOCKET_USER_CONNECTED, SOCKET_USER_DISCONNECTED } from 'src/store/types/auth'
import {
  FETCH_CHAT_DATA_FAILED,
  FETCH_CHAT_DATA_SUCCEEDED,
  FETCH_CHAT_DATA_PENDING,
  FETCH_NEW_GROUP_PENDING,
  FETCH_NEW_GROUP_SUCCEEDED,
  FETCH_NEW_GROUP_FAILED,
  SOCKET_TOOK_CHAT,
  SOCKET_NEW_WRITER_BY_ORDER,
  SOCKET_CHAT_GROUP_CLOSED,
  DECREMENT_UNREAD_QUANTITY,
  INCREMENT_UNREAD_QUANTITY,
  UPDATE_LAST_ROOM_MESSAGE,
  SOCKET_CHAT_CLOSED,
  SOCKET_CHAT_UPDATED,
  ORDER_DETAILS_UPDATE_VIEWED,
  UPDATE_UNANSWERED_CHATS,
} from 'src/store/types/chatData'

import { UserRole } from 'src/common/constants'
import { CLEAR_APP_DATA, SOCKET_CONNECTION_DISCONNECTED } from 'src/store/types/appControl'

const getGroupToUpdate = (state: IChatStore, orderId: number) => {
  return (Boolean(state.data.active[`${orderId}`]) && 'active') ||
  (Boolean(state.data.queued[`${orderId}`]) && 'queued') ||
  (Boolean(state.data.queued[`${orderId}`]) && 'archive') as IChatGroups | undefined
}

interface IChatGroup {
  [key: string|number]: IOrderChatGroup
}

type IChatGroups = 'active' | 'queued'

export interface IChatStore {
  data: {
    active: IChatGroup,
    queued: IChatGroup,
  },
  unansweredChats: {
    [key: string|number]: boolean
  },
  error: any,
  pending: boolean,
  fetchNewGroupPending: boolean
}

const initialState: IChatStore = {
  data: {
    active: {},
    queued: {},
  },
  unansweredChats: {},
  error: null,
  pending: false,
  fetchNewGroupPending: false,
}

const chatData = (state = initialState, action: Action) => {
  switch (action.type) {
    case FETCH_CHAT_DATA_PENDING: {
      return {
        ...state,
        pending: true,
        error: null,
      }
    }

    case FETCH_CHAT_DATA_SUCCEEDED: {
      return {
        ...state,
        data: {
          ...state.data,
          active: {
            ...action.payload.active,
          },
          queued: {
            ...action.payload.queued,
          },
        },
        pending: false,
        error: null,
      }
    }

    case FETCH_CHAT_DATA_FAILED: {
      return {
        ...state,
        error: action.payload,
        pending: false,
      }
    }

    case FETCH_NEW_GROUP_PENDING: {
      return {
        ...state,
        fetchNewGroupPending: true,
      }
    }

    case FETCH_NEW_GROUP_SUCCEEDED: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.groupToUpdate]: {
            ...state.data[action.payload.groupToUpdate as IChatGroups],
            [action.payload.orderId]: {
              ...action.payload.data
            },
          },
        },
        fetchNewGroupPending: false,
        error: null,
      }
    }

    case FETCH_NEW_GROUP_FAILED: {
      return {
        ...state,
        error: action.payload,
        fetchNewGroupPending: false,
      }
    }

    case SOCKET_TOOK_CHAT: {
      const groupToUpdate = getGroupToUpdate(state, action.payload.orderId)

      if (!groupToUpdate) {
        return {
          ...state,
        }
      }

      const updated = _omit(state.data[groupToUpdate], [`${action.payload.orderId}`])

      return {
        ...state,
        data: {
          ...state.data,
          [groupToUpdate]: {
            ...updated,
          }
        }
      }
    }

    case SOCKET_NEW_WRITER_BY_ORDER: {
      let orderId

      if (action.payload?.lastMessage?.identifiyers.group) {
        orderId = action.payload.lastMessage.identifiyers.group
      } else if (action.payload?.order?.id) {
        orderId = action.payload.order.id
      }

      const groupToUpdate = getGroupToUpdate(state, orderId)

      if (!groupToUpdate) {
        return {
          ...state,
        }
      }

      return {
        ...state,
        data: {
          ...state.data,
          [groupToUpdate]: {
            ...state.data[groupToUpdate],
            [`${orderId}`]: {
              ...state.data[groupToUpdate][orderId],
              chats: {
                ...state.data[groupToUpdate][orderId].chats,
                [action.payload.writer.id]: {
                  ...action.payload,
                  closed: false,
                }
              }
            }
          }
        }
      }
    }

    case INCREMENT_UNREAD_QUANTITY: {
      const groupToUpdate = getGroupToUpdate(state, action.payload.orderId)

      if (!groupToUpdate) {
        return {
          ...state,
        }
      }

      if (action.payload.senderRole !== UserRole.CLIENT) {
        return {
          ...state,
        }
      }

      if (!state.data[groupToUpdate][action.payload.orderId].chats[action.payload.writerId]) {
        return {
          ...state,
        }
      }

      return {
        ...state,
        data: {
          ...state.data,
          [groupToUpdate]: {
            ...state.data[groupToUpdate],
            [`${action.payload.orderId}`]: {
              ...state.data[groupToUpdate][action.payload.orderId],
              unreadQuantity: state.data[groupToUpdate][action.payload.orderId].unreadQuantity + 1,
              chats: {
                ...state.data[groupToUpdate][action.payload.orderId].chats,
                [action.payload.writerId]: {
                  ...state.data[groupToUpdate][action.payload.orderId].chats[action.payload.writerId],
                  unreadQuantity: state.data[groupToUpdate][action.payload.orderId].chats[action.payload.writerId].unreadQuantity + 1
                }
              }
            }
          }
        }
      }
    }

    case SOCKET_CHAT_UPDATED: {
      const groupToUpdate = getGroupToUpdate(state, action.payload.order.id)

      if (!groupToUpdate) {
        return {
          ...state,
        }
      }

      return {
        ...state,
        data: {
          ...state.data,
          [groupToUpdate]: {
            ...state.data[groupToUpdate as IChatGroups],
            [action.payload.order.id]: {
              ...action.payload,
              updateViewed: false,
            },
          },
        }
      }
    }

    case ORDER_DETAILS_UPDATE_VIEWED: {
      const groupToUpdate = getGroupToUpdate(state, action.payload.orderId)

      if (!groupToUpdate) {
        return {
          ...state,
        }
      }

      return {
        ...state,
        data: {
          ...state.data,
          [groupToUpdate]: {
            ...state.data[groupToUpdate as IChatGroups],
            [action.payload.orderId]: {
              ...state.data[groupToUpdate][action.payload.orderId],
              updateViewed: true,
            },
          },
        }
      }
    }

    case UPDATE_UNANSWERED_CHATS: { // this may be persisted!!! if you need to disable persist for this store move this to another with persist
      return {
        ...state,
        unansweredChats: {
          ...state.unansweredChats,
          [action.payload.key]: action.payload.value,
        }
      }
    }

    case SOCKET_USER_CONNECTED: {
      return {
        ...state,
        data: {
          ...state.data,
        }
      }
    }

    case SOCKET_USER_DISCONNECTED: {
      return {
        ...state,
        data: {
          ...state.data,
          active: {},
        }
      }
    }

    case SOCKET_CHAT_GROUP_CLOSED: {
      const groupToUpdate = getGroupToUpdate(state, action.payload.orderId)

      if (!groupToUpdate) {
        return {
          ...state,
        }
      }

      if (!state.data[groupToUpdate][action.payload.orderId]) {
        return {
          ...state,
        }
      }

      const withClosedChatGroup = _omit(state.data[groupToUpdate], [`${action.payload.orderId}`])

      return {
        ...state,
        data: {
          ...state.data,
          [groupToUpdate]: {
            ...withClosedChatGroup,
          }
        }
      }
    }

    case SOCKET_CHAT_CLOSED: {
      const groupToUpdate = getGroupToUpdate(state, action.payload.orderId)

      if (!groupToUpdate) {
        return {
          ...state,
        }
      }

      if (
        state.data[groupToUpdate][`${action.payload.orderId}`] &&
        !state.data[groupToUpdate][`${action.payload.orderId}`].chats[`${action.payload.writerId}`]
      ) {
        return {
          ...state,
        }
      }

      return {
        ...state,
        data: {
          ...state.data,
          [groupToUpdate]: {
            ...state.data[groupToUpdate],
            [`${action.payload.orderId}`]: {
              ...state.data[groupToUpdate][`${action.payload.orderId}`],
              chats: {
                ...state.data[groupToUpdate][`${action.payload.orderId}`].chats,
                [`${action.payload.writerId}`]: {
                  ...state.data[groupToUpdate][`${action.payload.orderId}`].chats[`${action.payload.writerId}`],
                  closed: true,
                }
              }
            },
          }
        }
      }
    }

    case UPDATE_LAST_ROOM_MESSAGE: {
      const groupToUpdate = getGroupToUpdate(state, action.payload.message.identifiyers.group)

      if (!groupToUpdate) {
        return {
          ...state,
        }
      }

      if (!state.data[groupToUpdate][action.payload.message.identifiyers.group].chats[action.payload.message.identifiyers.identifyer]) {
        return {
          ...state,
        }
      }

      return {
        ...state,
        data: {
          ...state.data,
          [groupToUpdate]: {
            ...state.data[groupToUpdate],
            [`${action.payload.message.identifiyers.group}`]: {
              ...state.data[groupToUpdate][action.payload.message.identifiyers.group],
              chats: {
                ...state.data[groupToUpdate][action.payload.message.identifiyers.group].chats,
                [action.payload.message.identifiyers.identifyer]: {
                  ...state.data[groupToUpdate][action.payload.message.identifiyers.group].chats[action.payload.message.identifiyers.identifyer],
                  lastMessage: {
                    ...action.payload.message
                  },
                }
              }
            }
          }
        }
      }
    }

    case DECREMENT_UNREAD_QUANTITY: {
      const groupToUpdate = getGroupToUpdate(state, action.payload.orderId)

      if (!groupToUpdate) {
        return {
          ...state,
        }
      }

      if (state.data[groupToUpdate][action.payload.orderId].chats[action.payload.writerId]?.lastMessage?.sender.role !== UserRole.CLIENT) {
        return {
          ...state,
        }
      }

      if (
        state.data[groupToUpdate][action.payload.orderId].unreadQuantity === 0 ||
        state.data[groupToUpdate][action.payload.orderId].chats[action.payload.writerId].unreadQuantity === 0
        ) {
        return {
          ...state,
        }
      }

      return {
        ...state,
        data: {
          ...state.data,
          [groupToUpdate]: {
            ...state.data[groupToUpdate],
            [`${action.payload.orderId}`]: {
              ...state.data[groupToUpdate][action.payload.orderId],
              unreadQuantity: state.data[groupToUpdate][action.payload.orderId].unreadQuantity - 1,
              chats: {
                ...state.data[groupToUpdate][action.payload.orderId].chats,
                [action.payload.writerId]: {
                  ...state.data[groupToUpdate][action.payload.orderId].chats[action.payload.writerId],
                  unreadQuantity: state.data[groupToUpdate][action.payload.orderId].chats[action.payload.writerId].unreadQuantity - 1
                }
              }
            }
          }
        }
      }
    }

    case SOCKET_CONNECTION_DISCONNECTED: {
      return {
        ...state,
        fetchNewGroupPending: false
      }
    }

    case CLEAR_APP_DATA:
    case AUTH_LOG_OUT: {
      return {
        ...initialState,
      }
    }

    default:
      return state
  }
}

export default persist('chatData', chatData)
