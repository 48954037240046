import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  templatesListWrapper: {
    maxWidth: '800px',
  },

  spinnerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
})

export default makeStyles(styles)
