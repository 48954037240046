import { FC, useCallback, useState, Dispatch, SetStateAction } from 'react'
import clsx from 'clsx'

import SupportControl from 'src/components/chat/left-side-bar/support-control'

import { ReactComponent as ChevronIcon } from 'src/media/images/chevronIcon.svg'

import useStyles from './styles'
import { MenuButtons } from 'src/components/chat/admin-menu/index'

interface IAdminSidebarProps {
  activeNavItem: MenuButtons
  setActiveNavItem: Dispatch<SetStateAction<MenuButtons>>
}

const AdminSidebar: FC<IAdminSidebarProps> = ({
  activeNavItem,
  setActiveNavItem,
}) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState<boolean>(true)

  const handleSupportButton = useCallback(() => {
    setActiveNavItem(MenuButtons.SUPPORTS)
  }, [setActiveNavItem])

  const handleChatLimitsButton = useCallback(() => {
    setActiveNavItem(MenuButtons.CHAT_LIMITS)
  }, [setActiveNavItem])

  const handleTemplatesButton = useCallback(() => {
    setActiveNavItem(MenuButtons.TEMPLATES)
  }, [setActiveNavItem])

  const toggleChatsButton = useCallback(() => {
    setExpanded(!expanded)
  }, [setExpanded, expanded])

  return (
    <div className={classes.adminSidebar}>
      <div className={classes.navWrapper}>
        <div
          onClick={handleSupportButton}
          className={clsx(classes.navItem, activeNavItem === MenuButtons.SUPPORTS && classes.navActive)}>
          <span className={classes.navItemTitle}>Supports</span>
        </div>
        <div className={classes.chatsMenuWrapper}>
          <div
            onClick={toggleChatsButton}
            className={clsx(classes.navItem, classes.navItemChats, expanded && classes.chatsExpanded)}>
            <span className={classes.navItemTitle}>Chats</span>
            <div className={clsx(classes.iconWrapper, expanded && classes.iconWrapperExpanded)}>
              <ChevronIcon className={classes.icon} />
            </div>
          </div>
          {expanded &&
            <div className={classes.chatsSubMenu}>
              <div
                onClick={handleChatLimitsButton}
                className={clsx(classes.navItem, activeNavItem === MenuButtons.CHAT_LIMITS && classes.navActive)}>
                <span className={classes.navItemTitle}>Chat limits</span>
              </div>
              <div
                onClick={handleTemplatesButton}
                className={clsx(classes.navItem, activeNavItem === MenuButtons.TEMPLATES && classes.navActive)}>
                <span className={classes.navItemTitle}>Templates</span>
              </div>
            </div>
          }
        </div>
      </div>
      <SupportControl />
    </div>
  )
}

export default AdminSidebar
