import { FC, Dispatch, SetStateAction, useCallback } from 'react'

import { ReactComponent as CrossIcon } from 'src/media/images/crossIcon.svg'

import useStyles from './styles'

interface IModalProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  modalWidth?: number
  withCloseButton?: boolean
}

const Modal: FC<IModalProps> = ({
  open,
  setOpen,
  modalWidth = 320,
  withCloseButton = true,
  children
}) => {
  const classes = useStyles()

  const handleCloseModal = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  if (!open) {
    return null
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.overlay} onClick={handleCloseModal} />
      <div className={classes.modalWrapper}>
        <div className={classes.modalInner} style={{width: `${modalWidth}px`, maxWidth: `${modalWidth}px`}}>
          {children}
          {withCloseButton && (
            <div className={classes.closeButton} onClick={handleCloseModal}>
              <CrossIcon className={classes.icon}/>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Modal
