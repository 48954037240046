import { Dispatch, useCallback, useEffect } from "react";
import { Socket } from "socket.io-client";
import { updateTypedData } from "src/store/actions/messagesData";

export interface ITypedData {
  message: string
  group: string
  identifyer: string
}

export const useTyping = (socket: Socket, dispatch: Dispatch<any>) => {
  const handleUpdateTypedData = useCallback((data) => dispatch(updateTypedData(data)), [dispatch])

  useEffect(() => {
    const handler = (data: ITypedData) => {
      handleUpdateTypedData(data)
    }

    socket.on('typing', handler)
    return () => {
      socket.off('typing', handler)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
