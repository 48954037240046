import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react'

import useStyles from './styles'
import { useDispatch } from 'react-redux'
import { ITemplatePhrase } from 'src/store/reducers/template-phrases'
import notifyer from 'src/common/notifyer'
import { editTemplate } from 'src/store/actions/templatePhrases'

interface ITemplateItemProps {
  template: ITemplatePhrase
  setEditMode: Dispatch<SetStateAction<boolean>>
}

const TemplateItemEdit: FC<ITemplateItemProps> = ({ template, setEditMode }) => {
  const classes = useStyles()

  const [templateText, setTemplateText] = useState<string>(template.value)
  const [templateShortcut, setTemplateShortcut] = useState<string>(template.id)

  const dispatch = useDispatch()
  const handleEditTemplate = useCallback((template) => dispatch(editTemplate(template)), [dispatch])

  const handleSave = useCallback(async () => {
    const shortcutToSend = templateShortcut

    if (!templateText.length || !templateShortcut.length) {
      notifyer.warning('Please fill all needed data')

      return
    }

    if (shortcutToSend[0] === '#') {
      shortcutToSend.slice(1)
    }

    await handleEditTemplate({oldTemplate: template.id, template: templateShortcut, phrase: templateText})

    setEditMode(false)
  }, [setEditMode, templateShortcut, templateText, handleEditTemplate, template])

  const handleCancel = useCallback(() => {
    setEditMode(false)
  }, [setEditMode])

  const handleChangeShortCut = useCallback((e: any) => {
    setTemplateShortcut(e.target.value)
  }, [setTemplateShortcut])

  const handleChangeText = useCallback((e: any) => {
    setTemplateText(e.target.value)
  }, [setTemplateText])

  return (
    <form className={classes.templateItemWrapper}>
      <div className={classes.templateContent}>
        <textarea
          className={classes.templateText}
          rows={5}
          value={templateText}
          onChange={handleChangeText}
        />
        <div className={classes.templateShortcutWrapper}>
          <p className={classes.shortcutTitle}>Shortcuts</p>
          <input
            className={classes.shortcut}
            value={templateShortcut}
            onChange={handleChangeShortCut}
          />
        </div>
      </div>
      <div className={classes.buttonsGroup}>
        <button className={classes.button} onClick={handleSave}>
          Save
        </button>
        <button className={classes.button} onClick={handleCancel}>
          Cancel
        </button>
      </div>
    </form>
  )
}

export default TemplateItemEdit
