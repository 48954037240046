import { FC } from 'react'

import useStyles from './styles'

interface IUnreadMessageCount {
  count: number
}

const UnreadMessageCount: FC<IUnreadMessageCount> = ({
  count,
}) => {
  const classes = useStyles()

  if (count === 0) {
    return null
  }

  return (
    <div className={classes.unreadCount}>{count > 99 ? '99+' : count}</div>
  )
}

export default UnreadMessageCount
