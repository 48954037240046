import { APIv1 } from 'src/api'
import { call, put } from 'redux-saga/effects'

import { FETCH_REFRESH_TOKEN_PENDING, FETCH_REFRESH_TOKEN_SUCCEEDED, FETCH_REFRESH_TOKEN_FAILED } from 'src/store/types/auth'
import notifyer from 'src/common/notifyer'

export interface IAuthDataFromForm {
  email: string
  password: string
}

const refreshTokenRequest = (): Promise<any> => (
  APIv1.get('/auth/refresh-token')
)

export default function* refreshToken() {
  try {
    yield put({ type: FETCH_REFRESH_TOKEN_PENDING })
    // @ts-ignore
    const result = yield call(refreshTokenRequest)
    localStorage.setItem('authToken', result.data.token)

    yield put({ type: FETCH_REFRESH_TOKEN_SUCCEEDED, payload: result.data.token })
  } catch (e) {
    notifyer.error('Request failed - server error')
    yield put({ type: FETCH_REFRESH_TOKEN_FAILED, payload: e })
  }
}
