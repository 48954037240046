import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import Entry from './components/Entry'

import { store, persistor } from 'src/store'

import 'src/styles.css'

function App() {
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Entry />
      </PersistGate>
    </ReduxProvider>
  )
}

export default App
