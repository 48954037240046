import { FC } from 'react'

import clsx from 'clsx'

import { INavbarButton } from 'src/common/interfaces'

import useStyles from './styles'

const NavbarButton: FC<INavbarButton> = ({
  checked,
  label,
  chatsCount,
  onClick,
  ...props
}) => {
  const classes = useStyles()

  return (
    <label className={clsx(classes.buttonLabel, checked === label && classes.active)}>
      <input
        className={classes.radioButtonInput}
        type="radio"
        value={label}
        name={label}
        checked={checked === label}
        onChange={onClick}
        {...props}
      />
      {`${label} ${chatsCount && chatsCount > 0 ? `(${chatsCount})` : ''}`}
    </label>
  )
}

export default NavbarButton
