import { createStyles, makeStyles } from '@material-ui/styles'
import searchIcon from 'src/media/images/searchIcon.svg'

const styles = () => createStyles({
  archiveHeaderFormWrapper: {
    color: '#868787',
  },

  label: {
    paddingBottom: '2px',
    fontSize: '12px',
  },

  archiveHeaderInputWrapper: {
    position: 'relative',
    width: '100%',

    '&:before': {
      position: 'absolute',
      left: '15px',
      top: '-5px',
      content: '""',
      width: '20px',
      height: '100%',
      background: `url(${searchIcon})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    }
  },

  spinnerWrapper: {
    position: 'absolute',
    right: '15px',
    top: '-5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20px',
    height: '100%'
  },

  archiveHeaderInput: {
    width: '100%',
    marginBottom: '10px',
    padding: '7px 15px 7px 48px',
    border: '1px solid rgba(110, 110, 110, 0.5)',
    borderRadius: '8px',
  },
})

export default makeStyles(styles)
