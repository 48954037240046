import { Dispatch, useCallback, useEffect } from "react";
import { Socket } from "socket.io-client";
import notifyer from "src/common/notifyer";
import { setDisconnected } from "src/store/actions/auth";

export const useSupportDisconnected = (socket: Socket, dispatch: Dispatch<any>) => {
  const handleDisconnected = useCallback(() => dispatch(setDisconnected()), [dispatch]) // SOCKET_USER_DISCONNECTED

  useEffect(() => {
		const handler = () => {
			handleDisconnected()
      notifyer.warning('Activity - disconnected')
		}

    socket.on('support-disconnected', handler)
    return () => {
      socket.off('support-disconnected', handler)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
