import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  archiveHeaderWrapper: {
    padding: '15px 15px 0 15px',
  },

  filtersVisibilityToggler: {
    height: '20px',
    cursor: 'pointer',
    opacity: 0.5,
    fontSize: '14px',
    fontWeight: 700,
  },

  filtersVisibilityTogglerContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  chevronIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '18px',
    transform: 'rotateX(180deg)',
    transition: 'transform 0.3s',
  },

  chevronIconOpened: {
    transform: 'rotateX(0deg)',
  },

  loadMoreButton: {
    zIndex: 3,
    position: 'absolute',
    bottom: '75px',
    right: '25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px 10px',
    background: '#28447B',
    borderRadius: '15px',
    border: 'none',
    cursor: 'pointer',
    color: '#fff',
    opacity: '0.8',

    '&:hover': {
      background: '#3459a2',
    },

    '&[disabled]': {
      opacity: 0.4,
      cursor: 'initial',
    }
  },
})

export default makeStyles(styles)
