import { Dispatch, FC, SetStateAction, useCallback } from 'react'
import { useSelector } from 'react-redux'
import DatePicker from "react-datepicker"

import { IStore } from 'src/store/types'

import useStyles from './styles'
import 'react-datepicker/dist/react-datepicker.css'

interface IDateFilter {
  startDate: Date | null,
  endDate: Date | null,
  setDateRange: Dispatch<SetStateAction<null[] | Date[]>>
}

const DateFilter: FC<IDateFilter> = ({
  startDate,
  endDate,
  setDateRange,
}) => {
  const classes = useStyles()

  const fetchArchivePending = useSelector((state: IStore) => state.archiveData.fetchArchivePending)

  const handleChange = useCallback((update) => {
    setDateRange(update)
  }, [setDateRange])

  return (
    <div className={classes.dateFilterWrapper}>
      <label className={classes.label}>Filter by Date</label>
      <DatePicker
        selectsRange={true}
        disabled={fetchArchivePending}
        startDate={startDate}
        endDate={endDate}
        onChange={handleChange}
        className={classes.datePicker}
        dateFormat={'dd.MM.yyyy'}
      />
    </div>
  )
}

export default DateFilter
