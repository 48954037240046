import { FC, useCallback, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _uniq from 'lodash/uniq'
import clsx from 'clsx'

import WriterLevel from '../writer-short-info/writer-level'
import Rating from './rating'

import { setOpenWritersModal } from 'src/store/actions/appControl'
import { IStore } from 'src/store/types'

import { IWriter } from 'src/common/interfaces'
import { ReactComponent as BioIcon } from 'src/media/images/bioIcon.svg'

import useStyles from './styles'

interface IWriterInfo {
  writer: IWriter
}

const WriterInfo: FC<IWriterInfo> = ({
  writer,
}) => {
  const classes = useStyles()
  const isOpenWriterInfo = useSelector((state: IStore) => state.appData.isOpenWriterInfo)

  const dispatch = useDispatch()
  const handleCloseWriterInfo = useCallback(() => dispatch(setOpenWritersModal(false)), [dispatch])

  return (
    <Fragment>
      {isOpenWriterInfo && <div className={classes.writerInfoOverlay} onClick={handleCloseWriterInfo}/>}
      <div className={classes.writerInfoWrapper}>
        <div className={clsx(classes.writerInfoInner, isOpenWriterInfo && classes.writerInfoInnerOpened)}>
          <div className={classes.writerInfoHeader}>
            {writer.avatar && (
              <div className={classes.writerInfoImageWrapper}>
                <img
                  className={classes.writerInfoImage}
                  src={writer.avatar}
                  alt={writer.name}
                />
              </div>
            )}
            <div className={classes.headerDetaisWrapper}>
              <div className={classes.headerDetaisMainInfo}>
                <p className={classes.mainInfoNameWrapper}>
                  <span className={classes.mainInfoName}>{writer.name}</span>
                  <WriterLevel profileLevel={writer.profileLevel} />
                </p>
                <Rating rating={writer.successRate / 20} />
              </div>
              <div className={classes.headerDetaisStatsWrapper}>
                <div className={classes.headerDetaisStatsItem}>
                  <p className={classes.statsItemValue}>{writer.writenEssaysQuantity}</p>
                  <p className={classes.statsItemDescription}>Essays written with us</p>
                </div>
                <div className={classes.headerDetaisStatsItem}>
                  <p className={classes.statsItemValue}>{writer.reviewsQuantity}</p>
                  <p className={classes.statsItemDescription}>Reviews about writer</p>
                </div>
                <div className={classes.headerDetaisStatsItem}>
                  <p className={classes.statsItemValue}>{`${writer.successRate}%`}</p>
                  <p className={classes.statsItemDescription}>Success rate</p>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.writerInfoBioWrapper}>
            <div className={classes.writerInfoBioHeaderWrapper}>
              <div className={classes.writerInfoBioIconWrapper}>
                <BioIcon />
              </div>
              <p className={classes.writerInfoBioHeader}>
                BIO
              </p>
            </div>
            <div className={classes.writerInfoBioText}>
              {writer.bio}
            </div>
          </div>
          <div className={classes.writerInfoFooter}>
            <p className={classes.writerInfoFooterTitle}>
              Subjects:
            </p>
            <div className={classes.subjectsList}>
              {_uniq(writer.subjects).map(subject => (
                <p key={subject} className={classes.subjectsListItem}>{subject}</p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default WriterInfo
