import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  chatsLimitViewWrapper: {
    width: '100%',
    height: '100%',
    maxHeight: '100vh',
    padding: '30px',
    background: '#F1F2F4',
    overflow: 'scroll',
  },

  spinnerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },

  chatLimitViewTitle: {
    margin: '0 0 10px',
    color: '#6D7885',
    fontSize: '16px',
    lineHeight: '18px',
  },

  controlsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  numberInputWrapper: {
    marginRight: '14px',
    height: '50px',
    display: 'flex',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '10px',
    background: '#FFFFFF',
  },

  numberInputButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: '40px',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    fontSize: '20px',
    lineHeight: '20px',

    '&:hover': {
      fontWeight: 700,
    }
  },

  numberInput: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '67px',
  },

  numberInputLabel: {
    margin: 0,
    opacity: 0.45,
    color: '#707070',
    fontSize: '14px',
    lineHeight: '16px',
  },

  chatsLimitSaveButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    width: '149px',
    marginTop: '20px',
    border: 'none',
    borderRadius: '10px',
    background: '#28447B',
    color: '#FFFFFF',
    cursor: 'pointer',
    transition: 'background 0.3s',

    '&:hover': {
      background: 'hsl(220, 51%, 42%)',
    }
  },
})

export default makeStyles(styles)
