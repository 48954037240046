import { Dispatch, FC, SetStateAction, useCallback } from 'react'
import { useDispatch, useSelector } from "react-redux"

import clsx from 'clsx'

import { logOut } from "src/store/actions/auth"

import useStyles from './styles'
import Tumbler from './tumbler'
import { useSocketEmit } from 'src/socket/socketControl'
import { IStore } from 'src/store/types'
import { ClipLoader } from 'react-spinners'
import { closeAdminProfile, openAdminProfile } from 'src/store/actions/adminData'
import { UserRole } from 'src/common/constants'

interface ISupportControlsMenu {
  open: boolean
  setOpenMenu: Dispatch<SetStateAction<boolean>>
}

const SupportControlsMenu: FC<ISupportControlsMenu> = ({
  open,
  setOpenMenu,
}) => {
  const dispatch = useDispatch()
  const handleLogOut = useCallback(() => dispatch(logOut()), [dispatch])
  const handleOpenAdminProfile = useCallback(() => dispatch(openAdminProfile()), [dispatch])
  const handleCloseAdminProfile = useCallback(() => dispatch(closeAdminProfile()), [dispatch])

  const supportConnected = useSelector((state: IStore) => state.appData.supportConnected)
  const requestPending = useSelector((state: IStore) => state.chatData.fetchNewGroupPending)
  const supportRole = useSelector((state: IStore) => state.authData.data.supportRole)
  const isOpenAdminMenu = useSelector((state: IStore) => state.adminData.showAdminMenu)

  const { connectSupport, disconnectSupport } = useSocketEmit()

  const toggleActivity = useCallback(() => {
    if (requestPending) {
      return
    }

    if (supportConnected) {
      disconnectSupport()
      setOpenMenu(false)

      return
    }

    connectSupport()
    setOpenMenu(false)
  }, [supportConnected, requestPending, connectSupport, disconnectSupport, setOpenMenu])

  const classes = useStyles()

  return (
    <div className={clsx(classes.controlsMenuWrapper, open && classes.controlsMenuWrapperOpened)}>
      <div className={classes.controlsTitle}>Support info</div>
      <div className={classes.controlsItem} onClick={toggleActivity}>
        <span>Activity</span>
        <div className={classes.controlsItemButtons}>
          {requestPending && <ClipLoader size={'12px'} color='#2F80ED' />}
          <Tumbler checked={supportConnected} />
        </div>
      </div>
      {supportRole === UserRole.ADMIN ?
        isOpenAdminMenu ?
          <div className={classes.controlsItem} onClick={handleCloseAdminProfile}>Back to chat</div>
          :
          <div className={classes.controlsItem} onClick={handleOpenAdminProfile}>Manage chats</div>
        :
        null
      }
      <div className={classes.controlsItem} onClick={handleLogOut}>Logout</div>
    </div>
  )
}

export default SupportControlsMenu
