import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  chatRoom: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '65px',
    maxHeight: '65px',
    padding: '0 24px 0 16px',
    borderTop: '1px solid #E6E6E6',
    transition: '0.3s background',
    cursor: 'pointer',

    '&:hover': {
      background: 'rgba(40, 68, 123, 0.03)',
    },
  },

  withAlertBorder: {
    border: '2px solid rgba(205, 92, 92, 0.6)',
  },

  activeChat: {
    background: 'rgba(40, 68, 123, 0.05)',

    '&:hover': {
      background: 'rgba(40, 68, 123, 0.05)',
    },
  },

  closedChat: {
    borderLeft: '10px solid rgba(205, 92, 92, 0.4)',
  },

  chatRoomHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 0 6px',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#333333',
    opacity: 0.6,
  },

  chatRoomFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  lastMessage: {
    marginRight: '4px',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#4F5E7B',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'capitalize',
  },

  lastMessageFrom: {
    marginRight: '3px',
    color: '#A2ADC3',
    opacity: 0.7,
  },
})

export default makeStyles(styles)
