import { FC, useCallback, useEffect } from 'react'
import clsx from 'clsx'

import Avatar from 'src/components/chat/avatar'

import UnreadMessageCount from '../unread-messages-count'

import { IOrderChatGroup } from 'src/common/interfaces'

import useStyles from './styles'
import ChatGroupMenu from './chat-group-menu'
import { useDispatch, useSelector } from 'react-redux'
import { IStore } from 'src/store/types'
import { NavbarButtonValues } from 'src/common/constants'
import { setCurrentGroupData } from 'src/store/actions/appControl'

interface IChatGroup {
  orderId: number
  groupData: IOrderChatGroup
  currentChatGroupData: IOrderChatGroup | null
  handleChatGroupButton: (e: any) => void
}

const ChatGroup: FC<IChatGroup> = ({
  orderId,
  groupData,
  currentChatGroupData,
  handleChatGroupButton,
}) => {
  const dispatch = useDispatch()

  const classes = useStyles()
  const activeTab = useSelector((state: IStore) => state.appData.activeNavbarTab)
  const handleSetCurrentChatGroupData = useCallback((orderId, data) => dispatch(setCurrentGroupData(orderId, data)), [dispatch])

  useEffect(() => {
    if(currentChatGroupData?.order.id === groupData.order.id) {
      handleSetCurrentChatGroupData(groupData.order.id, groupData)
    }
  }, [groupData, currentChatGroupData, handleSetCurrentChatGroupData])

  return (
    <div
      className={clsx(classes.chatGroup, currentChatGroupData?.order.id === groupData.order.id && classes.activeGroup)}
      data-orderid={orderId}
      onClick={handleChatGroupButton}
    >
      <div>
        <Avatar
          userName={groupData.customer.name}
        />
        <span className={classes.customerName}>{groupData.customer.name}</span>
      </div>
      <div className={classes.chatGroupControlsWrapper}>
        {groupData?.updateViewed !== undefined && !groupData.updateViewed ?
          <div className={classes.updateStatusBadge}>
            updated
          </div>
          :
          null
        }
        {activeTab !== NavbarButtonValues.ARCHIVE && (
          <>
            <UnreadMessageCount count={groupData.unreadQuantity} />
            <ChatGroupMenu
              orderId={orderId}
              activeTab={activeTab}
            />
        </>)}
      </div>
    </div>
  )
}

export default ChatGroup
