import { FC, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import clsx from 'clsx'

import Avatar from 'src/components/chat/avatar'
import WriterLevel from './writer-level'

import { setOpenWritersModal } from 'src/store/actions/appControl'

import { IWriter } from 'src/common/interfaces'

import useStyles from './styles'

interface IWriterShortInfo {
  avatar: IWriter['avatar']
  name: IWriter['name']
  profileLevel: IWriter['profileLevel']
  writerPrice: IWriter['rates']
}

const WriterShortInfo: FC<IWriterShortInfo> = ({
  avatar,
  name,
  profileLevel,
  writerPrice,
}) => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const handleOpenWriterInfo = useCallback(() => dispatch(setOpenWritersModal(true)), [dispatch])

  return (
    <div className={classes.writerShortInfoWrapper}>
      <div className={clsx(classes.writer, classes.shortInfoItem)} onClick={handleOpenWriterInfo}>
        <Avatar
          userName={name}
          avatar={avatar}
          size={36}
        />
        <p className={classes.writerName}>
          <span className={classes.highlited}>Writer: </span>
          {name}
        </p>
      </div>
      <p className={clsx(classes.rating, classes.shortInfoItem)}>
        <span className={classes.highlited}>Rating: </span>
        <WriterLevel
          profileLevel={profileLevel}
        />
      </p>
      <p className={clsx(classes.rates, classes.shortInfoItem)}>
        <span className={classes.highlited}>Rates:&nbsp;</span>
        <span>{writerPrice}</span>&nbsp;$
      </p>
    </div>
  )
}

export default WriterShortInfo
