import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  templatesButton: {
    marginRight: '15px',
    padding: '0 10px',
    fontSize: '20px',
    fontWeight: 500,
    color: '#8D8DAB',
    cursor: 'pointer',
  }
})

export default makeStyles(styles)
