export const FETCH_MESSAGES_DATA_SAGA: 'FETCH_MESSAGES_DATA_SAGA' = 'FETCH_MESSAGES_DATA_SAGA'
export const FETCH_MESSAGES_DATA_PENDING: 'FETCH_MESSAGES_DATA_PENDING' = 'FETCH_MESSAGES_DATA_PENDING'
export const FETCH_MESSAGES_DATA_SUCCEEDED: 'FETCH_MESSAGES_DATA_SUCCEEDED' = 'FETCH_MESSAGES_DATA_SUCCEEDED'
export const FETCH_MESSAGES_DATA_FAILED: 'FETCH_MESSAGES_DATA_FAILED' = 'FETCH_MESSAGES_DATA_FAILED'

export const SEND_MESSAGE: 'SEND_MESSAGE' = 'SEND_MESSAGE'
export const NEW_MESSAGE_FROM_SOCKET: 'NEW_MESSAGE_FROM_SOCKET' = 'NEW_MESSAGE_FROM_SOCKET'

export const SOCKET_MESSAGE_VIEWED: 'MESSAGE_VIEWED' = 'MESSAGE_VIEWED'

export const STORE_ENTERED_MESSAGE_DATA_BY_ROOM: 'STORE_ENTERED_MESSAGE_DATA_BY_ROOM' = 'STORE_ENTERED_MESSAGE_DATA_BY_ROOM'

export const UPDATE_TYPED_DATA: 'UPDATE_TYPED_DATA' = 'UPDATE_TYPED_DATA'
