import { APIv1 } from 'src/api'

import { call, put } from 'redux-saga/effects'

import { IOrderChatGroup } from 'src/common/interfaces'
import { NavbarButtonValues } from 'src/common/constants'
import notifyer from 'src/common/notifyer'
import { FETCH_TRANSFER_SUCCEEDED } from 'src/store/types/appControl'

const fetchTransferGroupRequest = ({
  orderId,
  activeTab,
}: {
  orderId: IOrderChatGroup['order']['id']
  activeTab: NavbarButtonValues
}): Promise<any> => {
  const requestPath = activeTab === NavbarButtonValues.ACTIVE ? '/chat/support/transfer/' : '/chat/support/assign/'
  return APIv1.get(`${requestPath}${orderId}`)
}

export default function* fetchTransferGroup(action: any) {
  try {
    // @ts-ignore
    const result = yield call(fetchTransferGroupRequest, action.payload)

    if (result.data) {
      const successText = action.payload.activeTab === NavbarButtonValues.ACTIVE ? 'Transfer accepted' : 'Check your active tab'
      notifyer.success(successText)

      if (action.payload.activeTab === NavbarButtonValues.ACTIVE) {
        yield put({
          type: FETCH_TRANSFER_SUCCEEDED,
          payload: {
            groupToUpdate: action.payload.groupToUpdate,
            orderId: action.payload.orderId,
            data: result.data
          }
        })
      }

    } else {
      notifyer.warning('Chat transfer is currently unavailable  - there are no availible suppots')
    }


  } catch (e) {
    notifyer.error('Something went wrong while transferring the chat')
  }
}
