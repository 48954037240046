import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  supportControlWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },

  supportControlInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '60px',
    minHeight: '60px',
    maxHeight: '60px',
    padding: '0 27px 0 16px',
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    borderRight: '1px solid rgba(0, 0, 0, 0.1)',
    background: '#fff',
    cursor: 'pointer',

    '&:hover': {
      '& $icon': {
        width: '18px',
        height: '11px',
      },
    },
  },

  supportMainInfo: {
    display: 'flex',
    alignItems: 'center',
  },

  supportName: {
    marginLeft: '14px',
    color: '#1B1A57',
    fontSize: '16px',
    lineHeight: '19px',
  },

  supportIconButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '18px',
    transition: 'transform 0.3s',
  },

  supportIconButtonOpened: {
    transform: 'rotateX(180deg)',
  },

  icon: {

  }
})

export default makeStyles(styles)
