import { FC } from 'react'

import { ReactComponent as OrderDetailsIcon } from 'src/media/images/orderDetailsIcon.svg'

import useStyles from './styles'

const OrderDetailsHeader: FC<{ orderLink: string}> = ({
  orderLink,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <div className={classes.iconBlock}>
        <OrderDetailsIcon className={classes.icon} />
        <span className={classes.iconBlockText}>
          Order details
        </span>
      </div>
      <a className={classes.orderLink} href={orderLink} target='_blank' rel="noreferrer">
        All info
      </a>
    </div>
  )
}

export default OrderDetailsHeader
