import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  chatAreaWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },

  messagesWindowWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: 'calc(100vh - 72px)',
    backgroundColor: '#F1F2F4',
  },

  emptyBlock: {
    width: '100%',
    height: '100%',
    background: '#F1F2F4',
  },

  spinnerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },

  closedNotificationWrapper: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 30px',
    backgroundColor: 'rgba(205, 92, 92, 0.2)',
  },

  closedNotification: {
    fontSize: '16px',
    lineHeight: '18px',
  },
})

export default makeStyles(styles)
