import { Dispatch, useCallback, useEffect } from "react";
import { Socket } from "socket.io-client";
import notifyer from "src/common/notifyer";
import { setConnected } from "src/store/actions/auth";

export const useSupportConnected = (socket: Socket, dispatch: Dispatch<any>) => {
  const handleConnected = useCallback(() => dispatch(setConnected()), [dispatch]) // SOCKET_USER_CONNECTED

  useEffect(() => {
		const handler = () => {
			handleConnected()
			notifyer.success('Activity - connected')
		}

    socket.on('support-connected', handler)
    return () => {
      socket.off('support-connected', handler)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
