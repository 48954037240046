import { FC, useEffect, Fragment, useCallback, createRef, LegacyRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _isEmpty from 'lodash/isEmpty'

import Message from './chat-message'
import UnreadDivider from './unread-divider'
import ScrollToBottomButton from './scroll-to-bottom-button'
import ChatMessagePreview from './chat-message-preview'

import { IMessageData, IOrder, IWriter } from 'src/common/interfaces'

import useStyles from './styles'
import { setMessagesEndRef, setMessagesListWrapperRef } from 'src/store/actions/appControl'
import { UserRole } from 'src/common/constants'
import { IStore } from 'src/store/types'
import { useScrollLogic } from './useScrollLogic'

interface IMessageList {
  orderId: IOrder['id']
  writerId: IWriter['id']
}

const MessagesList: FC<IMessageList> = ({
  orderId,
  writerId,
}) => {
  const classes = useStyles()

  const messagesEndRef = createRef<HTMLElement>()
  const wrapperRef = createRef<HTMLElement>()
  const messages = useSelector((state: IStore) => state.messagesData.data)
  const currentMessages = messages[`${orderId}${writerId}`]?.messages ? messages[`${orderId}${writerId}`].messages : []
  const [messageWithDivider, setMessageWithDivider]= useState<IMessageData | undefined >(undefined)
  const initialFirstUnreadFromClient = !_isEmpty(currentMessages) ? currentMessages.find((message) => (!message.viewed && message.sender.role === UserRole.CLIENT)) : undefined

  useScrollLogic({
    haveUnreadMessage: Boolean(initialFirstUnreadFromClient),
    orderId,
    writerId,
  })

  useEffect(() => {
    setMessageWithDivider(initialFirstUnreadFromClient)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, writerId])

  useEffect(() => {
    if (!_isEmpty(currentMessages)) {
      const isLastMessageFromSupport = currentMessages[currentMessages.length - 1]?.sender.role !== UserRole.CLIENT
      const isLastMessageFromClient = currentMessages[currentMessages.length - 1]?.sender.role === UserRole.CLIENT

      if (isLastMessageFromSupport) {
        setMessageWithDivider(undefined)
      } else if (!messageWithDivider && isLastMessageFromClient && initialFirstUnreadFromClient) {
        setMessageWithDivider(initialFirstUnreadFromClient)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMessages.length, setMessageWithDivider])

  const dispatch = useDispatch()
  const handleSetMessagesEndRef = useCallback((node) => dispatch(setMessagesEndRef(node)), [dispatch])
  const handleSetMessagesListWrapperRef = useCallback((node) => dispatch(setMessagesListWrapperRef(node)), [dispatch])

  useEffect(() => {
    if (messagesEndRef?.current) {
      handleSetMessagesEndRef(messagesEndRef.current)
    }
  }, [messagesEndRef, handleSetMessagesEndRef])

  useEffect(() => {
    if (wrapperRef?.current) {
      handleSetMessagesListWrapperRef(wrapperRef.current)
    }
  }, [wrapperRef, handleSetMessagesListWrapperRef])

  return (
    <div ref={wrapperRef as LegacyRef<HTMLDivElement> | undefined} key={`${orderId}${writerId}`} className={classes.messagesListWrapper}>
      {_isEmpty(currentMessages) ?
        (
          <span className={classes.noMessagesText}>There is no messages here</span>
        )
      :
        currentMessages.map((message) => (
          <Fragment key={`${message.id}${message.identifiyers.group}${message.identifiyers.identifyer}${message.sentAt}${message.content}`}>
            {(messageWithDivider && message.id === messageWithDivider.id) && (<UnreadDivider
              currentMessages={currentMessages}
              messageId={message.id}
              orderId={orderId}
              writerId={writerId}
            />)}
            <Message
              {...message}
            />
          </Fragment>
        ))
      }
      <ChatMessagePreview
        orderId={orderId}
        writerId={writerId}
      />
      <div ref={messagesEndRef as LegacyRef<HTMLDivElement> | undefined} />
      <ScrollToBottomButton
        currentMessages={currentMessages}
      />
    </div>
  )
}

export default MessagesList
