import { FC } from 'react'
import clsx from 'clsx'

import { ReactComponent as StarIcon } from 'src/media/images/starIcon.svg'

import { IWriter } from 'src/common/interfaces'

import useStyles from './styles'

const MAX_STARS_COUNT = 5

interface IWriterInfo {
  rating: IWriter['rating']
}

const Rating: FC<IWriterInfo> = ({
  rating,
}) => {
  const classes = useStyles()

	return (
    <div className={classes.ratingWrapper}>
			{[...Array(MAX_STARS_COUNT)].map((_, index) => {
        const isColored = index <= rating
        const isFilled = !Number.isInteger(rating) && index === parseInt(`${rating}`)
        const splitted = rating.toFixed(2).toString().split(".")
        const width = isFilled ? `${splitted[splitted.length - 1]}%` : '100%'

        return (
          <div key={`star${index}`} className={clsx(classes.starWrapper)}>
            <span style={{width}} className={clsx(classes.fillingControl, isColored && classes.coloredStar)}>
              <StarIcon />
            </span>
            <div className={classes.backgroundStar}>
              <StarIcon />
            </div>
          </div>
			  )
      })}
    </div>
  )
}

export default Rating
