export const SET_OPEN_WRITER_INFO: 'SET_OPEN_WRITER_INFO' = 'SET_OPEN_WRITER_INFO'

export const SET_MESSAGES_END_REF: 'SET_MESSAGES_END_REF' = 'SET_MESSAGES_END_REF'
export const SET_ARCHIVE_LIST_END_REF_VISIBILITY: 'SET_ARCHIVE_LIST_END_REF_VISIBILITY' = 'SET_ARCHIVE_LIST_END_REF_VISIBILITY'
export const SET_MESSAGES_LIST_WRAPPER_REF: 'SET_MESSAGES_LIST_WRAPPER_REF' = 'SET_MESSAGES_LIST_WRAPPER_REF'
export const SET_UNREAD_DIVIDER_REF: 'SET_UNREAD_DIVIDER_REF' = 'SET_UNREAD_DIVIDER_REF'

export const SET_MESSAGE_ROOM_SCROLL_POSITION: 'UPDATE_MESSAGE_ROOM_SCROLL_POSITION' = 'UPDATE_MESSAGE_ROOM_SCROLL_POSITION'
export const SET_CURRENT_CHAT_GROUP_DATA: 'SET_CURRENT_CHAT_GROUP_DATA' = 'SET_CURRENT_CHAT_GROUP_DATA'
export const SET_CURRENT_ROOM_DATA: 'SET_CURRENT_ROOM_DATA' = 'SET_CURRENT_ROOM_DATA'

export const SET_ACTIVE_NAVBAR_TAB: 'SET_ACTIVE_NAVBAR_TAB' = 'SET_ACTIVE_NAVBAR_TAB'

export const TRANSFER_CHAT_SAGA: 'TRANSFER_CHAT_SAGA' = 'TRANSFER_CHAT_SAGA'
export const FETCH_TRANSFER_SUCCEEDED: 'FETCH_TRANSFER_SUCCEEDED' = 'FETCH_TRANSFER_SUCCEEDED'
export const FETCH_TRANSFER_FAILED: 'FETCH_TRANSFER_FAILED' = 'FETCH_TRANSFER_FAILED'
export const FETCH_TRANSFER_PENDING: 'FETCH_TRANSFER_PENDING' = 'FETCH_TRANSFER_PENDING'

export const SOCKET_CONNECTION_DISCONNECTED: 'SOCKET_CONNECTION_DISCONNECTED' = 'SOCKET_CONNECTION_DISCONNECTED'

export const CLEAR_APP_DATA:'CLEAR_APP_DATA' = 'CLEAR_APP_DATA'
