import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  templateForm: {
    maxWidth: '350px',
    marginBottom: '28px',
  },

  templateFormTextArea: {
    width: '100% !important',
    marginBottom: '8px',
    padding: '15px 12px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '10px',
    background: '#fff',
    fontSize: '16px',
    lineHeight: '20px',
  },

  templateFormInput: {
    width: '100%',
    marginBottom: '10px',
    padding: '15px 12px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '10px',
    background: '#fff',
    fontSize: '16px',
    lineHeight: '20px',
  },

  templateFormButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '45px',
    width: '100%',
    border: 'none',
    borderRadius: '10px',
    background: '#28447B',
    color: '#FFFFFF',
    cursor: 'pointer',
    transition: 'background 0.3s',

    '&:hover': {
      background: 'hsl(220, 51%, 42%)',
    }
  },
})

export default makeStyles(styles)
