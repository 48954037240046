import { FC } from 'react'

import clsx from 'clsx'

import { IWriter } from 'src/common/interfaces'

import useStyles from './styles'

interface IWriterLevel {

  profileLevel: IWriter['profileLevel']
}

const WriterLevel: FC<IWriterLevel> = ({
  profileLevel,
}) => {
  const classes = useStyles()

  return (
    <span className={clsx(classes.ratingValue, classes[profileLevel])}>{profileLevel}</span>
  )
}

export default WriterLevel
