import { Dispatch } from 'redux'
import { NavbarButtonValues } from 'src/common/constants'
import { IChatData, IOrderChatGroup } from 'src/common/interfaces'

import {
  SET_OPEN_WRITER_INFO,
  SET_MESSAGES_END_REF,
  SET_MESSAGES_LIST_WRAPPER_REF,
  SET_MESSAGE_ROOM_SCROLL_POSITION,
  SET_CURRENT_CHAT_GROUP_DATA,
  SET_CURRENT_ROOM_DATA,
  SET_ACTIVE_NAVBAR_TAB,
  SET_UNREAD_DIVIDER_REF,
  CLEAR_APP_DATA,
  SET_ARCHIVE_LIST_END_REF_VISIBILITY,
} from 'src/store/types/appControl'

export const setOpenWritersModal = (condition: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_OPEN_WRITER_INFO,
    condition,
  })
}

export const setMessagesEndRef = (node: HTMLElement) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_MESSAGES_END_REF,
    node,
  })
}

export const setArchiveListEndVisibility = (inView: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_ARCHIVE_LIST_END_REF_VISIBILITY,
    inView,
  })
}

export const setUnreadDividerRef = (node: HTMLElement) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_UNREAD_DIVIDER_REF,
    node,
  })
}

export const setMessagesListWrapperRef = (node: HTMLElement) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_MESSAGES_LIST_WRAPPER_REF,
    node,
  })
}

export const setRoomScrollPosition = (orderId: number, writerId: number, scrollPosition: number) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_MESSAGE_ROOM_SCROLL_POSITION,
    payload: {
      orderId,
      writerId,
      scrollPosition,
    },
  })
}

export const setCurrentGroupData = (orderId: number, data: IOrderChatGroup) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_CURRENT_CHAT_GROUP_DATA,
    payload: {
      orderId,
      data
    },
  })
}

export const setCurrentChatRoomData = (data: IChatData) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_CURRENT_ROOM_DATA,
    payload: {
      data,
    },
  })
}

export const setActiveTab = (activeTab: NavbarButtonValues) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_ACTIVE_NAVBAR_TAB,
    payload: {
      activeTab,
    },
  })
}

export const clearAppData = () => (dispatch: Dispatch) => {
  dispatch({
    type: CLEAR_APP_DATA,
  })
}
