import { FC, Dispatch, SetStateAction, useCallback, useState, useEffect } from 'react'
import clsx from 'clsx'
import {useDropzone} from 'react-dropzone'
import { useDispatch, useSelector } from "react-redux"
import jsonToFormData from '@ajoelp/json-to-formdata'

import { ReactComponent as DragIcon } from 'src/media/images/dragIcon.svg'

import useStyles from './styles'
import notifyer from 'src/common/notifyer'
import { clearUploadedImage, createUser, uploadImage } from 'src/store/actions/adminData'
import { UserRole } from 'src/common/constants'
import { IStore } from 'src/store/types'
import { ClipLoader } from 'react-spinners'

interface ISupportsModalInnerProps {
  setModalOpen: Dispatch<SetStateAction<boolean>>
}

const SupportsModalInner: FC<ISupportsModalInnerProps> = ({ setModalOpen }) => {
  const classes = useStyles()

  const supportList = useSelector((state: IStore) => state.adminData.supportList)
  const uploadedImage = useSelector((state: IStore) => state.adminData.uploadedImage)
  const uploadImagePending = useSelector((state: IStore) => state.adminData.uploadImagePending)

  const dispatch = useDispatch()
  const handleCreateUser = useCallback((userData) => dispatch(createUser(userData)), [dispatch])
  const handleUploadImage = useCallback((file) => dispatch(uploadImage(file)), [dispatch])
  const handleClearUploadedImage = useCallback(() => dispatch(clearUploadedImage()), [dispatch])

  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [files, setFiles] = useState<File[]>([])
  const [adaptedFile, setAdaptedFile] = useState<{name: string, path: string} | null>(null)

  useEffect(() => {
    uploadedImage.path.length && setAdaptedFile(uploadedImage)
  }, [uploadedImage, setAdaptedFile])

  const onDrop = useCallback(uploadedFiles => {
      if (uploadedFiles.length > 1) {
        notifyer.warning('You should upload only one image file')

        return
      }

      if (!uploadedFiles[0].type.includes('image')) {
        notifyer.warning('Please upload image file!')

        return
      }

      setFiles([...uploadedFiles])
      handleUploadImage(jsonToFormData(uploadedFiles))
  }, [setFiles, handleUploadImage])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  const handleSubmit = useCallback(async (e: any) => {
    e.preventDefault()
    if (!name.length || !email.length || !password.length || !files.length) {
      notifyer.error('Fill in all the form fields')

      return
    }

    if (password.length < 8) {
      notifyer.warning('Password should be of minimum 8 characters length')

      return
    }

    if (supportList.find((support) => support.email === email)) {
      notifyer.error('User with this email is already registered')

      return
    }

    if (!adaptedFile?.path.length) {
      notifyer.warning('Please repeat image upload or try another one')

      return
    }

    await handleCreateUser({
      name,
      email,
      password,
      role: UserRole.SUPPORT,
      avatar: adaptedFile.path,
    })

    setName('')
    setEmail('')
    setPassword('')
    setFiles([])
    handleClearUploadedImage()
    setModalOpen(false)
  }, [name, email, password, files, supportList, adaptedFile, setModalOpen, setName, setEmail, setPassword, setFiles, handleCreateUser, handleClearUploadedImage])

  const handleNameChange = useCallback((e: any) => {
    setName(e.target.value)
  }, [setName])

  const handleEmailChange = useCallback((e: any) => {
    setEmail(e.target.value)
  }, [setEmail])

  const handlePasswordChange = useCallback((e: any) => {
    setPassword(e.target.value)
  }, [setPassword])

  return (
    <div className={classes.supportsModalContent}>
      <p className={classes.modalTitle}>
        Creating new profile
      </p>
      <form className={classes.modalControlsWrapper} onSubmit={handleSubmit}>
        <input
          value={name}
          onChange={handleNameChange}
          className={classes.modalInput}
          autoComplete="off"
          placeholder="Name"
        />
        <input
          value={email}
          onChange={handleEmailChange}
          className={classes.modalInput}
          autoComplete="off"
          placeholder="Email"
          type="email"
        />
        <input
          value={password}
          onChange={handlePasswordChange}
          className={classes.modalInput}
          autoComplete="off"
          placeholder="Password"
          type="password"
        />
        <div className={clsx(classes.dropzoneWrapper, isDragActive && classes.dropzoneWrapperActive)} {...getRootProps()}>
          <input {...getInputProps()} />
          {uploadImagePending ?
            <ClipLoader size={37}/>
            :
            adaptedFile?.name.length ?
              <p className={classes.dropzoneText}>{adaptedFile.name}</p>
              :
              <>
                <DragIcon />
                <p className={classes.dropzoneText}>{isDragActive ? 'Drag here' : 'Upload avatar'}</p>
              </>
          }
        </div>
        <button type="submit" className={classes.modalButton} onClick={handleSubmit}>
          Create profile
        </button>
      </form>
    </div>
  )
}

export default SupportsModalInner
