import { Dispatch, SetStateAction } from 'react'
import type { IFile } from 'src/common/interfaces'

export interface IFilesList {
  files: File[]
  filesAdapted: IAdaptedFiles[]
  orderId: number
  changeFilesAdapted: (files: IAdaptedFiles[]) => void
  setFiles: Dispatch<SetStateAction<File[]>>
}

export interface IAdaptedFiles extends IFile {
  id: number
  status: FilesStatus
  error?: string
}

export enum FilesStatus {
  PENDING = 'pending',
  SUCCESS = 'success',
  ERROR = 'error'
}
