import { FC, useState, useRef, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import _throttle from 'lodash/throttle'

import TextareaAutosize from 'react-textarea-autosize'
import { Picker } from 'emoji-mart'

import { saveEnteredDataByRoom, updateStoreOnWriterMessage } from 'src/store/actions/messagesData'

import { IWriter } from 'src/common/interfaces'
import type { IAdaptedFiles } from 'src/components/chat/chat-area/message-form/files-list/types'

import { ReactComponent as EmojiPickerIcon } from 'src/media/images/emojiPickerIcon.svg'

import 'emoji-mart/css/emoji-mart.css'
import useStyles from './styles'
import { UserRole } from 'src/common/constants'
import { IStore } from 'src/store/types'
import { FilesStatus } from './files-list/types'
import { useSocketEmit } from 'src/socket/socketControl'
import UploadButton from './upload-button'
import FilesList from './files-list'
import TemplatesButton from './templates-button'
import { useTemplates } from './useTemplates'
import { keyboardEventsHandler } from './keyboardEventsHandler'
import { markUpdateOrderDetailsViewed } from 'src/store/actions/chatData'

interface IMessageForm {
  writer: IWriter
  orderId: number
}
const MessageForm: FC<IMessageForm> = ({
  writer,
  orderId,
}) => {
  const { sendMessageBySocket, listenMessageTyping } = useSocketEmit()
  const dispatch = useDispatch()

  const messagesEndNode = useSelector((state: IStore) => state.appData.messagesEndNode)
  const unsendedData = useSelector((state: IStore) => state.messagesData.enteredDataByRoom)
  const supportAvatar = useSelector((state: IStore) => state.authData.data.supportAvatar)

  const initialMessage = unsendedData[`${orderId}${writer.id}`]?.text ? unsendedData[`${orderId}${writer.id}`].text : ''
  const initialFiles = unsendedData[`${orderId}${writer.id}`]?.files ? unsendedData[`${orderId}${writer.id}`].files : []
  const initialAdaptedFiles = unsendedData[`${orderId}${writer.id}`]?.adaptedFiles ? unsendedData[`${orderId}${writer.id}`].adaptedFiles : []

  const classes = useStyles()

  const [message, setMessage] = useState<string>(initialMessage)
  const [filesAdapted, setFilesAdapted] = useState<IAdaptedFiles[]>(initialAdaptedFiles)
  const [files, setFiles] = useState<File[]>(initialFiles)
  const [showEmoji, setShowEmoji] = useState(false)

  const templatesListRef = useRef(null)
  const emojiPickerRef = useRef(null)
  const messageFormRef = useRef(null)
  const textAreaRef = useRef(null)

  const updateStoreOnMessage = useCallback((message) => dispatch(updateStoreOnWriterMessage(orderId, writer.id, message)), [orderId, writer, dispatch])
  const handleMarkUpdateOrderDetailsViewed = useCallback(() => dispatch(markUpdateOrderDetailsViewed(orderId)), [dispatch, orderId])

  const handleSaveUnsendData = useCallback((text, adaptedFiles) => dispatch(
    saveEnteredDataByRoom({
      orderId,
      writerId: writer.id,
      text,
      adaptedFiles,
    })
  ), [orderId, writer.id, dispatch])

  useEffect(() => {
    setMessage(initialMessage)
    setFilesAdapted(initialAdaptedFiles)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, writer.id])

  useEffect(() => {
    handleSaveUnsendData(message, filesAdapted)
  }, [message, filesAdapted, handleSaveUnsendData])

  const {
		templateValue,
    setTemplateValue,
		showTemplatePhrases,
		setShowTemplatePhrases,
		activeTemplateIndex,
    setActiveTemplateIndex,
		handleChangeTemplateValue,
		handleAddTemplateToMessageText,
    filteredTemplatesKeysArray,
	} = useTemplates({message, setMessage, templatesListRef, textAreaRef})

  const scrollMessagesListToBottom = useCallback(() => {
    setTimeout(() => {
      if (!messagesEndNode) {
        return
      }

      messagesEndNode.scrollIntoView({behavior: 'smooth'})
    })
  }, [messagesEndNode])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleTyping = useCallback(_throttle(() => {
    listenMessageTyping({ orderId, writerId: writer.id })
  }, 1000), [orderId, writer.id])

  const handleChangeMessage = (e: any) => {
    setMessage(e.target.value)
    handleTyping()
  }

  const handleEmojiShowTrigger = useCallback(() => {
    setShowEmoji(!showEmoji)
  }, [setShowEmoji, showEmoji])

  const handleEmojiSelect = (e: any) => {
    setMessage((message) => (message += e.native))
    // @ts-ignore
    textAreaRef.current && textAreaRef.current.focus()
  }

  const sendMessage = useCallback(({ messageText, filteredFiles }: { messageText: string, filteredFiles: IAdaptedFiles[] }) => {
    const newMessage = {
      content: messageText,
      sender: {
        id: writer.id,
        name: writer.name,
        avatar: writer.avatar,
        role: UserRole.SUPPORT,
      },
      sentAt: Date.now(),
      viewed: false,
      identifiyers: {
        group: orderId,
        identifyer: writer.id,
      },
      files: filteredFiles,
      clientHashId: `${Date.now()}`,
      pending: true,
    }

    sendMessageBySocket({
      content: newMessage.content,
      files: filteredFiles,
      identifiyers: newMessage.identifiyers,
      clientHashId: `${newMessage.clientHashId}`,
    })

    updateStoreOnMessage({
      ...newMessage,
      sender: {
        ...newMessage.sender,
        avatar: supportAvatar,
      }
    })

    scrollMessagesListToBottom()
    showEmoji && handleEmojiShowTrigger()
    setMessage('')
    setFiles([])
    handleMarkUpdateOrderDetailsViewed()
    handleTyping.cancel()
  }, [handleEmojiShowTrigger, handleMarkUpdateOrderDetailsViewed, orderId, scrollMessagesListToBottom, sendMessageBySocket, showEmoji, updateStoreOnMessage, writer, supportAvatar, handleTyping])


  const handleSendMessage = useCallback((e: any) => {
    e.preventDefault()

    const filteredFiles = filesAdapted.filter((file: IAdaptedFiles) => file.status === FilesStatus.SUCCESS)
    const trimmed = message.trim()

    if (trimmed || filteredFiles.length) {
      sendMessage({ messageText: message, filteredFiles })
    }
  }, [filesAdapted, message, sendMessage])

  const handleKeyDown = useCallback((e: any) => {
    keyboardEventsHandler({
      e,
      message,
      showTemplatePhrases,
      templateValue,
      setTemplateValue,
      filteredTemplatesKeysArray,
      handleChangeTemplateValue,
      handleAddTemplateToMessageText,
      handleSendMessage,
      setShowTemplatePhrases,
      activeTemplateIndex,
      setActiveTemplateIndex,
    })
  }, [message, showTemplatePhrases, templateValue, setTemplateValue, filteredTemplatesKeysArray, handleChangeTemplateValue, handleAddTemplateToMessageText, handleSendMessage, setShowTemplatePhrases, activeTemplateIndex, setActiveTemplateIndex])

  const hideEmojiByClickOutside = useCallback((event) => {
    if (
      emojiPickerRef.current &&
      messageFormRef.current &&
      //@ts-ignore
      !emojiPickerRef.current.contains(event.target) && !messageFormRef.current.contains(event.target)
    ) {
      handleEmojiShowTrigger()
      window.removeEventListener('click', hideEmojiByClickOutside)
    }
  }, [handleEmojiShowTrigger])

  useEffect(() => {
    showEmoji && window.addEventListener('click', hideEmojiByClickOutside)
  }, [showEmoji, hideEmojiByClickOutside])

  const changeFilesAdapted = (adaptedFiles: IAdaptedFiles[]) => {
    setFilesAdapted([...adaptedFiles])
  }

  return (
    <div className={classes.messageFormWrapper}>
      <FilesList
        files={files}
        filesAdapted={filesAdapted}
        changeFilesAdapted={changeFilesAdapted}
        orderId={orderId}
        setFiles={setFiles}
      />
      <form
        ref={messageFormRef}
        className={classes.messageFormInner}
        onSubmit={handleSendMessage}
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className={clsx(classes.iconWrapper, showEmoji && classes.iconWrapperChecked)}
          role="button"
          onClick={handleEmojiShowTrigger}
        >
          <EmojiPickerIcon />
        </a>
        <TextareaAutosize
          ref={textAreaRef}
          maxRows={12}
          className={classes.messageInput}
          value={message}
          onChange={handleChangeMessage}
          onKeyDown={handleKeyDown}
          placeholder='Write a message...'
        />
        <TemplatesButton
          message={message}
          setMessage={setMessage}
          showTemplatePhrases={showTemplatePhrases}
          setShowTemplatePhrases={setShowTemplatePhrases}
          activeTemplateIndex={activeTemplateIndex}
          handleChangeTemplateValue={handleChangeTemplateValue}
          handleAddTemplateToMessageText={handleAddTemplateToMessageText}
          templateValue={templateValue}
          setTemplateValue={setTemplateValue}
          setActiveTemplateIndex={setActiveTemplateIndex}
          templatesListRef={templatesListRef}
          textAreaRef={textAreaRef}
        />
        <UploadButton
          setFiles={setFiles}
        />
        {showEmoji && (
          <div ref={emojiPickerRef}>
            <Picker
              style={{ position: 'absolute', bottom: '58px', left: 0 }}
              onSelect={handleEmojiSelect}
              emojiSize={20}
              showSkinTones={false}
              showPreview={false}
            />
          </div>
        )}
      </form>
    </div>
  )
}

export default MessageForm
