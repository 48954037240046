import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  chatGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 34px 0 16px',
    height: '65px',
    maxHeight: '65px',
    transition: '0.3s background',
    cursor: 'pointer',

    '&:not(:first-child)': {
      borderTop: '1px solid #E6E6E6',
    },

    '&:hover': {
      background: 'rgba(40, 68, 123, 0.03)',
    }
  },

  activeGroup: {
    background: 'rgba(40, 68, 123, 0.09)',

    '&:hover': {
      background: 'rgba(40, 68, 123, 0.09)',
    }
  },

  customerName: {
    marginLeft: '13px',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '19px',
    color: '#28447B',
  },

  chatGroupControlsWrapper: {
    display: 'flex',
    alignItems: 'center',
  },

  updateStatusBadge: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '10px',
    padding: '2px 5px',
    borderRadius: '20px',
    background: '#FFC107',
    color: '#523D00',
    fontSize: '12px',
    fontWeight: 700,
  },
})

export default makeStyles(styles)
