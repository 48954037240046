import { APIv1 } from 'src/api'
import { call, put } from 'redux-saga/effects'

import notifyer from 'src/common/notifyer'
import { ISupportItem } from 'src/store/reducers/admin-data'
import { USER_LIST_CHANGED } from 'src/store/types/adminData'

const postEditUser = (userData: ISupportItem): Promise<any> => (
  APIv1.post(`/users/update/${userData.id}`, { userData })
)

export default function* editUser(action: any) {
  try {
    // @ts-ignore
    yield call(postEditUser, action.payload)
    notifyer.success(`User data for ${action.payload.name} was updated`)
    yield put({ type: USER_LIST_CHANGED })
  } catch (e) {
    notifyer.error('Request failed - server error')
  }
}
