import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  chosenFiltersWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },

  chosenListItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxHeight: '20px',
    marginBottom: '3px',
    padding: '2px 3px',
    marginRight: '5px',
    border: '1px solid #28447B',
    borderRadius: '8px',
    fontSize: '13px',
    color: '#28447B',
  },

  clearFiltersButton: {
    cursor: 'pointer',
    border: '1px solid #E34848',
    color: '#E34848',
  },

  acceptFiltersButton: {
    border: '1px solid #2DBB6E',
    background: '#2DBB6E',
    color: '#fff',
    cursor: 'pointer',
  },

  crossIconWrapper: {
    marginLeft: '2px',
    cursor: 'pointer',

    '& svg': {
      width: '10px',
      height: '10px',
    }
  },

  disabledButton: {
    opacity: 0.4,
    pointerEvents: 'none',
    cursor: 'default',
  }
})

export default makeStyles(styles)
