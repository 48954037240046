import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  wrapper: {

  },

  orderDetailsListItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 0',

    '&:first-child': {
      padding: '0 0 16px',
    },

    '&:not(:first-child)': {
      padding: '16px 0',
    },

    '&:not(:last-child)': {
      borderBottom: '1px solid #E6E6E6',
    }
  },

  itemTitle: {
    marginBottom: '7px',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '19px',
    color: '#171717',
    textTransform: 'capitalize',
  },

  itemValue: {
    fontSize: '16px',
    lineHeight: '19px',
    color: '#4A4A4A',
    opacity: 0.8,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'capitalize',
  },
})

export default makeStyles(styles)
