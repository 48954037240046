import toastr from 'toastr'

toastr.options = {
  closeButton: false,
  progressBar: true,
  positionClass: 'toast-bottom-right',
  timeOut: 3000,
  extendedTimeOut: 1000,
}

const notify = (messageText, messageType) => {
  const notificationsSystem = toastr

  if (!notificationsSystem) {
    // eslint-disable-next-line no-alert
    alert(messageText)
    return
  }

  if (!notificationsSystem[messageType]) {
    notificationsSystem.info(messageText)
    // eslint-disable-next-line no-console
    console.warn(`Notification type '${messageType}' does not specify!`)
    return
  }

  notificationsSystem[messageType](messageText)

}

const notifyer = {
  success: (messageText) => notify(messageText, 'success'),
  error: (messageText) => notify(messageText, 'error'),
  warning: (messageText) => notify(messageText, 'warning'),
  info: (messageText) => notify(messageText, 'info'),
  notify: (messageText, messageType) => notify(messageText, messageType),
}

export default notifyer
