import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  uploadButtonLabel: {
    width: '17px',
    height: '24px',
    cursor: 'pointer',
  },

  filesInput: {
    display: 'none',
  },
})

export default makeStyles(styles)
