import { Dispatch } from 'redux'
import { NavbarButtonValues, UserRole } from 'src/common/constants'
import { IMessageData } from 'src/common/interfaces'

import {
  DECREMENT_UNREAD_QUANTITY,
  FETCH_CHAT_DATA_SAGA,
  INCREMENT_UNREAD_QUANTITY,
  ORDER_DETAILS_UPDATE_VIEWED,
  UPDATE_LAST_ROOM_MESSAGE,
  UPDATE_UNANSWERED_CHATS,
} from 'src/store/types/chatData'
import { SOCKET_CONNECTION_DISCONNECTED, TRANSFER_CHAT_SAGA } from '../types/appControl'

export const fetchChatsData = () => (dispatch: Dispatch) => {
  dispatch({
    type: FETCH_CHAT_DATA_SAGA,
  })
}

export const incrementUnreadQuantityOnNewMessage = (senderRole: UserRole, orderId: number, writerId: number) => (dispatch: Dispatch) => {
  dispatch({
    type: INCREMENT_UNREAD_QUANTITY,
    payload: {
      senderRole,
      writerId,
      orderId,
    }
  })
}

export const decrementUnreadQuantity = (orderId: number, writerId: number) => (dispatch: Dispatch) => {
  dispatch({
    type: DECREMENT_UNREAD_QUANTITY,
    payload: {
      writerId,
      orderId,
    }
  })
}

export const fetchTransferRequest = (orderId: number, activeTab: NavbarButtonValues) => (dispatch: Dispatch) => {
  dispatch({
    type: TRANSFER_CHAT_SAGA,
    payload: {
      orderId,
      activeTab,
    }
  })
}

export const updateLastRoomMessage = (message: IMessageData,) => (dispatch: Dispatch) => {
  dispatch({
    type: UPDATE_LAST_ROOM_MESSAGE,
    payload: {
      message,
    }
  })
}

export const handleSocketDisconnected = () => (dispath: Dispatch) => {
  dispath({
    type: SOCKET_CONNECTION_DISCONNECTED,
  })
}

export const markUpdateOrderDetailsViewed = (orderId: string | number,) => (dispatch: Dispatch) => {
  dispatch({
    type: ORDER_DETAILS_UPDATE_VIEWED,
    payload: {
      orderId,
    }
  })
}

export const updateUnansweredChats = ({
  key, value
} : {key: string, value: boolean}) => (dispatch: Dispatch) => {
  dispatch({
    type: UPDATE_UNANSWERED_CHATS,
    payload: {
      key,
      value,
    }
  })
}
