export const AUTH_SAGA: 'AUTH_SAGA' = 'AUTH_SAGA'

export const FETCH_AUTH_DATA_PENDING: 'FETCH_AUTH_DATA_PENDING' = 'FETCH_AUTH_DATA_PENDING'
export const FETCH_AUTH_DATA_SUCCEEDED: 'FETCH_AUTH_DATA_SUCCEEDED' = 'FETCH_AUTH_DATA_SUCCEEDED'
export const FETCH_AUTH_DATA_FAILED: 'FETCH_AUTH_DATA_FAILED' = 'FETCH_AUTH_DATA_FAILED'
export const AUTH_LOG_OUT: 'AUTH_LOG_OUT' = 'AUTH_LOG_OUT'

export const SOCKET_USER_CONNECTED: 'USER_CONNECTED' = 'USER_CONNECTED'
export const SOCKET_USER_DISCONNECTED: 'USER_DISCONNECTED' = 'USER_DISCONNECTED'

export const REFRESH_TOKEN_SAGA: 'REFRESH_TOKEN_SAGA' = 'REFRESH_TOKEN_SAGA'

export const FETCH_REFRESH_TOKEN_PENDING: 'FETCH_REFRESH_TOKEN_PENDING' = 'FETCH_REFRESH_TOKEN_PENDING'
export const FETCH_REFRESH_TOKEN_SUCCEEDED: 'FETCH_REFRESH_TOKEN_SUCCEEDED' = 'FETCH_REFRESH_TOKEN_SUCCEEDED'
export const FETCH_REFRESH_TOKEN_FAILED: 'FETCH_REFRESH_TOKEN_FAILED' = 'FETCH_REFRESH_TOKEN_FAILED'
