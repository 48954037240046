import { APIv1 } from 'src/api'
import { call, put } from 'redux-saga/effects'

import notifyer from 'src/common/notifyer'
import { IFile } from 'src/common/interfaces'
import { UPLOAD_IMAGE_FAILED, UPLOAD_IMAGE_PENDING, UPLOAD_IMAGE_SUCCEEDED } from 'src/store/types/adminData'

const uploadImageRequest = (files: IFile[]): Promise<any> => (
  APIv1.post('/users/upload-avatar', files)
)

export default function* uploadImage(action: any) {
  try {
    yield put({ type: UPLOAD_IMAGE_PENDING })

    // @ts-ignore
    const result = yield call(uploadImageRequest, action.payload)
    yield put({ type: UPLOAD_IMAGE_SUCCEEDED, payload: { path: result.data.path, name: result.data.name }})
  } catch (e) {
    notifyer.error('Upload image failed - server error')
    yield put({ type: UPLOAD_IMAGE_FAILED })
  }
}
