import { Dispatch, useCallback, useEffect } from "react";
import { AnyAction } from "redux";
import { Socket } from "socket.io-client";
import { decrementUnreadQuantity } from 'src/store/actions/chatData'

export const useMessageViewed = (socket: Socket, dispatch: Dispatch<any>) => {
  const handleMessageView = useCallback((data) => dispatch({ ...data }), [dispatch]) // SOCKET_MESSAGE_VIEWED
  const handleDecrementUnreadQuantity = useCallback((data) => dispatch(
    decrementUnreadQuantity(data.group, data.identifyer)
  ), [dispatch])


  useEffect(() => {
    const handler = (data: AnyAction) => {
      handleMessageView(data)
      handleDecrementUnreadQuantity(data.payload)
    }

    socket.on('message-viewed', handler)
    return () => {
      socket.off('message-viewed', handler)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
