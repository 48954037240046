import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  loginWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    minHeight: '100vh',
    width: '100%',
  },

  loginInner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '400px',
  },

  loginHeader: {
    margin: 0,
    fontSize: '24px',
    fontWeight: 700,
  },

  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    width: '100%',
  },

  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },

  inputLabel: {
    marginLeft: '5px',
    opacity: 0.7,
  },

  input: {
    height: '30px',
    padding: '0 20px',
    border: '1px solid rgba(110, 110, 110, 0.5)',
    borderRadius: '10px',
  },

  inputError: {
    border: '1px solid #EB001B',
  },

  inputHint: {
    color: '#EB001B',
    marginLeft: '5px',
    opacity: 0.7,
    fontSize: '14px',
  },

  submitButton: {
    height: '35px',
    border: 'none',
    borderRadius: '10px',
    background: '#28447B',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#fff',
    cursor: 'pointer',
    opacity: 0.9,
    transition: 'opacity 0.3s',

    '&:hover': {
      opacity: 1,
    }
  },

  submitButtonDisabled: {
    opacity: 0.5,
    background: '#ccc',
    cursor: 'initial',
  }
})

export default makeStyles(styles)
