import { FC, useEffect, useState } from 'react'
import { io, Socket } from 'socket.io-client'

import AdminMenu from './adminMenu'

export enum MenuButtons {
  SUPPORTS = 'supports',
  CHAT_LIMITS = 'chat-limits',
  TEMPLATES = 'templates'
}

const AdminMenuEntry: FC = () => {
  const [adminSocket, setAdminSocket] = useState<Socket | null>(null)

  useEffect(() => {
    const url = new URL(process.env.REACT_APP__SOCKET__URL as string, window.location.origin)
    url.pathname = process.env.REACT_APP__SOCKET__ADMIN_NAMESPACE as string

    setAdminSocket(io(url.toString(), {
      path: process.env.REACT_APP__SOCKET__PATH,
      auth: {
        authorization: `Bearer ${localStorage.getItem('authToken') ? `${localStorage.getItem('authToken')}` : ''}`,
      },
      transports: ['websocket'],
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!adminSocket) {
    return null
  }

  return (
    <AdminMenu adminSocket={adminSocket} />
  )
}

export default AdminMenuEntry
