import React, { useContext } from 'react'
import { Socket } from 'socket.io-client'

interface ISocketContext {
  socket: null | Socket
}

const SocketContext = React.createContext<ISocketContext>({
  socket: null,
})

export default SocketContext

export const useSocketContext = () => {
  const { socket } = useContext(SocketContext)

  return socket as Socket
}
