import { FC, useState, useCallback, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { APIv1 } from 'src/api'
import { NavbarButtonValues } from 'src/common/constants'
import notifyer from 'src/common/notifyer'

import { ReactComponent as ChatGroupIconButton } from 'src/media/images/chatGroupIconButton.svg'
import { ReactComponent as ChatGroupIconButtonActive } from 'src/media/images/chatGroupIconButtonActive.svg'

import { useSocketEmit } from 'src/socket/socketControl'
import { fetchTransferRequest } from 'src/store/actions/chatData'
import { IStore } from 'src/store/types'

import useStyles from './styles'

interface IChatGroupMenu {
  orderId: number
  activeTab: NavbarButtonValues
}

const ChatGroupMenu: FC<IChatGroupMenu> = ({orderId, activeTab}) => {
  const classes = useStyles()
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const menuWrapperRef = useRef(null)
  const { closeChat } = useSocketEmit()

  const dispatch = useDispatch()
  const handleFetchTransferRequest = useCallback(() => dispatch(fetchTransferRequest(orderId, activeTab)), [dispatch, orderId, activeTab])
  const supportConnected = useSelector((state: IStore) => state.appData.supportConnected)

  const handleButtonClick = (e: any) => {
    e.preventDefault()
    e.stopPropagation()

    setOpenMenu(!openMenu)
  }

  const hideClickOutside = useCallback((event) => {
    if (
      menuWrapperRef.current &&
      //@ts-ignore
      !menuWrapperRef.current.contains(event.target)
    ) {
      setOpenMenu(false)
      window.removeEventListener('click', hideClickOutside)
    }
  }, [setOpenMenu])

  const handleClose = useCallback(() => {
    closeChat({orderId})
  }, [closeChat, orderId])

  const handleBan = useCallback(() => {
    APIv1.post(
      `/chat/support/blacklist`, { orderId })
    .then((res: any) => {
      notifyer.success('User successfully banned')
    })
    .catch(() => {
      notifyer.error('User is not banned - server error. Try again')
    })
  }, [orderId])

  const handleTransferChat = () => {
    handleFetchTransferRequest()
  }

  useEffect(() => {
    openMenu && window.addEventListener('click', hideClickOutside)
  }, [openMenu, hideClickOutside])

  return (
    <div className={classes.chatGroupButton} onClick={handleButtonClick}>
      {openMenu ? <ChatGroupIconButtonActive /> : <ChatGroupIconButton />}
      {openMenu && (
        <div ref={menuWrapperRef} className={classes.menuWrapper}>
          {supportConnected && (
            <button onClick={handleTransferChat} className={classes.menuButton}>
              {activeTab === NavbarButtonValues.ACTIVE ? 'Transfer chat' : 'Take to Active'}
            </button>
          )}
          <button onClick={handleBan} className={classes.menuButton}>
            Ban chat
          </button>
          <button onClick={handleClose} className={classes.menuButton}>
            Close chat
          </button>
        </div>
      )}
    </div>
  )
}

export default ChatGroupMenu
