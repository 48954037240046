import { FC } from 'react'
import clsx from 'clsx'

import Avatar from '../../avatar'

import { IUserData } from 'src/common/interfaces'

import useStyles from './styles'
import GeoDetails from './GeoDetails'

interface IClientInfo {
  customer: IUserData
  activeChatsCount?: IUserData['activeChatsCount']
}

const ClientInfo: FC<IClientInfo> = ({
  customer,
  activeChatsCount,
}) => {
  const classes = useStyles()

  const mainInfoList = [
    {country: customer.country},
    {os: customer.os},
    {email: customer.email},
    {id: customer.id},
  ]

  return (
    <div className={classes.clientInfoWrapper}>
      <div className={classes.headerInfo}>
        <Avatar
          userName={customer.name}
          avatar={customer.avatar}
        />
        <p className={classes.headerIext}>
          <span className={classes.clientName}>{customer.name}</span>
          {activeChatsCount && (
            <span className={classes.activeChatsCount}>
              {activeChatsCount} active {activeChatsCount > 1 ? 'chats' : 'chat'}
            </span>
          )}
        </p>
      </div>
      <div className={classes.mainInfoWrapper}>
        {mainInfoList.map((item) => {
          const [key, value] = Object.entries(item)[0]

          if (!value) {
            return null
          }

          return (
            <p
              key={key}
              className={classes.mainInfoItem}>
              <span className={classes.mainInfoHighlited}>
                {key}
              </span>
              <span className={classes.mainInfoValue}>
                {value}
              </span>
            </p>
          )
        })}

        {customer.ip && <GeoDetails ip={customer.ip} />}

        {customer.pageFrom && (
          <p className={clsx(classes.mainInfoItem, classes.mainInfoLinkItem)}>
            <span className={classes.mainInfoHighlited}>
              Page
            </span>
            <a href={customer.pageFrom} className={clsx(classes.mainInfoValue, classes.pageLink)}>
              {customer.pageFrom}
            </a>
          </p>
        )}
      </div>
    </div>
  )
}

export default ClientInfo
