import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  chatMessageWrapper: {
    display: 'flex',
    padding: '10px 0',
  },

  chatMessageInner: {
    display: 'flex',
    alignItems: 'flex-end',
    maxWidth: '450px',
  },

  clientMessageWrapper: {
    justifyContent: 'flex-start',

    '& $messageWrapper': {
      marginLeft: '10px',
      padding: '7px 13px 10px 10px',
      borderRadius: '8px 8px 8px 0px',
      backgroundColor: '#fff',
    },

    '& $senderName, & $fileSize': {
      color: '#28447B',
      opacity: 0.5,
    },

    '& $messageText, & $fileName': {
      color: '#1B1A57',
    },

    '& $downloadIconWrapper': {
      color: '#28447B',
    },

    '& $sendedTime': {
      color: '#A1A1BC',
    },
  },

  supportMessageWrapper: {
    justifyContent: 'flex-end',

    '& $messageWrapper': {
      order: 1,
      marginRight: '10px',
      padding: '7px 10px 10px 13px',
      borderRadius: '8px 8px 0px 8px',
      backgroundColor: '#28447B',
    },

    '& $avatarWrapper': {
      order: 2,
    },

    '& $senderName, & $fileSize': {
      color: '#F5F5F5',
      opacity: 0.5,
    },

    '& $messageText, & $downloadIconWrapper, & $sendedTime': {
      color: '#fff',
    },

    '& $fileName': {
      color: '#fff',
      opacity: 0.7,
    },
  },

  avatarWrapper: {

  },

  messageWrapper: {

  },

  senderName: {
    margin: '0 0 3px',
    fontSize: '12px',
    lineHeight: '14px',
  },

  messageText: {
    margin: '0 0 2px',
    fontSize: '16px',
    lineHeight: '18px',
  },

  fileListWrapper: {
    margin: '10px 0 0',
  },

  fileWrapper: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    textDecoration: 'none',

    '&:not(:last-child)': {
      margin: '0 10px 10px 0'
    },

    '&:hover': {
      '& $fileName': {
        textDecoration: 'underline',
      },

      '& $downloadIconWrapper': {
        opacity: 1,
      }
    },
  },

  downloadIconWrapper: {
    display: 'flex',
    marginRight: '8px',
    opacity: 0.8,
    transition: 'opacity 0.3s',
  },

  fileInfo: {
    display: 'flex',
    flexDirection: 'column',
  },

  fileName: {
    margin: 0,
    fontSize: '16px',
    lineHeight: '20px',
  },

  fileSize: {
    fontSize: '13px',
    lineHeight: '14px',
  },

  messageFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  sendedTime: {
    margin: 0,
    fontSize: '12px',
    lineHeight: '14px',
  },

  statusIconWrapper: {
    display: 'flex',
    marginLeft: '5px',
  },
})

export default makeStyles(styles)
