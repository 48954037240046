import { FC } from 'react'

import OrderDetailsHeader from './order-details-header'
import OrderDetailsList from './order-details-list'

import { IOrder } from 'src/common/interfaces'

import useStyles from './styles'

const OrderDetails: FC<{ order: IOrder }> = ({
  order,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.orderDetailsWrapper}>
      <OrderDetailsHeader orderLink={`${process.env.REACT_APP__ESSAYCOCK_ORDER_LINK}${order.id}`} />
      <OrderDetailsList {...order} />
    </div>
  )
}

export default OrderDetails
