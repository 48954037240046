import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  ratingValue: {
    padding: '3px 5px',
    borderRadius: '31px',
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: '12px',
    textTransform: 'uppercase',
    color: '#fff',
  },

  default: {
    display: 'none',
  },

  Pro: {
    background: '#CD5C5C',
  },

  Top: {
    background: '#E98D20',
  },

  Regular: {
    background: '#28447B',
  }
})

export default makeStyles(styles)
