import { FC, useCallback, useState } from 'react'
import clsx from 'clsx'

import { IChatData, IOrderChatGroup } from 'src/common/interfaces'

import useStyles from './styles'
import ChatGroup from './chat-group'
import ChatRoom from './chat-room'
import { markUpdateOrderDetailsViewed } from 'src/store/actions/chatData'
import { useDispatch } from 'react-redux'

interface IChatListItem {
  currentChatGroupData: IOrderChatGroup | null
  currentRoomData: IChatData | null
  groupIndex: number
  groupData: IOrderChatGroup
  handleSetCurrentGroupData: (groupIndex: number) => void
  handleSetCurrentRoomData: (groupIndex: number, roomIndex: number) => void
}

const ChatListItem: FC<IChatListItem> = ({
  groupIndex,
  groupData,
  currentRoomData,
  currentChatGroupData,
  handleSetCurrentGroupData,
  handleSetCurrentRoomData,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleMarkUpdateOrderDetailsViewed = useCallback((orderId) => dispatch(markUpdateOrderDetailsViewed(orderId)), [dispatch])

  const [expanded, setExpanded] = useState<boolean>(false)

  const handleChatRoomButton = useCallback((e) => {
    handleSetCurrentRoomData(parseInt(e.currentTarget.dataset.orderid), parseInt(e.currentTarget.dataset.writerid))
    handleMarkUpdateOrderDetailsViewed(parseInt(e.currentTarget.dataset.orderid))
  }, [handleSetCurrentRoomData, handleMarkUpdateOrderDetailsViewed])

  const handleChatGroupButton = useCallback((e) => {
    setExpanded(!expanded)

    handleSetCurrentGroupData(parseInt(e.currentTarget.dataset.orderid))
    handleMarkUpdateOrderDetailsViewed(parseInt(e.currentTarget.dataset.orderid))
  }, [handleSetCurrentGroupData, handleMarkUpdateOrderDetailsViewed, setExpanded, expanded])

  return (
    <div
        className={clsx(classes.chatGroupWrapper, expanded && classes.activeGroupWrapper)}
    >
      <ChatGroup
        key={groupData.order.id}
        orderId={groupData.order.id}
        groupData={groupData}
        currentChatGroupData={currentChatGroupData}
        handleChatGroupButton={handleChatGroupButton}
      />
      <div className={classes.roomsWrapper}>
        {Object.entries(groupData.chats).map(([writerId, roomData]) => (
          <ChatRoom
            key={parseInt(`${writerId}${groupData.order.id}`)}
            groupIndex={groupIndex}
            writerId={writerId}
            roomData={roomData}
            chatGroup={groupData}
            currentChatGroupData={currentChatGroupData}
            currentRoomData={currentRoomData}
            handleChatRoomButton={handleChatRoomButton}
          />
        ))}
      </div>
    </div>
  )
}

export default ChatListItem
