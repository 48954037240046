import { FC, RefObject, useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'

import ArchiveFilters from './filters'

import { ReactComponent as ChevronIcon } from 'src/media/images/chevronIcon.svg'

import useStyles from './styles'
import ChosenFilters from './chosen-filters'
import { useDispatch, useSelector } from 'react-redux'
import { IArchiveRequestProps } from 'src/store/sagas/find-in-archive/fetchArchive'
import { clearArchiveData, findArchive } from 'src/store/actions/archiveData'
import { usePagination } from './usePagination'
import { IStore } from 'src/store/types'
import moment from 'moment'
interface IArchiveHeader {
  archiveListWrapperRef: RefObject<HTMLElement>
}

const ArchiveHeader: FC<IArchiveHeader> = ({archiveListWrapperRef}) => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const handleFindArchive = useCallback((options : IArchiveRequestProps) => dispatch(findArchive({...options})), [dispatch])
  const handleClearArchiveData = useCallback(() => dispatch(clearArchiveData()), [dispatch])

  const archiveListEndVisibility = useSelector((state: IStore) => state.appData.archiveListEndVisibility)
  const archiveChatsData = useSelector((state: IStore) => state.archiveData.data)
  const fetchArchivePending = useSelector((state: IStore) => state.archiveData.fetchArchivePending)
  const emptyArchiveResponseRecived = useSelector((state: IStore) => state.archiveData.emptyResponseRecived)

  const [showFilters, setShowFilters] = useState<boolean>(false)
  const [filtersCount, setFilterCount] = useState<number>(0)
  const [[startDate, endDate], setDateRange] = useState<null[] | Date[]>([null, null])
  const [showLoadMoreButton, setShowLoadMoreButton] = useState<boolean>(false)

  const toggleFiltersVisibility = useCallback(() => {
    setShowFilters(!showFilters)
  }, [showFilters, setShowFilters])

  const [orderFilter, setOrderFilter] = useState<number | undefined>(undefined)
  const [supportIds, setSupportIds] = useState<string[]>([])
  const [page, setPage] = useState<number>(1)

  usePagination({
    page,
    setPage,
    orderId: orderFilter,
    supportIds,
    startDate,
    endDate,
  })

  useEffect(() => {
    if (
      !emptyArchiveResponseRecived &&
      archiveChatsData &&
      archiveChatsData.size &&
      archiveListWrapperRef.current &&
      archiveListWrapperRef.current.scrollHeight <= archiveListWrapperRef.current.clientHeight
    ) {
      setShowLoadMoreButton(true)
    } else {
      setShowLoadMoreButton(false)
    }
  }, [archiveListWrapperRef, archiveListEndVisibility, archiveChatsData, emptyArchiveResponseRecived, setShowLoadMoreButton])

  useEffect(() => {
    handleClearArchiveData()
    handleFindArchive({page})

    return () => {
      handleClearArchiveData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let count = supportIds.length

    if (orderFilter) {
      count += 1
    }

    if (startDate || endDate) {
      count += 1
    }

    setFilterCount(count)
  }, [orderFilter, supportIds, startDate, endDate, setFilterCount])

  const handleLoadMoreButton = useCallback(() => {
      handleFindArchive({
        page: page + 1,
        orderId: orderFilter,
        supportIds,
        dateFrom: moment(startDate).startOf('day').valueOf(),
        dateTo: moment(endDate).endOf('day').valueOf(),
      })
      setPage(page + 1)
  }, [handleFindArchive, setPage, page, orderFilter, supportIds, startDate, endDate])

  return (
    <>
      <div className={classes.archiveHeaderWrapper}>
        <div className={classes.filtersVisibilityToggler} onClick={toggleFiltersVisibility}>
          <div className={classes.filtersVisibilityTogglerContent}>
            {showFilters ? 'Hide' : 'Show'} filters {filtersCount ? `(${filtersCount})` : ''}
            <div className={clsx(classes.chevronIcon, showFilters && classes.chevronIconOpened)}>
              <ChevronIcon />
            </div>
          </div>
        </div>
        <ChosenFilters
          supportIds={supportIds}
          setSupportIds={setSupportIds}
          orderId={orderFilter}
          setOrderId={setOrderFilter}
          setPage={setPage}
          startDate={startDate}
          endDate={endDate}
          setDateRange={setDateRange}
          setShowFilters={setShowFilters}
        />
        { !showFilters && <hr color='#ebebeb'/>}
        {showFilters && <ArchiveFilters
          orderId={orderFilter}
          setOrderId={setOrderFilter}
          supportIds={supportIds}
          setSupportIds={setSupportIds}
          setPage={setPage}
          startDate={startDate}
          endDate={endDate}
          setDateRange={setDateRange}
        />}
        { showFilters && <hr color='#ebebeb'/>}
      </div>
      {showLoadMoreButton && (
        <button
          onClick={handleLoadMoreButton}
          className={classes.loadMoreButton}
          disabled={fetchArchivePending}
        >
          Load more
        </button>
      )}
    </>
  )
}

export default ArchiveHeader
