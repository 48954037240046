import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  ratingWrapper: {
    display: 'flex'
  },

  starWrapper: {
    position: 'relative',
    width: '16px',
    height: '16px',
    color: 'rgba(0, 0, 0, 0.3)',
  },

  fillingControl: {
    zIndex: 1,
    position: 'absolute',
    top: '0',
    left: '0',
    display: 'block',
    height: '16px',
    overflow: 'hidden',
  },

  coloredStar: {
    color: '#E98D20',
  },

  backgroundStar: {
    position: 'absolute',
    top: 0,
    right: 0,
  }
})

export default makeStyles(styles)
