import { Dispatch, useCallback, useEffect } from "react";
import { AnyAction } from "redux";
import { Socket } from "socket.io-client";

export const useClientWaiting = (socket: Socket, dispatch: Dispatch<any>) => {
  const handleSetNewActiveGroup = useCallback((data) => dispatch({
    ...data, payload: { ...data.payload, groupToUpdate: 'active' }
  }), [dispatch]) // SOCKET_CLIENT_WAITING

  useEffect(() => {
    const handler = (data: AnyAction) => { handleSetNewActiveGroup(data) }

    socket.on('client-waiting', handler)
    return () => {
      socket.off('client-waiting', handler)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
