import { FC } from 'react'
import _isEmpty from 'lodash/isEmpty'

import { IChatData, IOrderChatGroup } from 'src/common/interfaces'

import useStyles from './styles'
import ChatListItem from './chat-list-item'

interface IChatList {
  chatsDataByTab: {[key: string | number]: IOrderChatGroup} | null
  currentChatGroupData: IOrderChatGroup | null
  currentRoomData: IChatData | null
  handleSetCurrentGroupData: (groupIndex: number) => void
  handleSetCurrentRoomData: (groupIndex: number, roomIndex: number) => void
}

const ChatList: FC<IChatList> = ({
  chatsDataByTab,
  currentChatGroupData,
  currentRoomData,
  handleSetCurrentGroupData,
  handleSetCurrentRoomData,
}) => {
  const classes = useStyles()

  if (!chatsDataByTab && _isEmpty(chatsDataByTab)) {
    return null
  }

  return (
    <div className={classes.chatListWrapper}>
      {chatsDataByTab && Object.entries(chatsDataByTab).map(([_, groupData], groupIndex) => (
        <ChatListItem
          key={groupData.order.id}
          groupData={groupData}
          groupIndex={groupIndex}
          currentChatGroupData={currentChatGroupData}
          currentRoomData={currentRoomData}
          handleSetCurrentGroupData={handleSetCurrentGroupData}
          handleSetCurrentRoomData={handleSetCurrentRoomData}
        />
      ))}
    </div>
  )
}

export default ChatList
