import { FC, useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _isEmpty from 'lodash/isEmpty'

import ChatListNavbar from './chat-list-navbar'
import ChatList from './chat-list'
import SupportControl from './support-control'

import { fetchMessagesData } from 'src/store/actions/messagesData'
import { setCurrentGroupData, setCurrentChatRoomData } from 'src/store/actions/appControl'

import { IStore } from 'src/store/types'
import { IChatData, IOrderChatGroup } from 'src/common/interfaces'

import useStyles from './styles'
import { NavbarButtonValues } from 'src/common/constants'
import ArchiveList from './chat-list/ArchiveList'

interface ILeftSidebar {
  activeTab: NavbarButtonValues
  currentChatGroupData: IOrderChatGroup | null
  currentRoomData: IChatData | null
}

const LeftSidebar: FC<ILeftSidebar> = ({
  activeTab,
  currentChatGroupData,
  currentRoomData,
}) => {
  const classes = useStyles()

  const chatsData = useSelector((state: IStore) => state.chatData.data)
  const messages = useSelector((state: IStore) => state.messagesData.data)
  const supportConnected = useSelector((state: IStore) => state.appData.supportConnected)

  const dispatch = useDispatch()
  const handleFetchMessagesByRoom = useCallback((orderId, writerId) => dispatch(fetchMessagesData(orderId, writerId)), [dispatch])
  const handleSetCurrentChatGroupData = useCallback((orderId, data) => dispatch(setCurrentGroupData(orderId, data)), [dispatch])
  const handleSetCurrentChatRoomData = useCallback((data) => dispatch(setCurrentChatRoomData(data)), [dispatch])

  const [chatsDataByTab, setChatsDataByTab] = useState<{[key: string | number]: IOrderChatGroup} | null>(null)

  const handleSetTabData = useCallback((data) => {
    setChatsDataByTab(data)
  }, [setChatsDataByTab])

  const handleSetCurrentGroupData = useCallback((orderId: number) => {
    chatsDataByTab && handleSetCurrentChatGroupData(orderId, chatsDataByTab[`${orderId}`])
    handleSetCurrentChatRoomData(null)
  }, [chatsDataByTab, handleSetCurrentChatRoomData, handleSetCurrentChatGroupData])

  const handleSetCurrentRoomData = useCallback((orderId: number, writerId: number) => {
    chatsDataByTab && handleSetCurrentChatGroupData(orderId, chatsDataByTab[`${orderId}`])
    chatsDataByTab && handleSetCurrentChatRoomData(chatsDataByTab[`${orderId}`].chats[`${writerId}`])

    if (!messages[`${orderId}${writerId}`]?.fetched) {
      handleFetchMessagesByRoom(orderId, writerId)
    }
  }, [
    chatsDataByTab,
    handleSetCurrentChatRoomData,
    messages,
    handleFetchMessagesByRoom,
    handleSetCurrentChatGroupData,
  ])

  useEffect(() => {
    handleSetCurrentChatRoomData(null)
  }, [supportConnected, handleSetCurrentChatRoomData, activeTab])

  return (
    <div className={classes.leftSidebar}>
      <ChatListNavbar
        activeTab={activeTab}
        chatsData={chatsData}
        handleSetTabData={handleSetTabData}
      />
      {((activeTab === NavbarButtonValues.ACTIVE || activeTab === NavbarButtonValues.QUEUE) && _isEmpty(chatsDataByTab)) &&  (
        <p className={classes.noChatsText}>There are no chats here yet...</p>
      )}
      {activeTab !== NavbarButtonValues.ARCHIVE ?
        <ChatList
          chatsDataByTab={chatsDataByTab}
          currentChatGroupData={currentChatGroupData}
          currentRoomData={currentRoomData}
          handleSetCurrentGroupData={handleSetCurrentGroupData}
          handleSetCurrentRoomData={handleSetCurrentRoomData}
        />
        :
        <ArchiveList />
      }

      <SupportControl />
    </div>
  )
}

export default LeftSidebar
