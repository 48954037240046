import { Dispatch, useCallback, useEffect } from "react"
import { AnyAction } from "redux"
import { Socket } from "socket.io-client"

export const useSupportChangedStatus = (socket: Socket, dispatch: Dispatch<any>) => {
  const handleChangeStatus = useCallback((data) => dispatch({ type: data.type, payload: data.data }), [dispatch]) // SOCKET_SUPPORT_CHANGED_ACTIVITY_STATUS

  useEffect(() => {
    const handler = (data: AnyAction) => {
      handleChangeStatus(data)
    }

    socket.on('support-changed-status', handler)
    return () => {
      socket.off('support-changed-status', handler)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
