import { Dispatch, useCallback, useEffect } from "react";
import { AnyAction } from "redux";
import { Socket } from "socket.io-client";

export const useChatInitiated = (socket: Socket, dispatch: Dispatch<any>) => {
  const handleSetNewRoom = useCallback((data) => dispatch({ ...data }), [dispatch]) // CHAT_INITIATED

  useEffect(() => {
    const handler = async (data: AnyAction) => {
      await handleSetNewRoom(data)
    }

    socket.on('chat-initiated', handler)
    return () => {
      socket.off('chat-initiated', handler)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
