import { APIv1 } from 'src/api'
import { call, put } from 'redux-saga/effects'

import notifyer from 'src/common/notifyer'
import { ISupportItem } from 'src/store/reducers/admin-data'
import { USER_LIST_CHANGED } from 'src/store/types/adminData'

const postNewUserData = (userData: ISupportItem): Promise<any> => (
  APIv1.post('/users/create', { userData })
)

export default function* createUser(action: any) {
  try {
    // @ts-ignore
    yield call(postNewUserData, action.payload)
    notifyer.success(`User ${action.payload.name} created`)
    yield put({ type: USER_LIST_CHANGED })
  } catch (e) {
    notifyer.error('Request failed - server error')
  }
}
