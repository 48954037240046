import { APIv1 } from 'src/api'

import { call, put } from 'redux-saga/effects'

import {
  FETCH_ARCHIVE_SUCCEEDED,
  FETCH_ARCHIVE_FAILED,
  FETCH_ARCHIVE_PENDING,
  CLEAR_ARCHIVE_DATA
} from 'src/store/types/archiveData'
import { IOrderChatGroup } from 'src/common/interfaces'
import notifyer from 'src/common/notifyer'

const INITIAL_PAGE = 1
const PER_PAGE = 10
const INITIAL_DATE_FROM = 1

export interface IArchiveRequestProps {
  page?: number,
  orderId?: IOrderChatGroup['order']['id'],
  dateTo?: number,
  dateFrom?: number,
  supportIds?: string[],
}

const fetchArchiveRequest = ({
  page,
  orderId,
  dateTo,
  dateFrom,
  supportIds,
}: IArchiveRequestProps): Promise<any> => {
  if (supportIds?.length) {
    let targetUrl = `/chat/support/archive?page=${page || INITIAL_PAGE}&perPage=${PER_PAGE}&to=${dateTo || Date.now()}&from=${dateFrom || INITIAL_DATE_FROM}&orderId=${orderId}`

    supportIds.forEach(supportId => {
      targetUrl = `${targetUrl}&supportIds[]=${supportId}`
    })

    return APIv1.get(targetUrl)
  }

  return APIv1.get(`/chat/support/archive?page=${page || INITIAL_PAGE}&perPage=${PER_PAGE}&to=${dateTo || Date.now()}&from=${dateFrom || INITIAL_DATE_FROM}&orderId=${orderId}`)
}

export default function* fetchArchive(action: any) {
  try {
    yield put({ type: FETCH_ARCHIVE_PENDING })

    // @ts-ignore
    const result = yield call(fetchArchiveRequest, action.payload)

    yield put({
      type: FETCH_ARCHIVE_SUCCEEDED,
      payload: result.data
    })
  } catch (e) {
    console.error(e)
    yield put({ type: FETCH_ARCHIVE_FAILED, payload: e })
    notifyer.error('Archive request failed. Server error')
    yield put ({ type: CLEAR_ARCHIVE_DATA })
  }
}
