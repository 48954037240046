import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  unreadCount: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 8px',
    height: '24px',
    background: '#28447B',
    borderRadius: '12px',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '16px',
    color: '#fff',
  },
})

export default makeStyles(styles)
