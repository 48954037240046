import { AnyAction as Action } from 'redux'

import { IMessageData } from 'src/common/interfaces'
import { IAdaptedFiles } from 'src/components/chat/chat-area/message-form/files-list/types'
import { CLEAR_APP_DATA } from 'src/store/types/appControl'
import { AUTH_LOG_OUT } from 'src/store/types/auth'

import {
  FETCH_MESSAGES_DATA_PENDING,
  FETCH_MESSAGES_DATA_FAILED,
  FETCH_MESSAGES_DATA_SUCCEEDED,
  SEND_MESSAGE,
  NEW_MESSAGE_FROM_SOCKET,
  STORE_ENTERED_MESSAGE_DATA_BY_ROOM,
  SOCKET_MESSAGE_VIEWED,
  UPDATE_TYPED_DATA,
} from 'src/store/types/messagesData'

export interface IMessagesStore {
  data: {
    [key: string | number]: {
      messages: IMessageData[]
      fetched?: boolean
    }
  }
  enteredDataByRoom: {
    [key: string | number]: {
      text: string
      files: File[]
      adaptedFiles: IAdaptedFiles[]
    }
  }
  typedData: {
    [key: string | number]: string
  }

  error: any
  pending: boolean
}

const initialState = {
  data: {} as IMessagesStore['data'],
  enteredDataByRoom: {},
  typedData: {},
  error: null,
  pending: false,
}

const messageData = (state = initialState, action: Action) => {
  switch (action.type) {
    case FETCH_MESSAGES_DATA_PENDING: {
      return {
        ...state,
        pending: true,
        error: null,
      }
    }

    case FETCH_MESSAGES_DATA_SUCCEEDED: {
      return {
        ...state,
        data: {
          ...state.data,
          [`${action.payload.orderId}${action.payload.writerId}`]: {
            messages: [
              ...action.payload.data
            ],
            fetched: true,
          },
        },
        pending: false,
        error: null,
      }
    }

    case FETCH_MESSAGES_DATA_FAILED: {
      return {
        ...state,
        error: action.payload,
        pending: false,
      }
    }

    case SEND_MESSAGE: {
      const arrayToUpdate = state.data[`${action.payload.orderId}${action.payload.writerId}`]?.messages ? [...state.data[`${action.payload.orderId}${action.payload.writerId}`].messages] : []
      arrayToUpdate.push(action.payload.message)

      return {
        ...state,
        data: {
          ...state.data,
          [`${action.payload.orderId}${action.payload.writerId}`]: {
            ...state.data[`${action.payload.orderId}${action.payload.writerId}`],
            messages: [...arrayToUpdate],
          },
        }
      }
    }

    case NEW_MESSAGE_FROM_SOCKET: {
      const orderId = action.payload.identifiyers.group
      const writerId = action.payload.identifiyers.identifyer
      const messages = state.data[`${orderId}${writerId}`]?.messages ? [ ...state.data[`${orderId}${writerId}`].messages] : []

      if (messages.length && action.payload.hasOwnProperty('clientHashId')) {
        const messageIndexToReplace = messages.findIndex(message => message.clientHashId === action.payload.clientHashId )
        messageIndexToReplace !== -1 && messages.splice(messageIndexToReplace, 1)
      }

      return {
        ...state,
        data: {
          ...state.data,
          [`${orderId}${writerId}`]: {
            ...state.data[`${orderId}${writerId}`],
            messages: [
              ...messages,
              action.payload,
            ],
          },
        }
      }
    }

    case STORE_ENTERED_MESSAGE_DATA_BY_ROOM: {
      return {
        ...state,
        enteredDataByRoom: {
          ...state.enteredDataByRoom,
          [`${action.payload.orderId}${action.payload.writerId}`]: {
            text: action.payload.text,
            adaptedFiles: action.payload.adaptedFiles,
          }
        },
      }
    }

    case UPDATE_TYPED_DATA: {
      return {
        ...state,
        typedData: {
          ...state.typedData,
          [`${action.payload.group}${action.payload.identifyer}`]: action.payload.message
        }
      }
    }

    case SOCKET_MESSAGE_VIEWED: {
      if (!state.data[`${action.payload.group}${action.payload.identifyer}`]) {
        return {
          ...state,
        }
      }

      const updated = state.data[`${action.payload.group}${action.payload.identifyer}`].messages.map(message => {
        message.id === action.payload.messageId && (message.viewed = true)

        return message
      })

      return {
        ...state,
        data: {
          ...state.data,
          [`${action.payload.group}${action.payload.identifyer}`]: {
            ...state.data[`${action.payload.group}${action.payload.identifyer}`],
            messages: [...updated],
          },
        },
      }
    }

    case CLEAR_APP_DATA:
    case AUTH_LOG_OUT: {
      return {
        ...initialState,
      }
    }

    default:
      return state
  }
}

export default messageData
