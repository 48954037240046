import { Dispatch, useCallback, useEffect } from "react";
import { Socket } from "socket.io-client";
import notifyer from "src/common/notifyer";
import { clearAppData } from "src/store/actions/appControl";
import { logOut, refreshToken } from "src/store/actions/auth"

export const useConnectError = (socket: Socket, dispatch: Dispatch<any>) => {
  const handleRefreshToken = useCallback(() => dispatch(refreshToken()), [dispatch])
  const handleClearAppData = useCallback(() => dispatch(clearAppData()), [dispatch])
  const handleLogOut = useCallback(() => dispatch(logOut()), [dispatch])

  useEffect(() => {
    const handler = (error: any) => {
      if (error.message) {
        switch(error.message) {
          case 'Jwt version is expired.': {
            handleRefreshToken()
            break
          }

          case 'jwt expired': {
            notifyer.warning('Your session was expired - please relogin')
            handleLogOut()
            break
          }

          default: {
            notifyer.error('Socket connection error')
            handleClearAppData()
          }
        }
      } else {
        notifyer.error('Socket connection error')
        handleClearAppData()
      }
    }

    socket.on('connect_error', handler)
    return () => {
      socket.off('connect_error', handler)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
