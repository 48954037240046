import { FC, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"
import clsx from 'clsx'

import { fetchSupportList } from 'src/store/actions/adminData';

import useStyles from './styles'
import SupportsListItems from './supports-list-items';
import { IStore } from 'src/store/types';

const SupportsList: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const handleFetchSupportList = useCallback(() => dispatch(fetchSupportList()), [dispatch])

  const userListChanged = useSelector((state: IStore) => state.adminData.userListChanged)

  useEffect(() => {
    handleFetchSupportList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (userListChanged) {
      handleFetchSupportList()
    }
  }, [userListChanged, handleFetchSupportList])

  return (
    <div className={classes.supportsListWrapper}>
      <div className={clsx(classes.supportListHeader, classes.grid)}>
        <p className={classes.supportListHeaderText}>Avatar</p>
        <p className={classes.supportListHeaderText}>Name</p>
        <p className={classes.supportListHeaderText}>Email</p>
        <p className={classes.supportListHeaderText}>Password</p>
      </div>
      <SupportsListItems />
    </div>
  )
}

export default SupportsList
