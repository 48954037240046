/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Socket } from 'socket.io-client';

import { IChatIdentifiers, IFile } from 'src/common/interfaces'
import { fetchChatsData } from 'src/store/actions/chatData';
import { useSocketContext } from './socket-context';
import { useChatClosed } from './socket-listeners/chat-closed';
import { useChatGroupClosed } from './socket-listeners/chat-group-closed';
import { useChatInitiated } from './socket-listeners/chat-initiated';
import { useClientQueued } from './socket-listeners/client-queued';
import { useClientWaiting } from './socket-listeners/client-waiting';
import { useDisconnect } from './socket-listeners/disconnect';
import { useEmitMessage } from './socket-listeners/emit-msg';
import { useMessageViewed } from './socket-listeners/message-viewed';
import { useSupportConnected } from './socket-listeners/support-connected';
import { useSupportDisconnected } from './socket-listeners/support-disconnected';
import { useConnectError } from './socket-listeners/connect-error';
import { useWorkerTookChat } from './socket-listeners/worker-took-chat';
import { useTyping } from './socket-listeners/typing';
import { useChatUpdated } from './socket-listeners/chat-updated';
import { useSupportChangedStatus } from './socket-admin-listeners/support-changed-status';
import { useJWTUpdated } from './socket-listeners/jwt-updated';

interface IMessageToSend {
  content: string
  files?: IFile[]
  identifiyers: IChatIdentifiers
  clientHashId?: string
}

export const useSocketConnect = () => {
  const socket = useSocketContext()
  const dispatch = useDispatch()
  const handleFetchChatsData = useCallback(() => dispatch(fetchChatsData()), [dispatch])

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Socket-io connected');
      handleFetchChatsData()
    })
    .on('error', (err: any) => {
      console.log(err)
    })

    return () => {
      socket.disconnect()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const useSocketEmit = () => {
  const socket = useSocketContext()

  const sendMessageBySocket = (message: IMessageToSend) => {
    socket.emit('emit-msg', message)
  }

  const connectSupport = () => {
    socket.emit('support-connecting')
  }

  const disconnectSupport = () => {
    socket.emit('support-disconnecting')
  }

  const markMessageViewed = ({messageId}: {messageId: string}) => {
    socket.emit('message-viewed', { messageId })
  }

  const closeChat = ({ orderId } : {orderId: number}) => {
    socket.emit('chat-group-closed', { group: orderId })
  }

  const listenMessageTyping = ({ orderId, writerId } : {orderId: number, writerId: number}) => {
    socket.emit('typing', {
      group: orderId,
      identifyer: writerId,
    })
  }

  return {
    sendMessageBySocket,
    connectSupport,
    disconnectSupport,
    markMessageViewed,
    closeChat,
    listenMessageTyping,
  }
}

export const useListenSocketIncoming = () => {
  const socket = useSocketContext()
  const dispatch = useDispatch()

  useClientQueued(socket, dispatch)
  useClientWaiting(socket, dispatch)
  useChatInitiated(socket, dispatch)
  useEmitMessage(socket, dispatch)
  useMessageViewed(socket, dispatch)
  useSupportConnected(socket, dispatch)
  useSupportDisconnected(socket, dispatch)
  useWorkerTookChat(socket, dispatch)
  useChatGroupClosed(socket, dispatch)
  useChatClosed(socket, dispatch)
  useDisconnect(socket, dispatch)
  useConnectError(socket, dispatch)
  useTyping(socket, dispatch)
  useChatUpdated(socket, dispatch)
  useJWTUpdated(socket, dispatch)
}

export const useAdminSocketConnect = (adminSocket: Socket) => {
  useEffect(() => {
    adminSocket.on('connect', () => {
      console.log('Socket-io ADMIN connected');
    })
    .on('error', (err: any) => {
      console.log(err)
    })

    return () => {
      adminSocket.disconnect()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const useListenAdminSocketIncoming = (adminSocket: Socket) => {
  const dispatch = useDispatch()

  useSupportChangedStatus(adminSocket, dispatch)
}
