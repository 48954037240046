import { APIv1 } from 'src/api'

import { call, put } from 'redux-saga/effects'

import {
  FETCH_NEW_GROUP_SUCCEEDED,
  FETCH_NEW_GROUP_FAILED,
  FETCH_NEW_GROUP_PENDING,
} from 'src/store/types/chatData'
import { IOrderChatGroup } from 'src/common/interfaces'
import { playAudio } from "src/common/play-audio";
import newMessageSound from 'src/media/sounds/incoming_message.wav'

const fetchNewGroupDataRequest = (
  orderId: IOrderChatGroup['order']['id']
): Promise<any> => APIv1.get(`/chat/support/${orderId}`)

export default function* fetchNewGroup(action: any) {
  try {
    yield put({ type: FETCH_NEW_GROUP_PENDING })

    // @ts-ignore
    const result = yield call(fetchNewGroupDataRequest, action.payload.group)

    yield put({
      type: FETCH_NEW_GROUP_SUCCEEDED,
      payload: {
        groupToUpdate: action.payload.groupToUpdate,
        orderId: action.payload.group,
        data: result.data
      }
    })

    if (action.payload.notify) { // this flag only for transferred chats
      const sound = new Audio(newMessageSound)
      playAudio({ sound, volume: 0.2 })
    }
  } catch (e) {
    yield put({ type: FETCH_NEW_GROUP_FAILED, payload: e })
  }
}
