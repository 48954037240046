import { Dispatch, FC, SetStateAction, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ClipLoader } from 'react-spinners'
import { setCurrentChatRoomData } from 'src/store/actions/appControl'
import { clearArchiveData, findArchive } from 'src/store/actions/archiveData'
import { IArchiveRequestProps } from 'src/store/sagas/find-in-archive/fetchArchive'
import { IStore } from 'src/store/types'
import DateFilter from './date-filter'
import useStyles from './styles'
import SupportFilter from './support-filter'

interface IArchiveFilters {
  supportIds: string[]
  setSupportIds: Dispatch<SetStateAction<string[]>>
  orderId: number | undefined
  setOrderId: Dispatch<SetStateAction<number | undefined>>
  setPage: Dispatch<SetStateAction<number>>
  startDate: Date | null,
  endDate: Date | null,
  setDateRange: Dispatch<SetStateAction<null[] | Date[]>>
}

const ArchiveFilters: FC<IArchiveFilters> = ({
  supportIds,
  setSupportIds,
  orderId,
  setOrderId,
  setPage,
  startDate,
  endDate,
  setDateRange,
}) => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const handleFindArchive = useCallback((options : IArchiveRequestProps) => dispatch(findArchive({...options})), [dispatch])
  const handleClearArchiveData = useCallback(() => dispatch(clearArchiveData()), [dispatch])
  const handleSetCurrentChatRoomData = useCallback((data) => dispatch(setCurrentChatRoomData(data)), [dispatch])

  const fetchArchivePending = useSelector((state: IStore) => state.archiveData.fetchArchivePending)

  const handleChangeOrderId = (e: any) => {
    setOrderId(parseInt(e.target.value))
  }

  const handleFindByOrder = (e: any) => {
    if (fetchArchivePending) {
      return
    }

    e.preventDefault()

    if (orderId) {
      setPage(1)
      handleSetCurrentChatRoomData(null)
      handleClearArchiveData()
      handleFindArchive({orderId: orderId})
    }
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleFindByOrder(e)
    }
  }

  return (
    <form
      className={classes.archiveHeaderFormWrapper}
      onSubmit={handleFindByOrder}
    >
      <label className={classes.label}>Filter by Order ID</label>
      <div className={classes.archiveHeaderInputWrapper}>
        <input
          type="number"
          disabled={fetchArchivePending}
          className={classes.archiveHeaderInput}
          value={orderId || ''}
          onChange={handleChangeOrderId}
          onKeyDown={handleKeyDown}
          placeholder='Type order Id...'
        />
        {fetchArchivePending && (
          <div className={classes.spinnerWrapper}>
            <ClipLoader size={'15px'} color={'#ccc'} />
          </div>
        )}
      </div>
      <SupportFilter
        supportIds={supportIds}
        setSupportIds={setSupportIds}
      />
      <DateFilter
        startDate={startDate}
        endDate={endDate}
        setDateRange={setDateRange}
      />
    </form>
  )
}

export default ArchiveFilters
