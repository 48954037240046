import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  adminMenuWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    height: '100vh',
    maxHeight: '100vh',
    overflow: 'hidden',
  },

  spinnerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
})

export default makeStyles(styles)
