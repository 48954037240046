import React, { FC, useEffect, useState } from 'react'
import { useSelector } from "react-redux"
import { ClipLoader } from 'react-spinners'
import _sortBy from 'lodash/sortBy'

import useStyles from './styles'
import { IStore } from 'src/store/types'
import SupportListItem from './support-item'

const SupportsListItems: FC = () => {
  const classes = useStyles()

  const supportList = useSelector((state: IStore) => state.adminData.supportList)
  const pending = useSelector((state: IStore) => state.adminData.pending)

  const [sortedList, setSortedList] = useState<IStore['adminData']['supportList']>(supportList)

  useEffect(() => {
    const sortedByName = _sortBy(supportList, (support) => support.name)
    const sorted = _sortBy(sortedByName, (support) => !support.status)
    setSortedList(sorted)
  }, [supportList])

  if (pending) {
    return (
      <div className={classes.supportsListItemsWrapper}>
        <div className={classes.spinnerWrapper}>
          <ClipLoader />
        </div>
      </div>
    )
  }

  return (
    <div className={classes.supportsListItemsWrapper}>
      {sortedList.map(listItem => (
        <React.Fragment key={listItem.id}>
          <SupportListItem
            support={listItem}
          />
        </React.Fragment>
      ))}
    </div>
  )
}

export default SupportsListItems
