export enum UserRole {
  SUPPORT ='support',
  CLIENT = 'client',
  ADMIN = 'admin',
}

export enum ProfileLevel {
  REGULAR = 'Regular',
  PRO = 'Pro',
  TOP = 'Top',
}

export enum NavbarButtonValues {
  ACTIVE = 'active',
  QUEUE = 'queue',
  ARCHIVE = 'archive',
}
