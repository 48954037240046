import { FC, Dispatch, SetStateAction, useCallback } from 'react'

import useStyles from './styles'

interface ISupportsHeaderProps {
  setModalOpen: Dispatch<SetStateAction<boolean>>
}

const SupportsHeader: FC<ISupportsHeaderProps> = ({ setModalOpen }) => {
  const classes = useStyles()

  const handleButton = useCallback(() => {
    setModalOpen(true)
  }, [setModalOpen])

  return (
    <div className={classes.supportsHeaderWrapper}>
      <span className={classes.supportsHeaderTitle}>
        Supports
      </span>
      <div className={classes.supportsHeaderButton} onClick={handleButton}>
        Create new profile
      </div>
    </div>
  )
}

export default SupportsHeader
