import localforage from 'localforage'
import { persistReducer } from 'redux-persist'
import type { Action, Reducer } from 'redux'
import { PersistConfig } from "redux-persist/es/types"

import { name, version } from '../../package.json'

export const persist = <S, A extends Action = Action>(key: string, reducer: Reducer<S, A>, config: Omit<PersistConfig<S>, 'key' | 'storage'> = {}) => persistReducer({
  key,
  storage: localforage.createInstance({
    name: `${name}-store-v${version}`,
    driver: [
      localforage.INDEXEDDB,
      localforage.WEBSQL,
      localforage.LOCALSTORAGE,
    ],
  }),
  ...config
}, reducer)
