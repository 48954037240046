import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  supportsHeaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '15px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },

  supportsHeaderTitle: {
    fontSize: '18px',
    lineHeight: '18px',
    color: '#6D7885',
  },

  supportsHeaderButton: {
    padding: '10px 21px',
    borderRadius: '8px',
    background: '#28447B',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#fff',
    cursor: 'pointer',
    transition: 'background 0.3s',

    '&:hover': {
      background: 'hsl(220, 51%, 42%)',
    }
  },
})

export default makeStyles(styles)
