import { APIv1 } from 'src/api'

import { call, put } from 'redux-saga/effects'

import { FETCH_CHAT_LIMIT_FAILED, FETCH_CHAT_LIMIT_PENDING, FETCH_CHAT_LIMIT_SUCCEEDED } from 'src/store/types/adminData'
import notifyer from 'src/common/notifyer'

const fetchChatLimitRequest = (): Promise<any> => APIv1.get('/admin/system/chat-limit')

export default function* fetchChatLimit() {
  try {
    yield put({ type: FETCH_CHAT_LIMIT_PENDING })

    // @ts-ignore
    const result = yield call(fetchChatLimitRequest)

    yield put({ type: FETCH_CHAT_LIMIT_SUCCEEDED, payload: result.data })
    if (!result.data.limit) {
      notifyer.error('Received not valid limit from server')
    }
  } catch (e) {
    notifyer.error('Request current chat limit failed, server error')
    yield put({ type: FETCH_CHAT_LIMIT_FAILED, payload: e })
  }
}
