import { APIv1 } from 'src/api'
import { call, put } from 'redux-saga/effects'

import notifyer from 'src/common/notifyer'
import { TEMPLATE_LIST_CHANGED } from 'src/store/types/templatePhrases'

export interface ITemplateToEdit {
  oldTemlate: string
  template: string
  phrase: string
}

const postEditedTemplate = (template: ITemplateToEdit): Promise<any> => (
  APIv1.put(`/admin/templates/update`, { ...template })
)

export default function* editTemplate(action: any) {
  try {
    // @ts-ignore
    yield call(postEditedTemplate, action.payload)
    notifyer.success(`Template "${action.payload.oldTemplate}" was updated`)
    yield put({ type: TEMPLATE_LIST_CHANGED })
  } catch (e) {
    notifyer.error('Request failed - server error')
  }
}
