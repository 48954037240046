import { Dispatch } from 'redux'

import {
  AUTH_SAGA,
  AUTH_LOG_OUT,
  FETCH_AUTH_DATA_SUCCEEDED,
  SOCKET_USER_CONNECTED,
  SOCKET_USER_DISCONNECTED,
  REFRESH_TOKEN_SAGA,
} from 'src/store/types/auth'

import { IAuthDataFromForm } from '../sagas/auth/fetchAuth'

export const login = (authDataFromForm: IAuthDataFromForm) => (dispatch: Dispatch) => {
  dispatch({
    type: AUTH_SAGA,
    authDataFromForm,
  })
}

export const refreshToken = () => (dispatch: Dispatch) => {
  dispatch({
    type: REFRESH_TOKEN_SAGA,
  })
}

export const updateAuthDataFromToken = (token: string) => (dispatch: Dispatch) => {
  dispatch({
    type: FETCH_AUTH_DATA_SUCCEEDED,
    payload: token,
  })
}

export const logOut = () => (dispatch: Dispatch) => {
  dispatch({
    type: AUTH_LOG_OUT,
  })
}

export const setConnected = () => (dispatch: Dispatch) => {
  dispatch({
    type: SOCKET_USER_CONNECTED,
  })
}

export const setDisconnected = () => (dispatch: Dispatch) => {
  dispatch({
    type: SOCKET_USER_DISCONNECTED,
  })
}
