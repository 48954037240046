import { APIv1 } from 'src/api'

import { call, put } from 'redux-saga/effects'

import { FETCH_SUPPORT_LIST_FAILED, FETCH_SUPPORT_LIST_PENDING, FETCH_SUPPORT_LIST_SUCCEEDED } from 'src/store/types/adminData'
import notifyer from 'src/common/notifyer'

const fetchSupportListRequest = (): Promise<any> => APIv1.get('/users/list')

export default function* fetchSupportList() {
  try {
    yield put({ type: FETCH_SUPPORT_LIST_PENDING })

    // @ts-ignore
    const result = yield call(fetchSupportListRequest)

    yield put({ type: FETCH_SUPPORT_LIST_SUCCEEDED, payload: result.data })
  } catch (e) {
    notifyer.error('Request support list failed, server error')
    yield put({ type: FETCH_SUPPORT_LIST_FAILED, payload: e })
  }
}
