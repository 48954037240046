import { Dispatch, FC, SetStateAction, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'

import { setFilteredTemplatesKeys } from 'src/store/actions/templatePhrases'
import { IStore } from 'src/store/types'

import useStyles from './styles'

interface ITemplatePhrasesSearchBox {
  message: string
  templatePhrasesList: IStore['templatePhrases']['data']
  activeTemplateIndex: number
  handleChangeTemplateValue: (id: string) => void
  handleAddTemplateToMessageText: () => void
  templateValue: string
  setTemplateValue: Dispatch<SetStateAction<string>>
  setActiveTemplateIndex: Dispatch<SetStateAction<number>>
  templatesListRef: any
  textAreaRef: any
}

const TemplatePhrasesSearchBox: FC<ITemplatePhrasesSearchBox> = ({
  message,
  templatePhrasesList,
  activeTemplateIndex,
  handleChangeTemplateValue,
  handleAddTemplateToMessageText,
  templateValue,
  setTemplateValue,
  setActiveTemplateIndex,
  templatesListRef,
  textAreaRef,
}) => {
  const classes = useStyles()

  const toggleHover = useCallback((index) => {
    setActiveTemplateIndex(index)
  }, [setActiveTemplateIndex])

  const dispatch = useDispatch()

  const handleSetFilteredTemplatesKeys = useCallback((filtered) => dispatch(setFilteredTemplatesKeys(filtered)), [dispatch])

  const handleBackButton = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()

    setTemplateValue('')
    // @ts-ignore
    textAreaRef.current && textAreaRef.current.focus()
  }, [setTemplateValue, textAreaRef])

  const filteredTemplatesKeysArray = useSelector((state: IStore) => state.templatePhrases.filteredKeys)

  useEffect(() => {
    const filtered: string[] = []

    if (message[message.length - 1] === '#') {
      !templateValue.length && handleSetFilteredTemplatesKeys(Object.keys(templatePhrasesList))
    } else {
      const splittedMessage = message.split('#').pop()

      splittedMessage && Object.keys(templatePhrasesList).forEach(key => {
        if (key.startsWith(splittedMessage.toLowerCase())) {
          filtered.push(key)
        }
      })

      !templateValue.length && handleSetFilteredTemplatesKeys(filtered)
    }
  }, [message, templatePhrasesList, templateValue, handleSetFilteredTemplatesKeys])

  if (!filteredTemplatesKeysArray.length) {
    return (
      <div tabIndex={-1} className={classes.templatePhrasesListWrapper} ref={templatesListRef}>
        <span className={classes.noTemplatesText}>...there is no templates by this value</span>
      </div>
    )
  }

  return (
    <div
      tabIndex={-1}
      className={clsx(
        classes.templatePhrasesListWrapper,
        Boolean(templateValue.length) && classes.wrapperWithTemplateValue
      )}
      ref={templatesListRef}
    >
      <select tabIndex={-1} size={10}className={clsx(
        classes.templatePhrasesList,
        templateValue.length && classes.listHidden,
      )}>
        {filteredTemplatesKeysArray.sort().map((key, index) => (
          <option
            key={key}
            className={clsx(
              classes.templatePhrasesListItem,
              activeTemplateIndex === index && classes.activeListItem
            )}
            selected={activeTemplateIndex === index}
            onClick={() => handleChangeTemplateValue(key)}
            onMouseEnter={() => toggleHover(index)}
            onMouseLeave={() => toggleHover(index)}
          >
            {`${key} ${activeTemplateIndex === index ? '--->' : ''}`}
          </option>
        ))}
      </select>
      {Boolean(templateValue.length) &&
        <div
          tabIndex={-1}
          className={classes.templatePhraseValueWrapper}
        >
          <span role='button' className={classes.backButton} onClick={handleBackButton}>
            {'<'}
          </span>
          <span className={classes.templatePhraseValue} onClick={handleAddTemplateToMessageText}>{templateValue}</span>
        </div>
      }
    </div>
  )
}

export default TemplatePhrasesSearchBox
