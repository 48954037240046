import { Dispatch, useCallback, useEffect } from "react"
import { Socket } from "socket.io-client"
import { updateAuthDataFromToken } from "src/store/actions/auth"

export const useJWTUpdated = (socket: Socket, dispatch: Dispatch<any>) => {
  const handleUpdateAuthDataFromToken = useCallback((token) => dispatch(updateAuthDataFromToken(token)), [dispatch])

  useEffect(() => {
    const handler = (data: any) => {
      const url = new URL(process.env.REACT_APP__SOCKET__URL as string, window.location.origin)
      url.pathname = process.env.REACT_APP__SOCKET__SUPPORT_NAMESPACE as string

      localStorage.setItem('authToken', data.token)
      // @ts-ignore
      socket.io.uri = `${url.toString()}/?token=${data.token}`
      socket.io.opts.query = {
        ...socket.io.opts.query,
        token: data.token,
      }

      socket.connect()
      handleUpdateAuthDataFromToken(data.token)
    }

    socket.on('jwt-updated', handler)
    return () => {
      socket.off('jwt-updated', handler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
