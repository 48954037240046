import { Dispatch, SetStateAction } from "react"

interface IHandler {
  e: any
  message: string
  showTemplatePhrases: boolean
  templateValue: string
  setTemplateValue: Dispatch<SetStateAction<string>>
  filteredTemplatesKeysArray: string[]
  handleChangeTemplateValue: (arg0: string) => void
  handleAddTemplateToMessageText: () => void
  handleSendMessage: (e: any) => void
  setShowTemplatePhrases: Dispatch<SetStateAction<boolean>>
  activeTemplateIndex: number
  setActiveTemplateIndex: Dispatch<SetStateAction<number>>
}

export const keyboardEventsHandler = ({
  e,
  message,
  showTemplatePhrases,
  templateValue,
  setTemplateValue,
  filteredTemplatesKeysArray,
  handleChangeTemplateValue,
  handleAddTemplateToMessageText,
  handleSendMessage,
  setShowTemplatePhrases,
  activeTemplateIndex,
  setActiveTemplateIndex,
}: IHandler) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      if (showTemplatePhrases) {
        e.preventDefault()
        e.stopPropagation()

        filteredTemplatesKeysArray.length && !templateValue.length && handleChangeTemplateValue(filteredTemplatesKeysArray[activeTemplateIndex])
        templateValue.length && handleAddTemplateToMessageText()

        return
      }

      handleSendMessage(e)
    }

    if (e.key === ' ') {
      setShowTemplatePhrases(false)
      templateValue.length && setTemplateValue('')
    }

    if (showTemplatePhrases && e.key === 'Escape') {
      setShowTemplatePhrases(false)
      templateValue.length && setTemplateValue('')
    }

    if (message[message.length - 1] === '#' && e.key === 'Backspace') {
      setShowTemplatePhrases(false)
      templateValue.length && setTemplateValue('')
    }

    if (e.key === '#') {
      setShowTemplatePhrases(true)
    }

    if (showTemplatePhrases && e.key === 'ArrowUp') {
      e.preventDefault()
      e.stopPropagation()
      !templateValue.length && activeTemplateIndex !== 0 && setActiveTemplateIndex(activeTemplateIndex - 1)
    }

    if (showTemplatePhrases && filteredTemplatesKeysArray.length && e.key === 'ArrowDown') {
      e.preventDefault()
      e.stopPropagation()
      !templateValue.length && filteredTemplatesKeysArray.length > activeTemplateIndex + 1 && setActiveTemplateIndex(activeTemplateIndex + 1)
    }

    if (showTemplatePhrases && e.key === 'ArrowLeft') {
      e.preventDefault()
      e.stopPropagation()
      setTemplateValue('')
    }

    if (showTemplatePhrases && e.key === 'ArrowRight') {
      e.preventDefault()
      e.stopPropagation()
      filteredTemplatesKeysArray.length && !templateValue.length && handleChangeTemplateValue(filteredTemplatesKeysArray[activeTemplateIndex])
      templateValue.length && handleAddTemplateToMessageText()
    }
  }
