import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import _isEmpty from 'lodash/isEmpty'
import _sortBy from 'lodash/sortBy'

import { fetchSupportList } from 'src/store/actions/adminData'
import { IStore } from 'src/store/types'

import useStyles from './styles'

interface ISupportFilter {
  supportIds: string[]
  setSupportIds: Dispatch<SetStateAction<string[]>>
}

const SupportFilter: FC<ISupportFilter> = ({supportIds, setSupportIds}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleFetchSupportList = useCallback(() => dispatch(fetchSupportList()), [dispatch])
  const supportList = useSelector((state: IStore) => state.adminData.supportList)
  const fetchArchivePending = useSelector((state: IStore) => state.archiveData.fetchArchivePending)

  useEffect(() => {
    _isEmpty(supportList) && handleFetchSupportList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [sortedList, setSortedList] = useState<IStore['adminData']['supportList']>(supportList)

  useEffect(() => {
    const sortedByName = _sortBy(supportList, (support) => support.name)
    const sorted = _sortBy(sortedByName, (support) => !support.status)
    setSortedList(sorted)
  }, [supportList])

  const handleSelect = useCallback((e) => {
    e.preventDefault()
    // @ts-ignore
    const checkedValues = Array.from(e.target.selectedOptions, option => option.value as string)
    setSupportIds(checkedValues)
  }, [setSupportIds])

  return (
    <div className={classes.supportFilterWrapper}>
      <div className={classes.supportSelectWrapper}>
        <label className={classes.label}>Filter by Support</label>
        <select className={classes.supportSelect} name="supports[]" multiple onChange={handleSelect} value={supportIds} disabled={fetchArchivePending}>
          {sortedList.map(({id, name}) => (
            <option key={id} value={id}>{name}</option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default SupportFilter
