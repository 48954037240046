export const OPEN_ADMIN_PROFILE: 'OPEN_ADMIN_PROFILE' = 'OPEN_ADMIN_PROFILE'
export const CLOSE_ADMIN_PROFILE: 'CLOSE_ADMIN_PROFILE' = 'CLOSE_ADMIN_PROFILE'

export const FETCH_SUPPORT_LIST_SAGA: 'FETCH_SUPPORT_LIST_SAGA' = 'FETCH_SUPPORT_LIST_SAGA'
export const FETCH_SUPPORT_LIST_PENDING: 'FETCH_SUPPORT_LIST_PENDING' = 'FETCH_SUPPORT_LIST_PENDING'
export const FETCH_SUPPORT_LIST_SUCCEEDED: 'FETCH_SUPPORT_LIST_SUCCEEDED' = 'FETCH_SUPPORT_LIST_SUCCEEDED'
export const FETCH_SUPPORT_LIST_FAILED: 'FETCH_SUPPORT_LIST_FAILED' = 'FETCH_SUPPORT_LIST_FAILED'

export const SET_CHAT_LIMIT_SAGA: 'SET_CHAT_LIMIT_SAGA' = 'SET_CHAT_LIMIT_SAGA'
export const SET_CHAT_LIMIT_SUCCEEDED: 'SET_CHAT_LIMIT_SUCCEEDED' = 'SET_CHAT_LIMIT_SUCCEEDED'
export const FETCH_CHAT_LIMIT_SAGA: 'FETCH_CHAT_LIMIT_SAGA' = 'FETCH_CHAT_LIMIT_SAGA'
export const FETCH_CHAT_LIMIT_PENDING: 'FETCH_CHAT_LIMIT_PENDING' = 'FETCH_CHAT_LIMIT_PENDING'
export const FETCH_CHAT_LIMIT_SUCCEEDED: 'FETCH_CHAT_LIMIT_SUCCEEDED' = 'FETCH_CHAT_LIMIT_SUCCEEDED'
export const FETCH_CHAT_LIMIT_FAILED: 'FETCH_CHAT_LIMIT_FAILED' = 'FETCH_CHAT_LIMIT_FAILED'

export const CREATE_USER_SAGA: 'CREATE_USER_SAGA' = 'CREATE_USER_SAGA'
export const EDIT_USER_SAGA: 'EDIT_USER_SAGA' = 'EDIT_USER_SAGA'
export const DELETE_USER_SAGA: 'DELETE_USER_SAGA' = 'DELETE_USER_SAGA'
export const DELETE_USER_REQUEST_PENDING: 'DELETE_USER_REQUEST_PENDING' = 'DELETE_USER_REQUEST_PENDING'
export const USER_LIST_CHANGED: 'USER_LIST_CHANGED' = 'USER_LIST_CHANGED'

export const SOCKET_SUPPORT_CHANGED_ACTIVITY_STATUS: 'SUPPORT_CHANGED_ACTIVITY_STATUS' = 'SUPPORT_CHANGED_ACTIVITY_STATUS'

export const UPLOAD_IMAGE_SAGA: 'UPLOAD_IMAGE_SAGA' = 'UPLOAD_IMAGE_SAGA'
export const UPLOAD_IMAGE_PENDING: 'UPLOAD_IMAGE_PENDING' = 'UPLOAD_IMAGE_PENDING'
export const UPLOAD_IMAGE_SUCCEEDED: 'UPLOAD_IMAGE_SUCCEEDED' = 'UPLOAD_IMAGE_SUCCEEDED'
export const UPLOAD_IMAGE_FAILED: 'UPLOAD_IMAGE_FAILED' = 'UPLOAD_IMAGE_FAILED'
export const CLEAR_UPLOADED_IMAGE: 'CLEAR_UPLOADED_IMAGE' = 'CLEAR_UPLOADED_IMAGE'
