import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  unreadDivider: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'calc(100% + 60px)',
    margin: '20px -30px 5px',
    padding: '5px',
    background: 'rgba(0, 0, 0, 0.07)',
    fontSize: '16px',
    color: '#333',
  },
})

export default makeStyles(styles)
