import { FC, useCallback, useState } from 'react'

import useStyles from './styles'
import { useDispatch } from 'react-redux'
import { ITemplatePhrase } from 'src/store/reducers/template-phrases'
import TemplateItemEdit from './edit-view'
import { deleteTemplate } from 'src/store/actions/templatePhrases'

interface ITemplateItemProps {
  template: ITemplatePhrase
}

const TemplateItem: FC<ITemplateItemProps> = ({ template }) => {
  const classes = useStyles()
  const [editMode, setEditMode] = useState<boolean>(false)

  const dispatch = useDispatch()
  const handleDeleteTemplate = useCallback(() => dispatch(deleteTemplate(template.id)), [dispatch, template])

  const handleEdit = useCallback(() => {
    setEditMode(true)
  }, [setEditMode])

  const handleDelete = useCallback(() => {
    if(window.confirm(`Are you sure to delete template "${template.id}"`)) {
      handleDeleteTemplate()
    } else {
      return
    }
  }, [template, handleDeleteTemplate])

  if (editMode) {
    return <TemplateItemEdit template={template} setEditMode={setEditMode}/>
  }

  return (
    <div className={classes.templateItemWrapper}>
      <div className={classes.templateContent}>
        <p className={classes.templateText}>{template.value}</p>
        <div className={classes.templateShortcutWrapper}>
          <p className={classes.shortcutTitle}>Shortcuts</p>
          <p className={classes.shortcut}>
            <span className={classes.shortcutHighlited}>#</span>{template.id}
          </p>
        </div>
      </div>
      <div className={classes.buttonsGroup}>
        <button className={classes.button} onClick={handleEdit}>
          Edit
        </button>
        <button className={classes.button} onClick={handleDelete}>
          Delete
        </button>
      </div>
    </div>
  )
}

export default TemplateItem
