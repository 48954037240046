import chatData from 'src/store/reducers/chat-data'
import adminData from 'src/store/reducers/admin-data'
import messagesData from 'src/store/reducers/messages-data'
import authData from 'src/store/reducers/auth'
import appData from 'src/store/reducers/app-conrol'
import templatePhrases from 'src/store/reducers/template-phrases'
import archiveData from 'src/store/reducers/archive-data'

const reducers = {
    chatData,
    messagesData,
    authData,
    appData,
    templatePhrases,
    adminData,
    archiveData,
}

export default reducers
