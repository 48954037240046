import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  supportFilterWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '10px',
  },

  label: {
    paddingBottom: '2px',
    fontSize: '12px',
  },

  supportSelectWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },

  supportSelect: {
    width: '100%',
    padding: '7px 15px',
    border: '1px solid rgba(110, 110, 110, 0.5)',
    borderRadius: '8px',
  },
})

export default makeStyles(styles)
