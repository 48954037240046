import { Dispatch } from 'redux'

import {
  CLEAR_ARCHIVE_DATA,
  FIND_ARCHIVE_SAGA,
} from 'src/store/types/archiveData'
import { IArchiveRequestProps } from '../sagas/find-in-archive/fetchArchive'

export const findArchive = (options: IArchiveRequestProps) => (dispatch: Dispatch) => {
  dispatch({
    type: FIND_ARCHIVE_SAGA,
    payload: {
      ...options,
    }
  })
}

export const clearArchiveData = () => (dispatch: Dispatch) => {
  dispatch({
    type: CLEAR_ARCHIVE_DATA,
  })
}
