import { useState, useCallback, useEffect, SetStateAction, Dispatch } from 'react'
import { useSelector } from 'react-redux'

import { IStore } from 'src/store/types'

interface IUseTemplates {
  message: string
  setMessage: Dispatch<SetStateAction<string>>
	templatesListRef: any
	textAreaRef: any
}
export const useTemplates = ({
	message,
	setMessage,
	templatesListRef,
	textAreaRef,
}: IUseTemplates) => {
  const filteredTemplatesKeysArray = useSelector((state: IStore) => state.templatePhrases.filteredKeys)
  const templatePhrasesList = useSelector((state: IStore) => state.templatePhrases.data)

  const [showTemplatePhrases, setShowTemplatePhrases] = useState<boolean>(false)
  const [activeTemplateIndex, setActiveTemplateIndex] = useState<number>(0)
  const [templateValue, setTemplateValue] = useState<string>('')

  useEffect(() => {
    filteredTemplatesKeysArray.length && setActiveTemplateIndex(0)
  }, [filteredTemplatesKeysArray.length, setActiveTemplateIndex])

  const handleChangeTemplateValue = useCallback((id: string) => {
    setTemplateValue(templatePhrasesList[`${id}`].value)

    // @ts-ignore
    textAreaRef.current && textAreaRef.current.focus()
  }, [setTemplateValue, templatePhrasesList, textAreaRef])

  const handleAddTemplateToMessageText = useCallback(() => {
    const slicedMessage = message.split('#').slice(0, -1).join('#')

    setMessage(`${slicedMessage}${templateValue}`)
    setShowTemplatePhrases(false)
    setTemplateValue('')
    textAreaRef.current && textAreaRef.current.focus()
  }, [setMessage, message, templateValue, textAreaRef])

  const hideTemplateListByClickOutside = useCallback((event) => {
    if (
      templatesListRef.current &&
      //@ts-ignore
      !templatesListRef.current.contains(event.target)
    ) {
      setShowTemplatePhrases(false)
      setTemplateValue('')
      window.removeEventListener('click', hideTemplateListByClickOutside)
    }
  }, [setShowTemplatePhrases, templatesListRef])

  useEffect(() => {
    showTemplatePhrases && window.addEventListener('click', hideTemplateListByClickOutside)
  }, [showTemplatePhrases, hideTemplateListByClickOutside])

  return {
		templateValue,
		setTemplateValue,
		showTemplatePhrases,
		setShowTemplatePhrases,
		activeTemplateIndex,
		setActiveTemplateIndex,
		handleChangeTemplateValue,
		handleAddTemplateToMessageText,
		filteredTemplatesKeysArray,
	}
}
