import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    maxHeight: '100vh',
    overflow: 'scroll',
  },

  overlay: {
    zIndex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.4)',
    cursor: 'pointer',
  },

  modalWrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  modalInner: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    borderRadius: '10px',
    background: '#fff',
  },

  closeButton: {
    position: 'absolute',
    top: '-8px',
    right: '-8px',
    width: '32px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '16px',
    background: '#FFFFFF',
    boxShadow: '-1px 2px 0px rgba(0, 0, 0, 0.15)',
    cursor: 'pointer',

    '&:hover': {
      '& $icon': {
        transform: 'rotate(90deg)',
      }
    },
  },

  icon: {
    transition: 'transform 0.3s',
  },
})

export default makeStyles(styles)
