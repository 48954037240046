import { useEffect, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"
import _isEmpty from 'lodash/isEmpty'

import Login from "./login/Login"
import ChatEntry from "./chat"

import { logOut } from "src/store/actions/auth"
import { updateAuthDataFromToken } from "src/store/actions/auth"
import { IStore } from "src/store/types"

const Entry = () => {
  const authData = useSelector((state: IStore) => state.authData.data)

  const dispatch = useDispatch()
  const getAuthDataFromToken = useCallback((token) => dispatch(updateAuthDataFromToken(token)), [dispatch])
  const handleLogOut = useCallback(() => dispatch(logOut()), [dispatch])

  const getAuthToken = useCallback(() => localStorage.getItem('authToken'), [])
  const authToken = getAuthToken()

  useEffect(() => {
    if (authToken && _isEmpty(authData)) {
      getAuthDataFromToken(localStorage.getItem('authToken'))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!authToken) {
      handleLogOut()
    }
  }, [authToken, handleLogOut])

  return _isEmpty(authData) ? <Login /> : <ChatEntry />
}

export default Entry
