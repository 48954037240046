import { FC, useCallback, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"

import useStyles from './styles'
import notifyer from 'src/common/notifyer'
import { IStore } from 'src/store/types'
import { createTemplate } from 'src/store/actions/templatePhrases'

const CreateTemplateForm: FC = () => {
  const classes = useStyles()

  const supportList = useSelector((state: IStore) => state.adminData.supportList)

  const dispatch = useDispatch()
  const handleCreateTemplate = useCallback((template) => dispatch(createTemplate(template)), [dispatch])

  const [templateText, setTemplateText] = useState<string>('')
  const [shortcutText, setShortcutText] = useState<string>('')

  const handleSubmit = useCallback(async (e: any) => {
    e.preventDefault()

    if (!templateText.length || !shortcutText.length) {
      notifyer.error('Fill in all the form fields')

      return
    }

    if (supportList.find((support) => support.email === shortcutText)) {
      notifyer.error('User with this email is already registered')

      return
    }

    await handleCreateTemplate({
      id: shortcutText,
      value: templateText,
    })

    setTemplateText('')
    setShortcutText('')
  }, [shortcutText, templateText, supportList, setTemplateText, setShortcutText, handleCreateTemplate])

  const handleTextChange = useCallback((e: any) => {
    setTemplateText(e.target.value)
  }, [setTemplateText])

  const handleShortcutChange = useCallback((e: any) => {
    setShortcutText(e.target.value)
  }, [setShortcutText])

  return (
    <form className={classes.templateForm}>
      <textarea
        value={templateText}
        onChange={handleTextChange}
        className={classes.templateFormTextArea}
        placeholder='Template text'
        rows={4}
      />
      <input
        value={shortcutText}
        onChange={handleShortcutChange}
        className={classes.templateFormInput}
        placeholder='Shortcut'
      />
      <button type="submit" className={classes.templateFormButton} onClick={handleSubmit}>
        Add this template
      </button>
    </form>
  )
}

export default CreateTemplateForm
