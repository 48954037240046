import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { UserRole } from 'src/common/constants'

import { IMessageData } from 'src/common/interfaces'
import { setUnreadDividerRef } from 'src/store/actions/appControl'

import useStyles from './styles'

interface IUnreadDivider {
  orderId: number
  writerId: number
  messageId: IMessageData['id']
  currentMessages: IMessageData[]
}

const UnreadDivider: FC<IUnreadDivider> = ({
  messageId,
  currentMessages,
}) => {
  const dispatch = useDispatch()
  const handleSetDividerNode = useCallback((node) => dispatch(setUnreadDividerRef(node)), [dispatch])

  const [lastMessageSenderRole, setLastMessageSenderRole] = useState<UserRole | null>(null)
  const unreadDividerRef = useRef(null)
  unreadDividerRef.current = null

  useEffect(() => {
    setLastMessageSenderRole(currentMessages[currentMessages.length - 1].sender.role)
  }, [currentMessages, messageId])

  useEffect(() => {
    return () => {
      handleSetDividerNode(null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleSetDividerNode(unreadDividerRef.current)
  }, [unreadDividerRef, handleSetDividerNode])

  const classes = useStyles()

  if (lastMessageSenderRole && lastMessageSenderRole !== UserRole.CLIENT) {
    return null
  }

  return (
    <div ref={unreadDividerRef} className={classes.unreadDivider}>Unread messages</div>
  )
}

export default UnreadDivider
