import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  tumblerWrapper: {
    height: '9px',
    marginLeft: '10px',
    pointerEvents: 'none',
  },

  tumbler: {
    position: 'relative',
    display: 'inline-block',
    width: '22px',
    height: '9px',
  },

  tumblerInput: {
    width: 0,
    height: 0,
    opacity: 0,

    '&:checked + $slider': {
      backgroundColor: '#AAC9F8',
    },

    '&:focus + $slider': {
      boxShadow: '0 0 1px #2196F3',
    },

    '&:checked + $slider:before': {
      backgroundColor: '#2F80ED',
      transform: 'translateX(10px)',
    },
  },


  slider: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: '12px',
    backgroundColor: '#f0f0f0',
    cursor: 'pointer',
    transition: '0.4s',

    '&:before': {
      position: 'absolute',
      content: "''",
      height: '13px',
      width: '13px',
      left: '0',
      bottom: '-2px',
      borderRadius: '50%',
      backgroundColor: '#ccc',
      transition: '0.4s',
    }
  },
})

export default makeStyles(styles)
