import { FC, useCallback, useEffect } from 'react'

import NavbarButton from './navbar-button'

import { NavbarButtonValues } from 'src/common/constants'

import useStyles from './styles'
import { IOrderChatGroup } from 'src/common/interfaces'
import { IStore } from 'src/store/types'
import { useDispatch } from 'react-redux'
import { setActiveTab } from 'src/store/actions/appControl'

interface IChatListNavbar {
  activeTab: NavbarButtonValues
  chatsData: IStore['chatData']['data']
  handleSetTabData: (data: { [key: string | number]: IOrderChatGroup } | null) => void
}

const ChatListNavbar: FC<IChatListNavbar> = ({
  activeTab,
  chatsData,
  handleSetTabData,
}) => {
  const classes = useStyles()
  const { active, queued } = chatsData
  const dispatch = useDispatch()
  const handleSetActiveTab = useCallback((activeTab: NavbarButtonValues) => dispatch(setActiveTab(activeTab)), [dispatch])

  useEffect(() => {
    switch (activeTab) {
      case NavbarButtonValues.ACTIVE:
        handleSetTabData(active)
        break

      case NavbarButtonValues.QUEUE:
        handleSetTabData(queued)
        break

      default:
        handleSetTabData(active)
        break
    }
  }, [chatsData, activeTab, handleSetTabData, active, queued])

  const handleChange = useCallback((event) => {
    switch (event.currentTarget.value) {
      case NavbarButtonValues.ACTIVE:
        handleSetActiveTab(NavbarButtonValues.ACTIVE)
        handleSetTabData(active)
        break

      case NavbarButtonValues.QUEUE:
        handleSetActiveTab(NavbarButtonValues.QUEUE)
        handleSetTabData(queued)
        break

      case NavbarButtonValues.ARCHIVE:
        handleSetActiveTab(NavbarButtonValues.ARCHIVE)
        break

      default:
        break
    }
  }, [handleSetActiveTab, handleSetTabData, active, queued])

  return (
    <div className={classes.chatListNavbarWrapper}>
      <div className={classes.chatListNavbarInner}>
        <NavbarButton
          label={NavbarButtonValues.ACTIVE}
          chatsCount={Object.entries(active).length || 0}
          checked={activeTab}
          onClick={handleChange}
        />
        <NavbarButton
          label={NavbarButtonValues.QUEUE}
          chatsCount={Object.entries(queued).length || 0}
          checked={activeTab}
          onClick={handleChange}
        />
        <NavbarButton
          label={NavbarButtonValues.ARCHIVE}
          checked={activeTab}
          onClick={handleChange}
        />
      </div>
    </div>
  )
}

export default ChatListNavbar
