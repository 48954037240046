import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  chatGroupButton: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20px',
    height: '28px',
    marginLeft: '14px',
    cursor: 'pointer',
  },

  menuWrapper: {
    zIndex: 1,
    position: 'absolute',
    top: '-5px',
    right: '30px',
    width: '134px',
    borderRadius: '10px',
    background: '#fff',
    filter: 'drop-shadow(0px 0px 14px rgba(0, 0, 0, 0.15))',
  },

  menuButton: {
    width: '100%',
    height: 'calc(100% / 3)',
    padding: '16px',
    background: 'none',
    border: 'none',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '14px',
    textAlign: 'left',
    color: '#4F5E7B',
    cursor: 'pointer',

    '&:hover': {
      background: 'rgba(40, 68, 123, 0.03)',
    },
  },
})

export default makeStyles(styles)
