import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  scrollToBottomButtonWrapper: {
    position: 'absolute',
    bottom: '100px',
    right: '20px',
    width: '30px',
    height: '30px',
    opacity: 0.3,
    transition: 'opacity 0.3s',
    cursor: 'pointer',

    '&:hover': {
      opacity: 0.7,
    }
  },

  unreadQuantity: {
    position: 'absolute',
    top: '-5px',
    right: '-3px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '16px',
    height: '16px',
    borderRadius: '8px',
    backgroundColor: 'rgb(40, 68, 123)',
    color: '#fff',
    fontSize: '10px',
  },
})

export default makeStyles(styles)
