import { APIv1 } from 'src/api'
import { call, put } from 'redux-saga/effects'

import notifyer from 'src/common/notifyer'
import { TEMPLATE_LIST_CHANGED } from 'src/store/types/templatePhrases'

const deleteTemplateRequest = (id: string): Promise<any> => (
  APIv1.delete('/admin/templates/delete', { data: { template: id }})
)

export default function* deleteTemplate(action: any) {
  try {
    // @ts-ignore
    yield call(deleteTemplateRequest, action.payload)
    notifyer.success(`Template was deleted`)
    yield put({ type: TEMPLATE_LIST_CHANGED })
  } catch (e) {
    notifyer.error('Request failed - server error')
  }
}
