import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  supportsViewWrapper: {
    width: '100%',
    height: '100%',
    maxHeight: '100vh',
    padding: '30px 310px 30px 30px',
    background: '#F1F2F4',
    overflow: 'scroll',
  },

  supportsViewInner: {
    maxWidth: '772px',
  },

  modalWrapper: {

  },
})

export default makeStyles(styles)
