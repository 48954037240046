import _isEmpty from 'lodash/isEmpty'

import { IChatStore } from 'src/store/reducers/chat-data'

const UNREAD_KEY = 'unreadQuantity'

export const getTotalUnreadFromChatData = (chatData: IChatStore['data']) => {
  let totalUnread: number = 0

  Object.keys(chatData).forEach(group => {
    if ((group === 'active' || group === 'queued') && !_isEmpty(group)) {
      Object.values(chatData[group]).forEach((chat => {
        totalUnread += chat[UNREAD_KEY]
      }))
    }
  })

  return totalUnread
}
