import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  writerShortInfoWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '72px',
    padding: '0 15px',
    background: '#fff',
  },

  shortInfoItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'calc(100% / 3)',
    fontSize: '18px',
    lineHeight: '21px',
  },

  writer: {
    cursor: 'pointer',

    '&:hover': {
      '& $writerName': {
        textDecoration: 'underline',
      }
    },
  },

  writerName: {
    marginLeft: '8px',
  },

  highlited: {
    fontWeight: 700,
  },

  rating: {
    borderRight: '1px solid rgba(155, 155, 155, 0.3)',
    borderLeft: '1px solid rgba(155, 155, 155, 0.3)',
  },

  rates: {

  },

})

export default makeStyles(styles)
