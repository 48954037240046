import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import jsonToFormData from '@ajoelp/json-to-formdata'

import Avatar from 'src/components/chat/avatar'

import useStyles from './styles'
import { ISupportItem } from 'src/store/reducers/admin-data'
import notifyer from 'src/common/notifyer'
import { useDispatch, useSelector } from 'react-redux'
import { clearUploadedImage, editUser, uploadImage } from 'src/store/actions/adminData'
import { IStore } from 'src/store/types'
import { ClipLoader } from 'react-spinners'

interface IEditView {
  support: ISupportItem
  setEditMode: Dispatch<SetStateAction<boolean>>
}

const EditView: FC<IEditView> = ({ support, setEditMode }) => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const handleEditUser = useCallback((userData) => dispatch(editUser(userData)), [dispatch])
  const handleUploadImage = useCallback((file) => dispatch(uploadImage(file)), [dispatch])
  const handleClearUploadedImage = useCallback(() => dispatch(clearUploadedImage()), [dispatch])

  const uploadedImagePath = useSelector((state: IStore) => state.adminData.uploadedImage.path)
  const uploadImagePending = useSelector((state: IStore) => state.adminData.uploadImagePending)

  const [showNotification, setShowNotification] = useState<boolean>(true)
  const [name, setName] = useState<string>(support.name)
  const [email, setEmail] = useState<string>(support.email)
  const [password, setPassword] = useState<string>('')
  const [avatarLink, setAvatarLink] = useState<string>(support.avatar)

  useEffect(() => {
    uploadedImagePath.length && setAvatarLink(uploadedImagePath)
  }, [uploadedImagePath, setAvatarLink])

  const handleNameChange = useCallback((e: any) => {
    setName(e.target.value)
  }, [setName])

  const handleEmailChange = useCallback((e: any) => {
    setEmail(e.target.value)
  }, [setEmail])

  const handlePasswordChange = useCallback((e: any) => {
    if (showNotification) {
      notifyer.warning('Leave password field empty if you dont want to change it')
      setShowNotification(false)
    }

    setPassword(e.target.value)
  }, [showNotification, setPassword, setShowNotification])

  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      if (!event.target.files[0].type.includes('image')) {
        notifyer.warning('Please upload image file!')

        return
      }

      if (event.target.files.length > 1) {
        notifyer.warning('You should upload only one image file')

        return
      }

      handleUploadImage(jsonToFormData(event.target.files))
    }
  }

  const handleSave = useCallback(async (e: any) => {
    e.preventDefault()

    if (support.status) {
      notifyer.warning('Sorry but user is online now')

      return
    }

    if (!name.length || !email.length) {
      notifyer.warning('Please fill name and email')

      return
    }

    if (!avatarLink.length) {
      notifyer.warning('Please repeat image upload or try another one')

      return
    }

    if (password.length && password.length < 8) {
      notifyer.warning('Password should be of minimum 8 characters length')

      return
    }

    const dataToSend = {
      ...support,
      name,
      email,
      // TODO: delete mock
      avatar: avatarLink,
    }

    if (password.length) {
      dataToSend.password = password
    }

    await handleEditUser({ ...dataToSend })

    setEditMode(false)
    handleClearUploadedImage()
  }, [support, name, email, password, avatarLink, handleEditUser, setEditMode, handleClearUploadedImage])

  const handleCancel = useCallback(() => {
    setEditMode(false)
  }, [setEditMode])


  return (
    <form className={clsx(classes.supportListItemWrapper, classes.grid)} onSubmit={handleSave}>
      <label className={classes.avatarInputWrapper}>
        <div className={classes.avatarWrapper}>
          {uploadImagePending ?
            <ClipLoader size={37}/>
            :
            <Avatar
              avatar={avatarLink.length ? avatarLink : support.avatar}
              size={37}
              online={support.status}
            />
          }

        </div>
        <input
          type="file"
          className={classes.avatarInput}
          onChange={handleAvatarChange}
        />
      </label>
      <input
        value={name}
        onChange={handleNameChange}
        className={classes.editInput}
        autoComplete="off"
        placeholder="Name"
      />
      <input
        value={email}
        onChange={handleEmailChange}
        className={classes.editInput}
        autoComplete="off"
        placeholder="Email"
        type="email"
      />
      <input
        value={password}
        onChange={handlePasswordChange}
        className={classes.editInput}
        autoComplete="off"
        placeholder="Password"
        type="password"
      />
      <div className={classes.buttonsGroup}>
        <button className={clsx(classes.saveButton, classes.button)} onClick={handleSave}>
          Save
        </button>
        <button className={clsx(classes.cancelButton, classes.button)} onClick={handleCancel}>
          Cancel
        </button>
      </div>
    </form>
  )
}

export default EditView
