import { FC, useEffect, useState } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import { useInView } from 'react-intersection-observer'

import clsx from 'clsx'
import moment from 'moment'

import { ReactComponent as FileDownloadIcon } from 'src/media/images/fileDownloadIcon.svg'
import { ReactComponent as MessageStatusReadedIcon } from 'src/media/images/messageStatusReadedIcon.svg'
import { ReactComponent as MessageStatusSendedIcon } from 'src/media/images/messageStatusSendedIcon.svg'

import Avatar from 'src/components/chat/avatar'

import { IMessageData } from 'src/common/interfaces'

import useStyles from './styles'
import { UserRole } from 'src/common/constants'
import { useSocketEmit } from 'src/socket/socketControl'
import { useSelector } from 'react-redux'
import { IStore } from 'src/store/types'
import { truncateFileName } from '../../message-form/files-list/helpers'

const ChatMessage: FC<IMessageData> = (message) => {
  const classes = useStyles()
  const [ref, inView] = useInView()
  const [convertedSendedTime, setConvertedSendedTime] = useState('')

  const { markMessageViewed } = useSocketEmit()
  const supportConnected = useSelector((state: IStore) => state.appData.supportConnected)

  useEffect(() => {
    const isToday = moment(message.sentAt).isSame(moment(), 'day')
    isToday ? setConvertedSendedTime(moment(message.sentAt).format('HH:mm')) : setConvertedSendedTime(moment(message.sentAt).format('MMMM Do YYYY, HH:mm'))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (supportConnected && inView && message.id && !message.viewed && message.sender.role === UserRole.CLIENT) {
      markMessageViewed({ messageId: `${message.id}` })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  return (
    <div ref={ref} className={clsx(classes.chatMessageWrapper, message.sender.role === UserRole.CLIENT ? classes.clientMessageWrapper : classes.supportMessageWrapper)}>
      <div className={classes.chatMessageInner}>
        <div className={classes.avatarWrapper}>
          <Avatar
            avatar={message.sender.avatar}
            userName={message.sender.name}
            online={message.sender.isOnline}
          />
        </div>

        <div className={classes.messageWrapper}>
          <p className={classes.senderName}>{message.sender.name}</p>
          <p className={classes.messageText}>{message.content}</p>

          {message.files && Boolean(message.files.length) && (
            <div className={classes.fileListWrapper}>
              {message.files.map((file) => (
                <a className={classes.fileWrapper} key={file.name} href={file.path} download target={'_blank'} rel={'noreferrer'}>
                  <div className={classes.downloadIconWrapper}>
                    <FileDownloadIcon />
                  </div>
                  <span className={classes.fileInfo}>
                    <span className={classes.fileName}>{truncateFileName(file.name)}</span>
                    <span className={classes.fileSize}>{file.size}</span>
                  </span>
                </a>
              ))}
            </div>
          )}

          <div className={classes.messageFooter}>
            <p className={classes.sendedTime}>{convertedSendedTime}</p>
            {message.sender.role !== UserRole.CLIENT && (
              <div className={classes.statusIconWrapper}>
                {message.pending && <ClipLoader color={'white'} size={10}/>}
                {message.viewed && !message.pending ? <MessageStatusReadedIcon /> : <MessageStatusSendedIcon />}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChatMessage
