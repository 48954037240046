import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  templatePhrasesListWrapper: {
    position: 'absolute',
    bottom: '50px',
    left: 0,
    width: '300px',
    minHeight: '200px',
    maxHeight: '400px',
    overflowY: 'scroll',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    backgroundColor: '#fff',
    boxShadow: '0px 7px 20px 0px rgba(34, 60, 80, 0.2)',
  },

  wrapperWithTemplateValue: {
    minHeight: 'initial',
  },

  templatePhrasesList: {
    width: '100%',
    border: 'none',
  },

  listHidden: {
    display: 'none',
  },

  templatePhrasesListItem: {
    display: 'block',
    padding: '5px 10px',
    cursor: 'pointer',

    '&:after': {
      content: '>'
    }
  },

  activeListItem: {
    backgroundColor: 'rgba(40, 68, 123, 0.09)',
  },

  templatePhraseValueWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '18px 22px 18px 17px',
    backgroundColor: 'rgba(40, 68, 123, 0.09)',
  },

  templatePhraseValue: {
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'underline',
    }
  },

  backButton: {
    marginRight: '10px',
    fontSize: '30px',
    fontWeight: 300,
    cursor: 'pointer',
  },

  noTemplatesText: {
    display: 'block',
    padding: '18px 22px 18px 5px',
    fontSize: '14px',
    opacity: 0.7,
  },
})

export default makeStyles(styles)
