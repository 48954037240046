import { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as yup from 'yup'
import clsx from 'clsx'

import useStyles from './styles'

import { login } from 'src/store/actions/auth'
import { IStore } from 'src/store/types'

const Login: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const pending = useSelector((state: IStore) => state.authData.pending)

  const loginSupport = useCallback((values) => dispatch(login(values)), [dispatch])

  const handleSubmit = useCallback(async (values) => {
    try {
      loginSupport(values)
    }
    catch(e) {
      console.warn(`Auth error: ${e}`)
    }
  }, [loginSupport])

  const validationSchema = yup.object({
    email: yup
      .string()
      .email('Enter a valid email')
      .required('Email is required'),
    password: yup
      .string()
      .min(8, 'Password should be of minimum 8 characters length')
      .required('Password is required'),
  })

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  })

  const emailFieldNotValid = formik.touched.email && Boolean(formik.errors.email)
  const passwordFieldNotValid = formik.touched.password && Boolean(formik.errors.password)
  const emailHint = formik.touched.email && formik.errors.email
  const passwordHint = formik.touched.password && formik.errors.password

  return (
    <div className={classes.loginWrapper}>
      <div className={classes.loginInner}>
        <p className={classes.loginHeader}>
          Sign In
        </p>
        <form
          onSubmit={formik.handleSubmit}
          className={classes.formWrapper}>
          <div className={classes.inputWrapper}>
            <label className={classes.inputLabel}>Email</label>
            <input
              className={clsx(classes.input, emailFieldNotValid && classes.inputError)}
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            {emailFieldNotValid && (
              <span className={classes.inputHint}>{emailHint}</span>
            )}
          </div>
          <div className={classes.inputWrapper}>
            <label className={classes.inputLabel}>Password</label>
            <input
              className={clsx(classes.input, passwordFieldNotValid && classes.inputError)}
              id="password"
              name="password"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
            />
            {passwordFieldNotValid && (
              <span className={classes.inputHint}>{passwordHint}</span>
            )}
          </div>
          <button className={clsx(classes.submitButton, pending && classes.submitButtonDisabled)} type="submit" disabled={pending}>
            Log In
          </button>
        </form>
      </div>
    </div>
  )
}

export default Login
