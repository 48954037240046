import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  grid: {
    display: 'grid',
    gridTemplateColumns: '47px 188px 188px 109px 154px',
    gridGap: '16px',
    alignItems: 'center',
  },

  supportListItemWrapper: {
    marginBottom: '10px',
    padding: '7px 10px',
    borderRadius: '15px',
    background: '#FFFFFF',
  },

  avatarWrapper: {

  },

  supportName: {
    margin: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  supportEmail: {
    margin: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  supportPassword: {
    margin: 0,
  },

  buttonsGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  button: {
    padding: '6px 16px',
    border: 'none',
    borderRadius: '8px',
    color: '#fff',
    fontSize: '16px',
    lineHeight: '18px',
    cursor: 'pointer',
    transition: 'background 0.3s',
  },

  editButton: {
    marginRight: '10px',
    background: '#28447B',

    '&:hover': {
      background: 'hsl(220, 51%, 42%)',
    },
  },

  deleteButton: {
    background: '#E34848',

    '&:hover': {
      background: 'hsl(0, 73%, 69%)',
    },
  },
})

export default makeStyles(styles)
