import { FC, useEffect, useCallback } from 'react'
import clsx from 'clsx'
import jsonToFormData from '@ajoelp/json-to-formdata'

import { APIv1 } from 'src/api'

import { statusIcons, adapter, singleUploadFile, truncateFileName } from './helpers'

import type { IFilesList, IAdaptedFiles } from './types'
import { FilesStatus } from './types'

import { ReactComponent as FileUpload } from 'src/media/images/chatUploadFilesIcon.svg'
import { ReactComponent as FileRepeatUpload } from 'src/media/images/chatFileRepeatIcon.svg'
import { ReactComponent as CloseIcon } from 'src/media/images/closeIcon.svg'

import useStyles from './styles'

const FilesList: FC<IFilesList> = ({
  files,
  filesAdapted,
  orderId,
  changeFilesAdapted,
  setFiles,
}) => {
  const classes = useStyles()

  useEffect(() => {
    if(!files.length) {
      changeFilesAdapted([])
      return
    }

    changeFilesAdapted(adapter(files, FilesStatus.PENDING))

    APIv1.post(
      `/file/upload-for/${orderId}`,
      jsonToFormData({files}),
      {
        headers: { "Content-Type": "multipart/form-data" },
      })
    .then((resp: any) => {
      changeFilesAdapted(resp.data.map((element: IAdaptedFiles) => ({
        id: element.id,
        name: element.name,
        size: element.size,
        path: element.path,
        status: element.error ? FilesStatus.ERROR : FilesStatus.SUCCESS,
      })))
    })
    .catch(() => {
      changeFilesAdapted(adapter(files, FilesStatus.ERROR))
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files])

  const handleRepeatUpload = useCallback((fileName: string) => {
    const neededFile = files.filter((file: File) => file.name === fileName)

    changeFilesAdapted(singleUploadFile(filesAdapted, fileName, FilesStatus.PENDING))

    APIv1.post(
      `/file/upload-for/${orderId}`,
      jsonToFormData({files: neededFile}),
      {
        headers: { "Content-Type": "multipart/form-data" },
      })
    .then((resp: any) => {
      changeFilesAdapted(singleUploadFile(filesAdapted, fileName, FilesStatus.SUCCESS, resp.data[0]))
    })
    .catch(() => {
      changeFilesAdapted(singleUploadFile(filesAdapted, fileName, FilesStatus.ERROR))
    })
  }, [files, changeFilesAdapted, filesAdapted, orderId])

  const handleClose = useCallback((fileName: string) => {
    const updatedFiles = [...files]

    updatedFiles.forEach((file, index) => {
      if (file.name === fileName) {
        updatedFiles.splice(index, 1)
      }
    })

    setFiles(updatedFiles)
  }, [files, setFiles])

  return (
    <div className={classes.wrapper}>
      {filesAdapted.map((file: IAdaptedFiles, key) => (
        <div className={clsx(classes.filePreview, file.status)} key={`${file.name}-${key}`}>
          <div className={classes.filePreviewLeft}>
            <FileUpload />
            <div className={classes.fileInfo}>
              <span className={classes.fileName}>{truncateFileName(file.name)}</span>
              <span className={classes.fileSize}>{Math.round(+file.size / 1024)}kb</span>
            </div>
          </div>
          <div className={classes.iconsWrapper}>
            <span className={clsx(classes.iconStatus, file.status)}>
              {statusIcons[file.status]}
            </span>
            {file.status === FilesStatus.ERROR && (
              <span className={classes.repeatIcon} onClick={() => handleRepeatUpload(file.name)}>
                <FileRepeatUpload />
              </span>
            )}
            <div className={classes.closeIconWrapper} onClick={() => handleClose(file.name)}>
              <CloseIcon className={classes.closeIcon}/>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default FilesList
