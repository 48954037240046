import { AnyAction as Action } from 'redux'
import { NavbarButtonValues } from 'src/common/constants'
import { IChatData, IOrderChatGroup } from 'src/common/interfaces'

import {
  SET_OPEN_WRITER_INFO,
  SET_MESSAGES_END_REF,
  SET_ARCHIVE_LIST_END_REF_VISIBILITY,
  SET_MESSAGES_LIST_WRAPPER_REF,
  SET_MESSAGE_ROOM_SCROLL_POSITION,
  SET_CURRENT_CHAT_GROUP_DATA,
  SET_CURRENT_ROOM_DATA,
  SET_ACTIVE_NAVBAR_TAB,
  SET_UNREAD_DIVIDER_REF,
  CLEAR_APP_DATA,
  FETCH_TRANSFER_SUCCEEDED,
} from 'src/store/types/appControl'
import { AUTH_LOG_OUT, SOCKET_USER_CONNECTED, SOCKET_USER_DISCONNECTED } from 'src/store/types/auth'
import { SOCKET_CHAT_CLOSED } from 'src/store/types/chatData'

export interface IAppData {
  isOpenWriterInfo: boolean,
  messagesEndNode: HTMLElement | null
  archiveListEndVisibility: boolean
  messagesListWrapperNode: HTMLElement | null
  unreadDividerNode: HTMLElement | null
  scrollPositionsList: {[key: string | number]: number}
  activeNavbarTab: NavbarButtonValues
  currentChatGroupId: IOrderChatGroup['order']['id'] | null
  currentChatGroupData: IOrderChatGroup | null,
  currentChatRoomData: IChatData | null
  supportConnected: boolean
}

const initialState: IAppData = {
  isOpenWriterInfo: false,
  messagesEndNode: null,
  archiveListEndVisibility: false,
  unreadDividerNode: null,
  messagesListWrapperNode: null,
  scrollPositionsList: {},
  activeNavbarTab: NavbarButtonValues.ACTIVE,
  currentChatGroupId: null,
  currentChatGroupData: null,
  currentChatRoomData: null,
  supportConnected: false,
}

const appData = (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_OPEN_WRITER_INFO: {
      return {
        ...state,
        isOpenWriterInfo: action.condition
      }
    }

    case SET_MESSAGES_END_REF: {
      return {
        ...state,
        messagesEndNode: action.node
      }
    }

    case SET_ARCHIVE_LIST_END_REF_VISIBILITY: {
      return {
        ...state,
        archiveListEndVisibility: action.inView
      }
    }

    case SET_UNREAD_DIVIDER_REF: {
      return {
        ...state,
        unreadDividerNode: action.node
      }
    }

    case SET_MESSAGES_LIST_WRAPPER_REF: {
      return {
        ...state,
        messagesListWrapperNode: action.node
      }
    }

    case SET_MESSAGE_ROOM_SCROLL_POSITION: {
      return {
        ...state,
        scrollPositionsList: {
          ...state.scrollPositionsList,
          [`${action.payload.orderId}${action.payload.writerId}`]: action.payload.scrollPosition,
        }
      }
    }

    case SET_ACTIVE_NAVBAR_TAB: {
      return {
        ...state,
        activeNavbarTab: action.payload.activeTab
      }
    }

    case SET_CURRENT_CHAT_GROUP_DATA: {
      return {
        ...state,
        currentChatGroupId: action.payload.orderId,
        currentChatGroupData: action.payload.data,
      }
    }

    case FETCH_TRANSFER_SUCCEEDED: {
      if (action.payload.orderId === state.currentChatGroupId) {
        return {
          ...state,
          currentChatGroupId: initialState.currentChatGroupId,
          currentChatGroupData: initialState.currentChatGroupData,
          currentChatRoomData: initialState.currentChatRoomData,
          error: null,
        }
      }
      return {
        ...state,
        error: null,
      }
    }

    case SET_CURRENT_ROOM_DATA: {
      return {
        ...state,
        currentChatRoomData: action.payload.data,
      }
    }

    case SOCKET_CHAT_CLOSED: {
      if (
        state.currentChatRoomData?.lastMessage?.identifiyers.group !== action.payload.orderId &&
        state.currentChatRoomData?.lastMessage?.identifiyers.identifyer !== action.payload.writerId
      ) {
        return {
          ...state,
        }
      }

      return {
        ...state,
        currentChatRoomData: {
          ...state.currentChatRoomData,
          closed: true,
        }
      }
    }

    case SOCKET_USER_CONNECTED: {
      return {
        ...state,
        supportConnected: true,
      }
    }

    case SOCKET_USER_DISCONNECTED: {
      return {
        ...state,
        supportConnected: false,
      }
    }

    case CLEAR_APP_DATA:
    case AUTH_LOG_OUT: {
      return {
        ...initialState,
      }
    }

    default:
      return state
  }
}
export default appData
