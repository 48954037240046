import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import _remove from 'lodash/remove'
import moment from 'moment'

import { IStore } from 'src/store/types'

import { ReactComponent as CrossIcon } from 'src/media/images/crossIcon.svg'

import useStyles from './styles'
import { ISupportItem } from 'src/store/reducers/admin-data'
import { IArchiveRequestProps } from 'src/store/sagas/find-in-archive/fetchArchive'
import { clearArchiveData, findArchive } from 'src/store/actions/archiveData'

interface IChosenFilters {
  supportIds: string[]
  setSupportIds: Dispatch<SetStateAction<string[]>>
  orderId: number | undefined
  setOrderId: Dispatch<SetStateAction<number | undefined>>
  setPage: Dispatch<SetStateAction<number>>
  startDate: Date | null,
  endDate: Date | null,
  setDateRange: Dispatch<SetStateAction<null[] | Date[]>>
  setShowFilters: Dispatch<SetStateAction<boolean>>
}

const ChosenFilters: FC<IChosenFilters> = ({
  orderId,
  supportIds,
  setSupportIds,
  setOrderId,
  setPage,
  startDate,
  endDate,
  setDateRange,
  setShowFilters,
}) => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const handleFindArchive = useCallback((options : IArchiveRequestProps) => dispatch(findArchive({...options})), [dispatch])
  const handleClearArchiveData = useCallback(() => dispatch(clearArchiveData()), [dispatch])

  const archiveData = useSelector((state: IStore) => state.archiveData.data)
  const supportList = useSelector((state: IStore) => state.adminData.supportList)
  const fetchArchivePending = useSelector((state: IStore) => state.archiveData.fetchArchivePending)

  const [chosenFilters, setChosenFilters] = useState<ISupportItem[]>([])

  const fetchArchiveIfThereAreNoMoreFilters = useCallback((orderId, supportIds, startDate, endDate) => {
    if (archiveData?.size && !fetchArchivePending && !orderId && !supportIds.length && !startDate && !endDate) {
      setPage(1)
      handleClearArchiveData()
      handleFindArchive({page: 1})
    }
  }, [archiveData, fetchArchivePending, setPage, handleFindArchive, handleClearArchiveData])

  useEffect(() => {
    const filtered = supportList.filter((item) => supportIds.find(selected => selected === item.id))

    setChosenFilters(filtered)
  }, [supportIds, setChosenFilters, supportList])

  const handleClearSupportFilter = useCallback((e: any) => {
    if (fetchArchivePending) {
      return
    }

    const filtered = _remove(supportIds, (id) => id !== e.currentTarget.getAttribute('data-id'))
    setSupportIds(filtered)
    fetchArchiveIfThereAreNoMoreFilters(orderId, filtered, startDate, endDate)
  }, [fetchArchivePending, supportIds, orderId, startDate, endDate, setSupportIds, fetchArchiveIfThereAreNoMoreFilters])

  const handleClearDateFilter = useCallback(() => {
    if (fetchArchivePending) {
      return
    }

    setDateRange([null, null])
    fetchArchiveIfThereAreNoMoreFilters(orderId, supportIds, null, null)
  }, [fetchArchivePending, orderId, supportIds, setDateRange, fetchArchiveIfThereAreNoMoreFilters])

  const handleClearOrderFilter = useCallback(() => {
    if (fetchArchivePending) {
      return
    }

    setOrderId(undefined)
    fetchArchiveIfThereAreNoMoreFilters(undefined, supportIds, startDate, endDate)
  }, [fetchArchivePending, supportIds, startDate, endDate, setOrderId, fetchArchiveIfThereAreNoMoreFilters])

  const handleClearAllFilters = useCallback(() => {
    if (fetchArchivePending) {
      return
    }

    setPage(1)
    setSupportIds([])
    handleClearOrderFilter()
    handleClearDateFilter()
    handleClearArchiveData()
    handleFindArchive({page: 1})
  }, [fetchArchivePending, setPage, setSupportIds, handleClearOrderFilter, handleClearDateFilter, handleClearArchiveData, handleFindArchive])

  const handleAcceptFilters = useCallback(() => {
    if (fetchArchivePending) {
      return
    }

    setPage(1)
    setShowFilters(false)
    handleClearArchiveData()
    handleFindArchive({
      orderId,
      supportIds,
      page: 1,
      dateFrom: moment(startDate).startOf('day').valueOf(),
      dateTo: moment(endDate).endOf('day').valueOf(),
    })
  }, [fetchArchivePending, setPage, handleClearArchiveData, handleFindArchive, orderId, supportIds, startDate, endDate, setShowFilters])

  return (
    <div className={classes.chosenFiltersWrapper}>
        <>
          {Boolean(chosenFilters.length) && chosenFilters.map(chosenFilter => (
            <div key={`${chosenFilter.name}${chosenFilter.id}`} className={clsx(classes.chosenListItem, fetchArchivePending && classes.disabledButton)}>
              {chosenFilter.name}
              <div className={classes.crossIconWrapper} data-id={chosenFilter.id} onClick={handleClearSupportFilter}>
                <CrossIcon />
              </div>
            </div>
          ))}
          {Boolean(orderId) && <div className={clsx(classes.chosenListItem, fetchArchivePending && classes.disabledButton)}>
            {`Order#${orderId}`}
            <div className={classes.crossIconWrapper} onClick={handleClearOrderFilter}>
              <CrossIcon />
            </div>
          </div>}
          {(startDate && endDate) && (
            <div className={clsx(classes.chosenListItem, fetchArchivePending && classes.disabledButton)}>
              {moment(startDate).format('x') === moment(endDate).format('x') ?
              `${moment(startDate).format('DD-MM-YYYY')}`
              :
              `${moment(startDate).format('DD-MM-YYYY')} - ${moment(endDate).format('DD-MM-YYYY')}`
            }
              <div className={classes.crossIconWrapper} onClick={handleClearDateFilter}>
                <CrossIcon />
              </div>
            </div>
          )}
          {(chosenFilters.length || orderId || startDate || endDate) && (
            <>
              <div onClick={handleClearAllFilters} className={clsx(classes.chosenListItem, classes.clearFiltersButton, fetchArchivePending && classes.disabledButton)}>
                Clear all
              </div>
              <div onClick={handleAcceptFilters} className={clsx(classes.chosenListItem, classes.acceptFiltersButton, fetchArchivePending && classes.disabledButton)}>
                Accept filters
              </div>
            </>
          )}
        </>
    </div>
  )
}

export default ChosenFilters
