import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import _isEmpty from 'lodash/isEmpty'

import { setRoomScrollPosition } from 'src/store/actions/appControl'

import { IStore } from "src/store/types"
import _useEventListener from "src/common/hooks/useEventListener"

interface IScrollLogic {
  haveUnreadMessage: boolean
  orderId: number
  writerId: number
}

export const useScrollLogic = ({
  haveUnreadMessage,
  orderId,
  writerId,
}: IScrollLogic) => {
  const dispatch = useDispatch()
  const [userMadeScrollMove, setUserMadeScrollMove] = useState(false)
  const scrollPositionsList = useSelector((state: IStore) => state.appData.scrollPositionsList)
  const wrapperNode = useSelector((state: IStore) => state.appData.messagesListWrapperNode)
  const unreadDividerNode = useSelector((state: IStore) => state.appData.unreadDividerNode)
  const messagesEndNode = useSelector((state: IStore) => state.appData.messagesEndNode)
  const typedData = useSelector((state: IStore) => state.messagesData.typedData)
  const currentTypedData = typedData[`${orderId}${writerId}`]

  const userScrollPosition = scrollPositionsList[`${orderId}${writerId}`]

  const handleSetUserScrollPosition = useCallback((orderId, writerId, scrollPosition) => dispatch(
    setRoomScrollPosition(orderId, writerId, scrollPosition)
  ),[dispatch])

  const handleScroll = useCallback(() => {
    !userMadeScrollMove && setUserMadeScrollMove(true)
    wrapperNode && handleSetUserScrollPosition(orderId, writerId, wrapperNode!.scrollTop)
  }, [orderId, writerId, wrapperNode, handleSetUserScrollPosition, userMadeScrollMove, setUserMadeScrollMove])

  _useEventListener(wrapperNode!, 'scroll', handleScroll, [orderId, writerId], { useThrottle: true, delay: 400 })

  const scrollToUnread = useCallback(() => {
    !userMadeScrollMove && unreadDividerNode && unreadDividerNode.scrollIntoView()
  }, [unreadDividerNode, userMadeScrollMove])

  const scrollBottom = useCallback(() => {
    wrapperNode && (wrapperNode.scrollTop = wrapperNode.scrollHeight)
  }, [wrapperNode])

  const scrollToSavedPosition = useCallback(() => {
    wrapperNode && (wrapperNode.scrollTop = userScrollPosition)
  }, [wrapperNode, userScrollPosition])

  useEffect(() => {
    if ((haveUnreadMessage || currentTypedData) && messagesEndNode && wrapperNode && wrapperNode.scrollHeight && wrapperNode.scrollTop) {
      (wrapperNode.scrollHeight - wrapperNode.scrollTop) < 600 && messagesEndNode.scrollIntoView({behavior: 'smooth'})
    }
  }, [messagesEndNode, wrapperNode, haveUnreadMessage, currentTypedData])

  useEffect(() => {
    setUserMadeScrollMove(false)
  }, [orderId, writerId, setUserMadeScrollMove])

  useEffect(() => {
    const haveSavedPosition = !_isEmpty(scrollPositionsList) && userScrollPosition !== undefined

    switch(true) {
      case haveUnreadMessage: {
        scrollToUnread()
        break
      }

      case haveSavedPosition: {
        scrollToSavedPosition()
        break
      }

      default: {
        scrollBottom()
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, writerId, wrapperNode, unreadDividerNode])
}
