import { Dispatch } from 'redux'
import { IChatIdentifiers, IMessageData } from 'src/common/interfaces'
import { IAdaptedFiles } from 'src/components/chat/chat-area/message-form/files-list/types'
import { ITypedData } from 'src/socket/socket-listeners/typing'

import {
  FETCH_MESSAGES_DATA_SAGA,
  SEND_MESSAGE,
  NEW_MESSAGE_FROM_SOCKET,
  STORE_ENTERED_MESSAGE_DATA_BY_ROOM,
  UPDATE_TYPED_DATA,
} from 'src/store/types/messagesData'

export const fetchMessagesData = (
  orderId: IChatIdentifiers['group'], writerId: IChatIdentifiers['identifyer']
) => (dispatch: Dispatch) => {
  dispatch({
    type: FETCH_MESSAGES_DATA_SAGA,
    orderId,
    writerId,
  })
}

export const updateStoreOnWriterMessage = (orderId: number, writerId: number, message: IMessageData) => (dispatch: Dispatch) => {
  dispatch({
    type: SEND_MESSAGE,
    payload: {
      orderId,
      writerId,
      message,
    },
  })
}

export const setNewMessageFromWS = (message: IMessageData) => (dispatch: Dispatch) => {
  dispatch({
    type: NEW_MESSAGE_FROM_SOCKET,
    payload: message,
  })
}

interface ISavedUnsendMessage {
  orderId: number,
  writerId: number,
  text: string,
  adaptedFiles: IAdaptedFiles[],
}

export const saveEnteredDataByRoom = ({
  orderId,
  writerId,
  text,
  adaptedFiles,
}: ISavedUnsendMessage) => (dispatch: Dispatch) => {

  dispatch({
    type: STORE_ENTERED_MESSAGE_DATA_BY_ROOM,
    payload: {
      orderId,
      writerId,
      text,
      adaptedFiles,
    },
  })
}

export const updateTypedData = (data: ITypedData | {}) => (dispatch: Dispatch) => {
  dispatch({
    type: UPDATE_TYPED_DATA,
    payload: data,
  })
}
