import { FC } from 'react'
import CreateTemplateForm from './create-template-form'

import useStyles from './styles'
import TemplatesList from './templates-list'

const TemplatesView: FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.templatesViewWrapper}>
      <p className={classes.templatesViewTitle}>Templates</p>
      <CreateTemplateForm />
      <TemplatesList />
    </div>
  )
}

export default TemplatesView
