import { createStyles, makeStyles } from '@material-ui/styles'

export interface IClasses {
  classes: {
    wrapper: string
    avatarWrapper: string
    image: string
    letters: string
    onlineBadge: string
  }
}

const styles = () => createStyles({
  wrapper: {
    position: 'relative',
    display: 'inline-block',
  },

  avatarWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '36px',
    minHeight: '36px',
    overflow: 'hidden',
  },

  image: {
    objectFit: 'cover',
  },

  letters: {
    color: '#fff',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '19px',
  },

  onlineBadge: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: '12px',
    height: '12px',
    background: '#4CE417',
    border: '2px solid #FFFFFF',
    borderRadius: '12px',
  },
})

export default makeStyles(styles)
