import { AnyAction as Action } from 'redux'

import { AUTH_LOG_OUT } from 'src/store/types/auth'

import {
  FETCH_TEMPLATE_PHRASES_SUCCEEDED,
  FETCH_TEMPLATE_PHRASES_PENDING,
  FETCH_TEMPLATE_PHRASES_FAILED,
  CLEAR_TEMPLATE_PHRASES_STORE,
  SET_FILTERED_TEMPLATES_KEYS,
  TEMPLATE_LIST_CHANGED,
} from 'src/store/types/templatePhrases'

export interface ITemplatePhrase {
  id: string
  value: string
}
export interface ITemplatePhrasesStore {
  data: {[key: string | number]: ITemplatePhrase}
  filteredKeys: string[]
  error: any,
  pending: boolean,
  templateListChanged: boolean,
}

const initialState: ITemplatePhrasesStore = {
  data: {},
  filteredKeys: [],
  error: null,
  pending: false,
  templateListChanged: false,
}

const templatePhrases = (state = initialState, action: Action) => {
  switch (action.type) {
    case FETCH_TEMPLATE_PHRASES_PENDING: {
      return {
        ...state,
        pending: true,
        error: null,
        templateListChanged: false,
      }
    }

    case FETCH_TEMPLATE_PHRASES_SUCCEEDED: {
      return {
        ...state,
        data: action.payload,
        pending: false,
        error: null,
        templateListChanged: false,
      }
    }

    case SET_FILTERED_TEMPLATES_KEYS: {
      return {
        ...state,
        filteredKeys: action.payload,
      }
    }

    case FETCH_TEMPLATE_PHRASES_FAILED: {
      return {
        ...state,
        error: action.payload,
        pending: false,
      }
    }

    case TEMPLATE_LIST_CHANGED: {
      return {
        ...state,
        templateListChanged: true,
      }
    }

    case AUTH_LOG_OUT:
    case CLEAR_TEMPLATE_PHRASES_STORE: {
      return {
        ...initialState,
      }
    }

    default:
      return state
  }
}

export default templatePhrases
