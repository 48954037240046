import { Dispatch } from 'redux'
import { IFile } from 'src/common/interfaces'

import {
  OPEN_ADMIN_PROFILE,
  CLOSE_ADMIN_PROFILE,
  FETCH_SUPPORT_LIST_SAGA,
  CREATE_USER_SAGA,
  DELETE_USER_SAGA,
  EDIT_USER_SAGA,
  FETCH_CHAT_LIMIT_SAGA,
  SET_CHAT_LIMIT_SAGA,
  UPLOAD_IMAGE_SAGA,
  CLEAR_UPLOADED_IMAGE,
} from 'src/store/types/adminData'
import { ISupportItem } from '../reducers/admin-data'

export const openAdminProfile = () => (dispatch: Dispatch) => {
  dispatch({
    type: OPEN_ADMIN_PROFILE,
  })
}

export const closeAdminProfile = () => (dispatch: Dispatch) => {
  dispatch({
    type: CLOSE_ADMIN_PROFILE,
  })
}

export const fetchSupportList = () => (dispatch: Dispatch) => {
  dispatch({
    type: FETCH_SUPPORT_LIST_SAGA,
  })
}

export const fetchChatLimit = () => (dispatch: Dispatch) => {
  dispatch({
    type: FETCH_CHAT_LIMIT_SAGA,
  })
}

export const putChatLimit = (chatLimit: number) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_CHAT_LIMIT_SAGA,
    payload: chatLimit,
  })
}

export const createUser = (userData: ISupportItem) => (dispatch: Dispatch) => {
  dispatch({
    type: CREATE_USER_SAGA,
    payload: userData,
  })
}

export const editUser = (userData: ISupportItem) => (dispatch: Dispatch) => {
  dispatch({
    type: EDIT_USER_SAGA,
    payload: userData,
  })
}

export const deleteUser = (userId: string) => (dispatch: Dispatch) => {
  dispatch({
    type: DELETE_USER_SAGA,
    payload: userId,
  })
}

export const uploadImage = (files: IFile[]) => (dispatch: Dispatch) => {
  dispatch({
    type: UPLOAD_IMAGE_SAGA,
    payload: files,
  })
}

export const clearUploadedImage = () => (dispatch: Dispatch) => {
  dispatch({
    type: CLEAR_UPLOADED_IMAGE,
  })
}
