import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  leftSidebar: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '383px',
    minWidth: '383px',
    maxWidth: '383px',
    maxHeight: '100vh',
  },

  noChatsText: {
    opacity: 0.5,
    padding: '0 0 0 20px',
  },
})

export default makeStyles(styles)
