import { AnyAction as Action } from 'redux'
import { UserRole } from 'src/common/constants'

import {
  OPEN_ADMIN_PROFILE,
  CLOSE_ADMIN_PROFILE,
  FETCH_SUPPORT_LIST_PENDING,
  FETCH_SUPPORT_LIST_SUCCEEDED,
  FETCH_SUPPORT_LIST_FAILED,
  USER_LIST_CHANGED,
  DELETE_USER_REQUEST_PENDING,
  SOCKET_SUPPORT_CHANGED_ACTIVITY_STATUS,
  FETCH_CHAT_LIMIT_SUCCEEDED,
  FETCH_CHAT_LIMIT_PENDING,
  FETCH_CHAT_LIMIT_FAILED,
  SET_CHAT_LIMIT_SUCCEEDED,
  UPLOAD_IMAGE_PENDING,
  UPLOAD_IMAGE_SUCCEEDED,
  UPLOAD_IMAGE_FAILED,
  CLEAR_UPLOADED_IMAGE,
} from 'src/store/types/adminData'

import {
  CLEAR_APP_DATA,
} from 'src/store/types/appControl'
import { AUTH_LOG_OUT } from 'src/store/types/auth'

export interface ISupportItem {
  avatar: string
  id?: string
  name: string
  role: UserRole
  email: string
  status?: boolean
  password?: string
}

export interface IAdminStore {
  supportList: ISupportItem[],
  showAdminMenu: boolean
  pending: boolean,
  error: any,
  userListChanged: boolean
  deleteUserPending: boolean
  chatLimit: number | null
  chatLimitPenging: boolean
  uploadedImage: {
    name: string
    path: string
  }
  uploadImagePending: boolean
}

const initialState: IAdminStore = {
  supportList: [],
  showAdminMenu: false,
  pending: false,
  error: null,
  userListChanged: false,
  deleteUserPending: false,
  chatLimit: null,
  chatLimitPenging: false,
  uploadedImage: {
    name: '',
    path: '',
  },
  uploadImagePending: false,
}

const adminData = (state: IAdminStore = initialState, action: Action) => {
  switch (action.type) {
    case OPEN_ADMIN_PROFILE: {
      return {
        ...state,
        showAdminMenu: true,
      }
    }

    case CLOSE_ADMIN_PROFILE: {
      return {
        ...state,
        showAdminMenu: false,
      }
    }

    case FETCH_SUPPORT_LIST_PENDING: {
      return {
        ...state,
        pending: true,
        error: null,
      }
    }

    case FETCH_SUPPORT_LIST_SUCCEEDED: {
      return {
        ...state,
        supportList: [...action.payload],
        pending: false,
        error: null,
        userListChanged: false,
      }
    }

    case FETCH_SUPPORT_LIST_FAILED: {
      return {
        ...state,
        pending: false,
        error: action.payload,
      }
    }

    case FETCH_CHAT_LIMIT_PENDING: {
      return {
        ...state,
        chatLimitPenging: true,
      }
    }

    case FETCH_CHAT_LIMIT_SUCCEEDED: {
      return {
        ...state,
        chatLimit: action.payload.limit,
        chatLimitPenging: false,
      }
    }

    case SET_CHAT_LIMIT_SUCCEEDED: {
      return {
        ...state,
        chatLimit: action.payload,
      }
    }

    case FETCH_CHAT_LIMIT_FAILED: {
      return {
        ...state,
        chatLimitPenging: false,
        error: action.payload,
      }
    }

    case SOCKET_SUPPORT_CHANGED_ACTIVITY_STATUS: {
      const supportListUpdated = [...state.supportList].map(function(item) {
        return item.id === action.payload.id ? {...item, status: action.payload.status} : item;
      });

      return {
        ...state,
        supportList: [...supportListUpdated],
      }
    }

    case DELETE_USER_REQUEST_PENDING: {
      return {
        ...state,
        deleteUserPending: true,
      }
    }

    case USER_LIST_CHANGED: {
      return {
        ...state,
        userListChanged: true,
        deleteUserPending: false,
      }
    }

    case UPLOAD_IMAGE_PENDING: {
      return {
        ...state,
        uploadImagePending: true,
      }
    }

    case UPLOAD_IMAGE_SUCCEEDED: {
      return {
        ...state,
        uploadedImage: {...action.payload},
        uploadImagePending: false,
      }
    }

    case UPLOAD_IMAGE_FAILED: {
      return {
        ...state,
        uploadImagePending: false,
        error: action.payload,
      }
    }

    case CLEAR_UPLOADED_IMAGE: {
      return {
        ...state,
        uploadedImage: {
          name: '',
          path: '',
        },
      }
    }

    case CLEAR_APP_DATA:
    case AUTH_LOG_OUT: {
      return {
        ...initialState,
      }
    }

    default:
      return state
  }
}
export default adminData
