import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  messageFormWrapper: {
    margin: '0 20px 20px 20px',
  },

  messageFormInner: {
    position: 'relative',
    display: 'flex',
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '38px',
    padding: '18px 22px 18px 17px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    background: '#fff',
  },

  iconWrapper: {
    display: 'block',
    width: '24px',
    height: '24px',
    cursor: 'pointer',
  },

  iconWrapperChecked: {
    '& svg': {
      filter: 'invert(49%) sepia(90%) saturate(2594%) hue-rotate(161deg) brightness(94%) contrast(101%)',
    }
  },

  messageInput: {
    width: '100%',
    margin: '0 0 0 18px',
    paddingTop: '10px',
    maxHeight: '200px',
    minHeight: '40px',
    border: 'none',
    outline: 'none',
    color: '#1B1A57',
    fontFamily: "'Trebuchet MS', sans-serif !important",
    resize: 'none',

    '&::placeholder': {
      fontSize: '12px',
      lineHeight: '18px',
      opacity: 0.5,
    }
  },
})

export default makeStyles(styles)
