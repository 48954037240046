import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import SyncLoader from 'react-spinners/SyncLoader'

import Avatar from 'src/components/chat/avatar'

import { IStore } from 'src/store/types'

import useStyles from './styles'

interface IChatMessagePreview {
  orderId: number
  writerId: number
}

const ChatMessagePreview: FC<IChatMessagePreview> = ({
  orderId,
  writerId,
}) => {
  const classes = useStyles()
  const [showSpinner, setShowSpinner] = useState<boolean>(false)

  const currentChatGroupData = useSelector((state: IStore) => state.appData.currentChatGroupData)

  const typedData = useSelector((state: IStore) => state.messagesData.typedData)
  const currentTypedData = typedData[`${orderId}${writerId}`]

  useEffect(() => {
    setShowSpinner(true)

    setTimeout(() => {
      setShowSpinner(false)
    }, 2000)
  }, [currentTypedData])

  if (
    !currentTypedData ||
    !currentTypedData?.length
  ) {
      return null
  }

  return (
    <div className={classes.chatMessagePreviewWrapper}>
      <div className={classes.chatMessagePreviewInner}>
        <div className={classes.avatarWrapper}>
          {currentChatGroupData && <Avatar
            avatar={currentChatGroupData.customer.avatar}
            userName={currentChatGroupData.customer.name}
            // online={currentChatGroupData.customer.isOnline}
          />}
        </div>

        <div className={classes.messagePreviewTextWrapper}>
          <span className={classes.messagePreviewText}>{currentTypedData}</span>
          {showSpinner && <SyncLoader size={5} color={'#28447B'} margin={2} />}
        </div>
      </div>
    </div>
  )
}

export default ChatMessagePreview
