import { createStyles, makeStyles } from '@material-ui/styles'
import avatarEditBg from 'src/media/images/avatarEditBg.svg'

const styles = () => createStyles({
  grid: {
    display: 'grid',
    gridTemplateColumns: '47px 188px 188px 109px 154px',
    gridGap: '16px',
    alignItems: 'center',
  },

  supportListItemWrapper: {
    marginBottom: '10px',
    padding: '7px 10px',
    borderRadius: '15px',
    background: '#FFFFFF',
  },

  avatarWrapper: {

  },

  avatarInputWrapper: {
    cursor: 'pointer',

    '&:hover': {
      background: `url(${avatarEditBg})`,
      backgroundRepeat: 'no-repeat',

      '& $avatarWrapper': {
        opacity: 0.2,
      }
    }
  },

  avatarInput: {
    display: 'none',
  },

  editInput: {
    padding: '8px 12px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '10px',
    background: '#F2F3F5',
    fontSize: '16px',
    lineHeight: '19px',
  },

  buttonsGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  button: {
    padding: '6px 16px',
    border: 'none',
    borderRadius: '8px',
    color: '#fff',
    fontSize: '16px',
    lineHeight: '18px',
    cursor: 'pointer',
    transition: 'background 0.3s',
  },

  saveButton: {
    marginRight: '10px',
    background: '#469100',

    '&:hover': {
      background: 'hsl(91, 100%, 38%)',
    },
  },

  cancelButton: {
    background: '#fcba03',

    '&:hover': {
      background: 'hsl(44, 98%, 60%)',
    },
  },
})

export default makeStyles(styles)
