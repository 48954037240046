import { APIv1 } from 'src/api'
import { call, put } from 'redux-saga/effects'

import { FETCH_AUTH_DATA_SUCCEEDED, FETCH_AUTH_DATA_FAILED, FETCH_AUTH_DATA_PENDING } from 'src/store/types/auth'
import notifyer from 'src/common/notifyer'

export interface IAuthDataFromForm {
  email: string
  password: string
}

const fetchAuthDataRequest = (authDataFromForm: IAuthDataFromForm): Promise<any> => (
  APIv1.post('/auth/login', authDataFromForm)
)

export default function* fetchAuthData(action: any) {
  try {
    yield put({ type: FETCH_AUTH_DATA_PENDING })
    // @ts-ignore
    const result = yield call(fetchAuthDataRequest, action.authDataFromForm)
    localStorage.setItem('authToken', result.data.token)

    yield put({ type: FETCH_AUTH_DATA_SUCCEEDED, payload: result.data.token })
  } catch (e) {
    notifyer.error('Request failed - server error')
    yield put({ type: FETCH_AUTH_DATA_FAILED, payload: e })
  }
}
