import { Dispatch, useCallback, useEffect } from "react";
import { Socket } from "socket.io-client";
import notifyer from "src/common/notifyer";
import { clearAppData } from "src/store/actions/appControl";
import { handleSocketDisconnected } from "src/store/actions/chatData";

export const useDisconnect = (socket: Socket, dispatch: Dispatch<any>) => {
  const clearPendingRequests = useCallback(() => dispatch(handleSocketDisconnected()), [dispatch])
  const handleClearAppData = useCallback(() => dispatch(clearAppData()), [dispatch])

  useEffect(() => {
    const handler = () => {
      clearPendingRequests()
      notifyer.error('Socket connection down')
      handleClearAppData()
    }

    socket.on('disconnect', handler)
    return () => {
      socket.off('disconnect', handler)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
