import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  chatListNavbarWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
    height: '72px',
    minHeight: '72px',
    maxHeight: '72px',
    borderBottom: '1px solid #E6E6E6',
    borderRight: '1px solid #E6E6E6',
  },

  chatListNavbarInner: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    border: '1px solid #ccc',
    borderRadius: '10px',
    overflow: 'hidden',

    '& > :not(:last-child)': {
      borderRight: '1px solid #ccc',
      borderLeft: 'none',
      borderTop: 'none',
      borderBottom: 'none',
    },
  },
})

export default makeStyles(styles)
