import * as chatData from 'src/store/sagas/chat-data'
import * as messages from 'src/store/sagas/messages-data'
import * as auth from 'src/store/sagas/auth'
import * as newGroup from 'src/store/sagas/new-group-from-socket'
import * as transferChat from 'src/store/sagas/transfer-chat'
import * as templatePhrases from 'src/store/sagas/template-phrases'
import * as supportList from 'src/store/sagas/admin-data'
import * as chatLimit from 'src/store/sagas/admin-get-chat-limit'
import * as setChatLimit from 'src/store/sagas/admin-set-chat-limit'
import * as createUser from 'src/store/sagas/admin-create-user'
import * as editUser from 'src/store/sagas/admin-edit-user'
import * as deleteUser from 'src/store/sagas/admin-delete-user'
import * as createTemplate from 'src/store/sagas/admin-create-template'
import * as editTemplate from 'src/store/sagas/admin-edit-template'
import * as deleteTemplate from 'src/store/sagas/admin-delete-template'
import * as uploadImage from 'src/store/sagas/admin-upload-image'
import * as refreshToken from 'src/store/sagas/refresh-token'
import * as findInArchive from 'src/store/sagas/find-in-archive'

const sagas = [
  ...Object.values(chatData),
  ...Object.values(messages),
  ...Object.values(auth),
  ...Object.values(newGroup),
  ...Object.values(transferChat),
  ...Object.values(templatePhrases),
  ...Object.values(supportList),
  ...Object.values(createUser),
  ...Object.values(editUser),
  ...Object.values(deleteUser),
  ...Object.values(createTemplate),
  ...Object.values(editTemplate),
  ...Object.values(deleteTemplate),
  ...Object.values(chatLimit),
  ...Object.values(setChatLimit),
  ...Object.values(uploadImage),
  ...Object.values(refreshToken),
  ...Object.values(findInArchive),
]

export default sagas
