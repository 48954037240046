import { Dispatch, useCallback, useEffect } from "react";
import { AnyAction } from "redux";
import { Socket } from "socket.io-client";
import notifyer from "src/common/notifyer";
import { setCurrentChatRoomData } from "src/store/actions/appControl";

export const useChatGroupClosed = (socket: Socket, dispatch: Dispatch<any>) => {
  const handleCloseChat = useCallback((data) => dispatch({ ...data }), [dispatch]) // SOCKET_CHAT_GROUP_CLOSED
  const handleSetCurrentChatRoomData = useCallback((data) => dispatch(setCurrentChatRoomData(data)), [dispatch])

  useEffect(() => {
    const handler = (data: AnyAction) => {
      handleCloseChat(data)
      handleSetCurrentChatRoomData(null)
      if (!data.payload.silent) {
        !data.payload.orderId ? notifyer.error('Empty socket response') : notifyer.success(`Chat by order #${data.payload.orderId} was closed`)
      }
    }

    socket.on('chat-group-closed', handler)
    return () => {
      socket.off('chat-group-closed', handler)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
