import { AnyAction as Action } from 'redux'
import _isEmpty from 'lodash/isEmpty'

import { IOrderChatGroup } from 'src/common/interfaces'

import {
  CLEAR_ARCHIVE_DATA,
  FETCH_ARCHIVE_SUCCEEDED,
  FETCH_ARCHIVE_PENDING,
  FETCH_ARCHIVE_FAILED,
} from 'src/store/types/archiveData'
import { AUTH_LOG_OUT } from 'src/store/types/auth'
import { CLEAR_APP_DATA } from 'src/store/types/appControl'

export interface IArchiveStore {
  data: Map<number, IOrderChatGroup> | null
  error: any,
  fetchArchivePending: boolean
  emptyResponseRecived: boolean
}

const initialState: IArchiveStore = {
  data: null,
  error: null,
  fetchArchivePending: false,
  emptyResponseRecived: false,
}

const archiveData = (state = initialState, action: Action) => {
  switch (action.type) {
    case FETCH_ARCHIVE_PENDING: {
      return {
        ...state,
        fetchArchivePending: true
      }
    }

    case FETCH_ARCHIVE_SUCCEEDED: {
      if (_isEmpty(action.payload)) {
        return {
          ...state,
          emptyResponseRecived: true,
          fetchArchivePending: false
        }
      }

      let adapted = new Map()

      action.payload.forEach((item: any) => {
        adapted.set(item.key, item.data)
      })

      const final = state.data ? new Map([...state.data, ...adapted]) : adapted

      return {
        ...state,
        data: final,
        fetchArchivePending: false,
        emptyResponseRecived: false
      }
    }

    case FETCH_ARCHIVE_FAILED: {
      return {
        ...state,
        fetchArchivePending: false,
        emptyResponseRecived: false,
      }
    }

    case CLEAR_ARCHIVE_DATA:
    case CLEAR_APP_DATA:
    case AUTH_LOG_OUT: {
      return {
        ...initialState,
      }
    }

    default:
      return state
  }
}

export default archiveData
