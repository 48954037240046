import { APIv1 } from 'src/api'

import { call, put } from 'redux-saga/effects'
import { IChatIdentifiers } from 'src/common/interfaces'

import { FETCH_MESSAGES_DATA_FAILED, FETCH_MESSAGES_DATA_SUCCEEDED, FETCH_MESSAGES_DATA_PENDING } from 'src/store/types/messagesData'
// import { getMessages } from 'src/stories/mock'

// const fetchMessagesDataRequest = async (orderId: IChatIdentifiers['group'], writerId: IChatIdentifiers['identifyer']): Promise<any> => ({
//   orderId,
//   writerId,
//   data: await getMessages(orderId, writerId)
// })

const fetchMessagesDataRequest = (
  orderId: IChatIdentifiers['group'],
  writerId: IChatIdentifiers['identifyer']
): Promise<any> => APIv1.get(`/message/support/${orderId}/${writerId}`)

export default function* fetchMessagesData(action: any) {
  try {
    yield put({ type: FETCH_MESSAGES_DATA_PENDING })

    // @ts-ignore
    const result = yield call(fetchMessagesDataRequest, action.orderId, action.writerId)

    yield put({
      type: FETCH_MESSAGES_DATA_SUCCEEDED,
      payload: {
        data: result.data,
        orderId: action.orderId,
        writerId: action.writerId
      }
    })
  } catch (e) {
    yield put({ type: FETCH_MESSAGES_DATA_FAILED, payload: e })
  }
}
