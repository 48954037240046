export const getLettersFromName = (string: string) => string.split(' ')
  .map((word, index) => {
    const letter = word ? word[0].toLocaleUpperCase() : ''

    return /\d/.test(letter) && index !== 0 ? '' : letter
  })
  .slice(0, 2)
  .join('')

export default getLettersFromName
