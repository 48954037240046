import { APIv1 } from 'src/api'

import { call, put } from 'redux-saga/effects'

import notifyer from 'src/common/notifyer'
import { SET_CHAT_LIMIT_SUCCEEDED } from 'src/store/types/adminData'

const setChatLimitRequest = (chatLimit: number): Promise<any> => APIv1.put(`admin/system/chat-limit/update`, {limit: chatLimit})

export default function* setChatLimit(action: any) {
  try {
    // @ts-ignore
    yield call(setChatLimitRequest, action.payload)
    notifyer.success('Chats limit updated')
    yield put({ type: SET_CHAT_LIMIT_SUCCEEDED, payload: action.payload })

  } catch (e) {
    notifyer.error('Request current chat limit failed, server error')
  }
}
