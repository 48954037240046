import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  clientInfoWrapper: {
    padding: '22px 28px 0 22px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '20px 20px 0 0',
    background: '#F9F9FB',
  },

  headerInfo: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '30px',
  },

  headerIext: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 0 9px',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#28447B',
  },

  clientName: {
    fontWeight: 700,
  },

  activeChatsCount: {
    opacity: 0.4,
  },

  mainInfoWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },

  mainInfoItem: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 'calc(50% - 25px)',
    margin: '0 0 23px',
    fontSize: '16px',
    lineHeight: '19px',
  },

  mainInfoLinkItem: {
    width: '100%',
  },

  mainInfoHighlited: {
    marginBottom: '7px',
    fontWeight: 700,
    textTransform: 'capitalize',
    color: '#171717',
  },

  mainInfoValue: {
    color: '#4A4A4A',
    opacity: 0.8,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  pageLink: {
    fontSize: '17px',
    lineHeight: '20px',
    color: '#226CFF',
  },
})

export default makeStyles(styles)
