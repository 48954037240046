import { FC } from 'react'
import { useSelector } from 'react-redux'
import ClipLoader from 'react-spinners/ClipLoader'

import WriterShortInfo from './writer-short-info'
import MessagesList from './messages-list'
import MessageForm from './message-form'

import useStyles from './styles'
import { IChatData, IOrderChatGroup } from 'src/common/interfaces'
import { IStore } from 'src/store/types'
import WriterInfo from './writer-info'

interface IChatArea {
  hideFormInput: boolean
  currentChatGroupData: IOrderChatGroup | null
  currentRoomData: IChatData | null
}

const ChatArea: FC<IChatArea> = ({
  hideFormInput,
  currentChatGroupData,
  currentRoomData,
}) => {
  const classes = useStyles()
  const isPendingMessages = useSelector((state: IStore) => state.messagesData.pending)

  if (!currentRoomData || !currentChatGroupData) {
    return <div className={classes.emptyBlock} />
  }

  return (
    <>
      <div className={classes.chatAreaWrapper}>
        <WriterShortInfo
          avatar={currentRoomData.writer.avatar}
          name={currentRoomData.writer.name}
          profileLevel={currentRoomData.writer.profileLevel}
          writerPrice={currentRoomData.writer.price}
        />
        <div className={classes.messagesWindowWrapper}>
          {isPendingMessages ? (
              <div className={classes.spinnerWrapper}>
                <ClipLoader />
              </div>
            )
          :
            (
              <>
                <MessagesList
                  orderId={currentChatGroupData.order.id}
                  writerId={currentRoomData.writer.id}
                />
                {(!hideFormInput) && (
                  <MessageForm
                    orderId={currentChatGroupData.order.id}
                    writer={currentRoomData.writer}
                  />
                )}
                {currentRoomData.closed && (
                  <div className={classes.closedNotificationWrapper}>
                    <span className={classes.closedNotification}>Chat was closed by user</span>
                  </div>
                )}
              </>
              )
          }
        </div>
      </div>
      <WriterInfo
        writer={currentRoomData.writer}
      />
    </>
  )
}

export default ChatArea
