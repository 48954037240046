import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  chatMessagePreviewWrapper: {
    display: 'flex',
    padding: '10px 0',
  },

  chatMessagePreviewInner: {
    display: 'flex',
    alignItems: 'flex-end',
    maxWidth: '450px',
  },

  avatarWrapper: {
    opacity: 0.3,
  },

  messagePreviewTextWrapper: {
    marginLeft: '10px',
    padding: '7px 13px 10px 10px',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderRadius: '8px 8px 8px 0px',
    fontSize: '14px',
  },

  messagePreviewText: {
    marginRight: '5px',
    opacity: 0.7,
    fontStyle: 'italic',
  },
})

export default makeStyles(styles)
