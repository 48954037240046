import axios from 'axios'

import notifyer from 'src/common/notifyer'
import { logOut } from 'src/store/actions/auth'
import { store } from '../store'

const APIv1 = axios.create({
  baseURL: process.env.REACT_APP__API_V1__URL,
  headers: {
    "content-type": "application/json",
  },
  responseType: "json"
})

const createAxiosResponseInterceptor = () => {
  APIv1.interceptors.request.use(
    (config) => {
      if (localStorage.getItem('authToken')) {
        config.headers.Authorization = `Bearer ${localStorage.getItem('authToken')}`
      }

      return config;
    },
    (error) => Promise.reject(error),
  )

  const interceptor = APIv1.interceptors.response.use(
    response => {
      return response
    },
    error => {
      if (error?.response?.status === 401) {
        APIv1.interceptors.response.eject(interceptor)

        return APIv1.get('/auth/refresh-token')
          .then(response => {
            localStorage.setItem('authToken', response.data.token)
            error.response.config.headers.Authorization = `Bearer ${response.data.token}`
            APIv1.defaults.headers.common.Authorization = `Bearer ${response.data.token}`

            return axios(error.response.config)
          }).catch(error => {
            localStorage.removeItem('authToken')
            delete APIv1.defaults.headers.common.Authorization

            if (error.response.data.error === 'Jwt refresh time been expired, login again.') {
              notifyer.warning('Your session was expired - please relogin.')
            } else {
              notifyer.error('You are not authorized')
            }
            store.dispatch(logOut())
            return Promise.reject(error)
          }).finally(createAxiosResponseInterceptor)
      } else {
        return Promise.reject(error)
      }
    },
  )
}

createAxiosResponseInterceptor()

export default APIv1
