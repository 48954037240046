import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  radioButtonInput: {
    display: 'none',
  },

  buttonLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'calc(100% / 3)',
    padding: '10px',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '19px',
    background: '#F2F3F5',
    boxShadow: 'none',
    color: '#767677',
    cursor: 'pointer',
    textTransform: 'capitalize',
  },

  active: {
    border: 'none',
    background: '#28447B',
    color: '#fff',
  },
})

export default makeStyles(styles)
