import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  "@keyframes spin": {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },

  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: '-10px',
    border: '1px solid #E6E6E6',
    borderRadius: '10px 10px 0 0',
  },

  filePreview: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '8px 16px 8px 14px',
    borderBottom: '1px solid #E6E6E6',
    background: '#fff',

    '&.success': {
      background: 'rgba(45, 187, 110, .05)',
    },

    '&.error': {
      background: 'rgba(226, 9, 31, 0.05)',
    },

    '&:first-child': {
      borderRadius: '10px 10px 0 0',
    },

    '&:last-child': {
      paddingBottom: 18,
    },
  },

  filePreviewLeft: {
    display: 'flex',
    alignItems: 'center',
  },

  fileInfo: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 7,
  },

  fileName: {
    marginRight: 3,
    fontSize: 12,
    lineHeight: '13px',
    color: '#28447B',
  },

  fileSize: {
    fontSize: 12,
    lineHeight: '13px',
    color: '#28447B',
    opacity: .5,
  },

  iconsWrapper: {
    display: 'flex',
    alignItems: 'center',
  },

  repeatIcon: {
    display: 'flex',
    marginLeft: 10,
    cursor: 'pointer',
  },

  iconStatus: {
    display: 'flex',

    '&.pending': {
      animation: '$spin 1s infinite linear',
    }
  },

  closeIconWrapper: {
    width: '22px',
    height: '22px',
    marginLeft: '15px',
    cursor: 'pointer',
    opacity: 0.5,
    transition: 'transform 0.3s, opacity 0.3s',

    '&:hover': {
      opacity: 1,
      transform: 'rotate(90deg)'
    }
  },

  closeIcon: {
    width: '22px',
    height: '22px',
  },
})

export default makeStyles(styles)
