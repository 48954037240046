import { Dispatch, useCallback, useEffect } from "react";
import { AnyAction } from "redux";
import { Socket } from "socket.io-client";

import notifyer from "src/common/notifyer";

export const useChatUpdated = (socket: Socket, dispatch: Dispatch<any>) => {
  const handleUpdateChat = useCallback((data) => dispatch({ ...data }), [dispatch]) // CHAT_UPDATED

  useEffect(() => {
    const handler = (data: AnyAction) => {
      handleUpdateChat(data)
      !data.payload.silent && notifyer.warning(`Chat by order ${data.payload.order.id} was updated`)
    }

    socket.on('chat-updated', handler)
    return () => {
      socket.off('chat-updated', handler)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
