interface IPlayProps {
  sound: HTMLAudioElement
  muted?: boolean
  volume?: number
}

export const playAudio = ({ sound, muted, volume }: IPlayProps) => {
  if (volume) {
    sound.volume = volume
  }

  sound.muted = Boolean(muted)

  const playPromise = sound.play()

  if (playPromise !== undefined) {
    playPromise
      // @ts-ignore
      .then(_ => {
        // Automatic playback started!
        // Show playing UI.
        console.log("audio played auto");
      })

      // @ts-ignore
      .catch(error => {
        console.log(error)
        // Auto-play was prevented
        // Show paused UI.
        console.log("playback prevented");
      })
  }
}
