import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  chatListWrapper: {
    position: 'relative',
    height: '100%',
    maxHeight: 'calc(100vh - 72px - 60px)',
    overflowY: 'scroll',
    borderRight: '1px solid #E6E6E6',
  },

  clipLoaderWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  }
})

export default makeStyles(styles)
