import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  adminAreaWrapper: {
    width: '100%',
    height: '100%',
  },
})

export default makeStyles(styles)
