import { FC } from 'react'

import ChatLimitView from './chat-limits-view'
import SupportsView from './supports-view'
import TemplatesView from './templates-view'

import { MenuButtons } from 'src/components/chat/admin-menu/index'

import useStyles from './styles'

interface IAdminAreaProps {
  activeNavItem: MenuButtons
}

const AdminArea: FC<IAdminAreaProps> = ({ activeNavItem }) => {
  const classes = useStyles()

  return (
    <div className={classes.adminAreaWrapper}>
			{ activeNavItem === MenuButtons.SUPPORTS && <SupportsView /> }
			{ activeNavItem === MenuButtons.CHAT_LIMITS && <ChatLimitView /> }
			{ activeNavItem === MenuButtons.TEMPLATES && <TemplatesView /> }
    </div>
  )
}

export default AdminArea
