import { FC, useCallback, useEffect, useState } from 'react'
import notifyer from 'src/common/notifyer'

import useStyles from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { IStore } from 'src/store/types'
import { fetchChatLimit, putChatLimit } from 'src/store/actions/adminData'
import { ClipLoader } from 'react-spinners'

const ChatLimitView: FC = () => {
  const chatLimitFromStore = useSelector((state: IStore) => state.adminData.chatLimit)
  const pending = useSelector((state: IStore) => state.adminData.chatLimitPenging)

  const classes = useStyles()
  const [chatLimit, setChatLimit] = useState<number | null>(null)

  const dispatch = useDispatch()
  const handleFetchChatLimit = useCallback(() => dispatch(fetchChatLimit()), [dispatch])
  const handleSetChatLimit = useCallback((chatLimit) => dispatch(putChatLimit(chatLimit)), [dispatch])

  useEffect(() => {
    !chatLimitFromStore && handleFetchChatLimit()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setChatLimit(chatLimitFromStore)
  }, [chatLimitFromStore, setChatLimit])

  const handleDecrement= useCallback((e: any) => {
    if (chatLimit === 1) {
      notifyer.warning('Сhats limit cannot be less than 1')
      return
    }

    chatLimit && setChatLimit(chatLimit - 1)
  }, [setChatLimit, chatLimit])


  const handleIncrement = useCallback((e: any) => {
    chatLimit && setChatLimit(chatLimit + 1)
  }, [setChatLimit, chatLimit])

  const handleSave = useCallback(() => {
    handleSetChatLimit(chatLimit)
  }, [chatLimit, handleSetChatLimit])

  if (pending) {
    return (
      <div className={classes.chatsLimitViewWrapper}>
        <div className={classes.spinnerWrapper}>
          <ClipLoader />
        </div>
      </div>
    )
  }

  if (!chatLimit) {
    return null
  }

  return (
    <div className={classes.chatsLimitViewWrapper}>
      <p className={classes.chatLimitViewTitle}>
        Chats limit
      </p>
      <div className={classes.controlsWrapper}>
        <div className={classes.numberInputWrapper}>
          <button className={classes.numberInputButton} onClick={handleDecrement}>
            -
          </button>
          <div className={classes.numberInput}>{chatLimit}</div>
          <button className={classes.numberInputButton} onClick={handleIncrement}>
            +
          </button>
        </div>
        <p className={classes.numberInputLabel}>Concurrent chats</p>
      </div>
      <button className={classes.chatsLimitSaveButton} onClick={handleSave}>
        Save changes
      </button>
    </div>
  )
}

export default ChatLimitView
