import { FC, useState, useEffect, useRef, useCallback } from 'react'
import clsx from 'clsx'
import moment from 'moment'
import SyncLoader from 'react-spinners/SyncLoader'

import { NavbarButtonValues, UserRole } from 'src/common/constants'
import { IChatData, IOrderChatGroup } from 'src/common/interfaces'

import useStyles from './styles'
import UnreadMessageCount from '../unread-messages-count'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentChatRoomData } from 'src/store/actions/appControl'
import { IStore } from 'src/store/types'
import { updateUnansweredChats } from 'src/store/actions/chatData'

interface IChatRoom {
  groupIndex: number
  writerId: string
  chatGroup: IOrderChatGroup
  currentChatGroupData: IOrderChatGroup | null
  roomData: IChatData
  currentRoomData: IChatData | null
  handleChatRoomButton: (e: any) => void
}

const ChatRoom: FC<IChatRoom> = ({
  groupIndex,
  writerId,
  roomData,
  chatGroup,
  currentChatGroupData,
  currentRoomData,
  handleChatRoomButton,
}) => {
  const dispatch = useDispatch()
  const handleSetCurrentChatRoomData = useCallback((data) => dispatch(setCurrentChatRoomData(data)), [dispatch])
  const handleUpdateAnansweredChats = useCallback((value) => dispatch(updateUnansweredChats({key: `${groupIndex}${writerId}`, value})), [dispatch, groupIndex, writerId])
  const classes = useStyles()

  const activeTab = useSelector((state: IStore) => state.appData.activeNavbarTab)
  const typedData = useSelector((state: IStore) => state.messagesData.typedData)
  const unansweredChats = useSelector((state: IStore) => state.chatData.unansweredChats)
  const currentChatIsUnanswered = unansweredChats[`${groupIndex}${writerId}`]

  const currentTypedData = typedData[`${chatGroup.order.id}${writerId}`]

  const [lastMessageText, setLastMessageText] = useState<string>('')
  const [lastMessageSender, setLastMessageSender] = useState<string>('')
  const [lastMessageTime, setLastMessageTime] = useState<string>('')

  const updateTime = useRef<any>(null)
  const unansweredMessageTimeout = useRef<any>(null)

  useEffect(() => {
    if (!currentChatIsUnanswered) {
      clearTimeout(unansweredMessageTimeout.current)

      if (roomData?.lastMessage?.sender.role === UserRole.CLIENT) {
        unansweredMessageTimeout.current = setTimeout(() => {
          handleUpdateAnansweredChats(true)
        }, 2*60*1000) // 2 minutes
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomData.lastMessage, handleUpdateAnansweredChats])

  useEffect(() => {
    if (roomData?.lastMessage?.sender.role !== UserRole.CLIENT && currentChatIsUnanswered) {
      clearTimeout(unansweredMessageTimeout.current)
      handleUpdateAnansweredChats(false)
    }
  }, [currentChatIsUnanswered, roomData.lastMessage, handleUpdateAnansweredChats])

  useEffect(() => {
    if (
      currentRoomData &&
      currentRoomData?.lastMessage &&
      roomData?.lastMessage &&
      currentRoomData.lastMessage?.identifiyers.group === roomData.lastMessage?.identifiyers.group &&
      currentRoomData.lastMessage?.identifiyers.identifyer === roomData.lastMessage?.identifiyers.identifyer
    ) {
      handleSetCurrentChatRoomData(roomData)
    }
  }, [roomData, currentRoomData, handleSetCurrentChatRoomData])
  useEffect(() => {
    if (currentTypedData?.length) {
      setLastMessageText(currentTypedData)
      setLastMessageSender("Client")
    }

    if (!currentTypedData?.length && roomData?.lastMessage) {
      roomData?.lastMessage?.sender?.role !== UserRole.CLIENT ?
        setLastMessageSender("You") :
        setLastMessageSender("Client")

      roomData?.lastMessage?.content.length ?
        setLastMessageText(roomData.lastMessage?.content) :
        setLastMessageText('send file')
    }
  }, [currentTypedData, roomData, setLastMessageText, setLastMessageSender])

  useEffect(() => {
    clearInterval(updateTime.current)
  }, [roomData.lastMessage])

  useEffect(() => {
    return () => {
      clearInterval(updateTime.current)
      clearTimeout(unansweredMessageTimeout.current)
    }
  }, [])

  useEffect(() => {
    if (roomData?.lastMessage && roomData?.lastMessage?.sentAt) {
    const isToday = moment(roomData.lastMessage?.sentAt).isSame(moment(), 'day')

    if (isToday) {
      setLastMessageTime(moment(roomData.lastMessage?.sentAt).fromNow(true))

      updateTime.current = setInterval(
        () => {
          setLastMessageTime(moment(roomData.lastMessage?.sentAt).fromNow(true))
        }, 60000
      )
    } else {
      setLastMessageTime(moment(roomData.lastMessage?.sentAt).format('MMMM Do'))
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomData?.lastMessage?.sentAt])

  if (!currentChatGroupData) {
    return null
  }

  return (
    <div
      className={clsx(
        classes.chatRoom,
        parseInt(`${currentChatGroupData?.order.id}${currentRoomData?.writer.id}`) === parseInt(`${chatGroup.order.id}${roomData.writer.id}`) && classes.activeChat,
        roomData.closed && classes.closedChat,
        currentChatIsUnanswered && activeTab !== NavbarButtonValues.ARCHIVE && classes.withAlertBorder,
      )}
      data-groupindex={groupIndex}
      data-writerid={writerId}
      data-orderid={chatGroup.order.id}
      data-id={parseInt(`${roomData.writer.id}${chatGroup.order.id}`)}
      onClick={handleChatRoomButton}
    >
      <p className={classes.chatRoomHeader}>
        <span>
          {`№${chatGroup.order.id} - ${roomData.writer.name}`}
        </span>
        <span>
          {lastMessageTime}
        </span>
      </p>
      <div className={classes.chatRoomFooter}>
        <span className={classes.lastMessage}>
          <span className={classes.lastMessageFrom}>
            {lastMessageSender}
          </span>
          {currentTypedData?.length ? <SyncLoader size={3} color={'#28447B'} /> : null}
          {lastMessageText}
        </span>
        {activeTab !== NavbarButtonValues.ARCHIVE && <UnreadMessageCount count={roomData.unreadQuantity} />}
      </div>
    </div>
  )
}

export default ChatRoom
