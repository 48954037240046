import { APIv1 } from 'src/api'

import { call, put } from 'redux-saga/effects'

import { FETCH_CHAT_DATA_FAILED, FETCH_CHAT_DATA_SUCCEEDED, FETCH_CHAT_DATA_PENDING } from 'src/store/types/chatData'
import notifyer from 'src/common/notifyer'
// import { getAsyncChatsData } from 'src/stories/mock'

// const fetchChatDataRequest = async (): Promise<any> => {
//   return await getAsyncChatsData()
// }

const fetchChatDataRequest = (): Promise<any> => APIv1.get('/chat/support/payload')

export default function* fetchChatData() {
  try {
    yield put({ type: FETCH_CHAT_DATA_PENDING })

    // @ts-ignore
    const result = yield call(fetchChatDataRequest)

    // yield put({ type: FETCH_CHAT_DATA_SUCCEEDED, payload: result })
    yield put({ type: FETCH_CHAT_DATA_SUCCEEDED, payload: result.data })
  } catch (e) {
    notifyer.error('Request chats data failed, server error')
    yield put({ type: FETCH_CHAT_DATA_FAILED, payload: e })
  }
}
