import { FC, Dispatch, SetStateAction } from 'react'

import { ReactComponent as UploadIcon } from 'src/media/images/uploadIcon.svg'

import useStyles from './styles'

interface IUploadButton {
  setFiles: Dispatch<SetStateAction<File[]>>
}

const UploadButton: FC<IUploadButton> = ({
  setFiles,
}) => {
  const classes = useStyles()

  const handleFilesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFiles([...Array.from(event.target.files)])
    }
  }

  return (
    <label className={classes.uploadButtonLabel}>
      <UploadIcon />
      <input
          type="file"
          className={classes.filesInput}
          onChange={handleFilesChange}
          multiple
      />
    </label>
  )
}

export default UploadButton
