import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'

import { IStore } from 'src/store/types'

import { getTotalUnreadFromChatData } from './getTotalUnreadFromChatData'

const PAGE_TITLE = 'Qwerty chat'

const ChatHelmet: FC = () => {
  const chatData = useSelector((state: IStore) => state.chatData.data)
  const [pageTitle, setPageTitle] = useState<string>(PAGE_TITLE)
  const intervalRef = useRef<any>(null)

  const totalUnread = useMemo(() => {
    return getTotalUnreadFromChatData(chatData)
  }, [chatData])

  const intervalHandler = useCallback(() => {
    if (pageTitle === PAGE_TITLE) {
      setPageTitle(`(${totalUnread}) ${PAGE_TITLE}`)
    } else {
      setPageTitle(PAGE_TITLE)
    }
  }, [totalUnread, setPageTitle, pageTitle])

  useEffect(() => {
    if (totalUnread) {
      intervalRef.current = setInterval(intervalHandler, 1000)

      return () => {
        clearInterval(intervalRef.current)
      }
    } else {
      setPageTitle(PAGE_TITLE)
    }
  }, [totalUnread, intervalHandler])

  return (
    <Helmet defer={false}>
      <title>{pageTitle}</title>
    </Helmet>
  )
}

export default ChatHelmet
