import { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import _isEmpty from 'lodash/isEmpty'

import { IMessageData } from 'src/common/interfaces'
import { IStore } from 'src/store/types'
import { ReactComponent as ChevronDownInCircle } from 'src/media/images/chevronDownInCircle.svg'

import useStyles from './styles'
import { NavbarButtonValues } from 'src/common/constants'

interface IScrollToBottomButton {
  currentMessages: IMessageData[]
}

const ScrollToBottomButton: FC<IScrollToBottomButton> = ({
  currentMessages,
}) => {
  const classes = useStyles()
  const currentRoomData = useSelector((state: IStore) => state.appData.currentChatRoomData)
  const messagesEndNode = useSelector((state: IStore) => state.appData.messagesEndNode)
  const activeTab = useSelector((state: IStore) => state.appData.activeNavbarTab)

  const [unreadCount, setUnreadCount] = useState<number | undefined>(currentRoomData?.unreadQuantity)

  useEffect(() => {
    if (currentRoomData && !_isEmpty(currentRoomData)) {
      setUnreadCount(currentRoomData.unreadQuantity)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMessages.length, currentRoomData])

  const handleButton = useCallback(() => {
    if (messagesEndNode) {
      messagesEndNode.scrollIntoView({behavior: 'smooth'})
    }
  }, [messagesEndNode])

  return (
    <div className={classes.scrollToBottomButtonWrapper} onClick={handleButton}>
      <ChevronDownInCircle />
      {(activeTab !== NavbarButtonValues.ARCHIVE && Boolean(unreadCount)) && <span className={classes.unreadQuantity}>
        {unreadCount}
      </span>}
    </div>
  )
}

export default ScrollToBottomButton
