import { createStyles, makeStyles } from '@material-ui/styles'
import orderDetailsArrow from 'src/media/images/orderDetailsArrow.svg'

const styles = () => createStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '72px',
  },

  iconBlock: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
  },

  icon: {
    marginRight: '8px',
  },

  iconBlockText: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '19px',
    color: '#28447B',
  },

  orderLink: {
    position: 'relative',
    paddingRight: '20px',
    fontSize: '17px',
    lineHeight: '20px',
    color: '#226CFF',
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline',
    },

    '&:after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '16px',
      height: '100%',
      background: `url(${orderDetailsArrow})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
})

export default makeStyles(styles)
