import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  templateItemWrapper: {
    display: 'flex',
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
  },

  templateContent: {
    maxWidth: '600px',
    minWidth: '600px',
    width: '600px',
    marginRight: '90px',
  },

  templateText: {
    minWidth: '100%',
    width: '100%',
    fontSize: '16px',
    lineHeight: '22px',
    color: '#6D7885',
    margin: '10px 0',
  },

  templateShortcutWrapper: {
    display: 'flex',
    alignItems: 'center',
  },

  shortcutTitle: {
    marginRight: '10px',
    fontSize: '14px',
    lineHeight: '22px',
    textTransform: 'uppercase',
    color: '#6D7885',
    opacity: 0.5,
  },

  shortcut: {
    padding: '0 7px',
    border: '1px solid #28447B',
    borderRadius: '7px',
    background: '#FFFFFF',
    fontSize: '14px',
    lineHeight: '22px',
  },

  shortcutHighlited: {
    color: '#4080BF',
  },

  buttonsGroup: {
    display: 'flex',
    alignSelf: 'flex-end',
    marginBottom: '10px',
  },

  button: {
    border: 'none',
    background: 'none',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#0052F4',
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'underline',
    }
  },
})

export default makeStyles(styles)
