import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  templatesViewTitle: {
    margin: '0 0 10px',
    color: '#6D7885',
    fontSize: '16px',
    lineHeight: '18px',
  },

  templatesViewWrapper: {
    width: '100%',
    height: '100%',
    maxHeight: '100vh',
    padding: '30px',
    background: '#F1F2F4',
    overflow: 'scroll',
  },
})

export default makeStyles(styles)
