export const FETCH_TEMPLATE_PHRASES_SAGA: 'FETCH_TEMPLATE_PHRASES_SAGA' = 'FETCH_TEMPLATE_PHRASES_SAGA'
export const FETCH_TEMPLATE_PHRASES_SUCCEEDED: 'FETCH_TEMPLATE_PHRASES_SUCCEEDED' = 'FETCH_TEMPLATE_PHRASES_SUCCEEDED'
export const FETCH_TEMPLATE_PHRASES_PENDING: 'FETCH_TEMPLATE_PHRASES_PENDING' = 'FETCH_TEMPLATE_PHRASES_PENDING'
export const FETCH_TEMPLATE_PHRASES_FAILED: 'FETCH_TEMPLATE_PHRASES_FAILED' = 'FETCH_TEMPLATE_PHRASES_FAILED'
export const CLEAR_TEMPLATE_PHRASES_STORE: 'CLEAR_TEMPLATE_PHRASES_STORE' = 'CLEAR_TEMPLATE_PHRASES_STORE'

export const SET_FILTERED_TEMPLATES_KEYS: 'SET_FILTERED_TEMPLATES_KEYS' = 'SET_FILTERED_TEMPLATES_KEYS'

export const CREATE_TEMPLATE_SAGA: 'CREATE_TEMPLATE_SAGA' = 'CREATE_TEMPLATE_SAGA'
export const EDIT_TEMPLATE_SAGA: 'EDIT_TEMPLATE_SAGA' = 'EDIT_TEMPLATE_SAGA'
export const DELETE_TEMPLATE_SAGA: 'DELETE_TEMPLATE_SAGA' = 'DELETE_TEMPLATE_SAGA'
export const TEMPLATE_LIST_CHANGED: 'TEMPLATE_LIST_CHANGED' = 'TEMPLATE_LIST_CHANGED'
