import { Dispatch, useCallback, useEffect } from "react";
import { AnyAction } from "redux";
import { Socket } from "socket.io-client";

export const useWorkerTookChat = (socket: Socket, dispatch: Dispatch<any>) => {
  const handleTookChat = useCallback((data) => dispatch({ ...data }), [dispatch]) // SOCKET_TOOK_CHAT

  useEffect(() => {
    const handler = (data: AnyAction) => { handleTookChat(data) }

    socket.on('worker-took-chat', handler)
    return () => {
      socket.off('worker-took-chat', handler)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
