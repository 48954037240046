import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  supportsListWrapper: {
  },

  supportListHeader: {
    padding: '0 10px',
  },

  grid: {
    display: 'grid',
    gridTemplateColumns: '47px 188px 188px 109px 154px',
    gridGap: '16px',
  },

  supportListHeaderText: {
    color: '#000',
    fontSize: '16px',
    lineHeight: '19px',
    opacity: 0.5,
  },
})

export default makeStyles(styles)
