import { FC, useState } from 'react'
import { Socket } from 'socket.io-client'
import { useAdminSocketConnect, useListenAdminSocketIncoming } from 'src/socket/socketControl'
import AdminArea from './admin-area'

import AdminSidebar from './admin-sidebar'

import useStyles from './styles'

export enum MenuButtons {
  SUPPORTS = 'supports',
  CHAT_LIMITS = 'chat-limits',
  TEMPLATES = 'templates'
}

interface IAdminMenuProps {
  adminSocket: Socket
}

const AdminMenu: FC<IAdminMenuProps> = ({ adminSocket }) => {

  useAdminSocketConnect(adminSocket)
  useListenAdminSocketIncoming(adminSocket)

  const classes = useStyles()
  const [activeNavItem, setActiveNavItem] = useState<MenuButtons>(MenuButtons.SUPPORTS)

  return (
    <div className={classes.adminMenuWrapper}>
      <AdminSidebar
        activeNavItem={activeNavItem}
        setActiveNavItem={setActiveNavItem}
      />
      <AdminArea activeNavItem={activeNavItem} />
    </div>
  )
}

export default AdminMenu
