import { AnyAction as Action } from 'redux'
import jwt_decode from 'jwt-decode'

import { persist } from 'src/store/localforage'

import {
  FETCH_AUTH_DATA_SUCCEEDED,
  FETCH_AUTH_DATA_FAILED, AUTH_LOG_OUT,
  FETCH_AUTH_DATA_PENDING,
  FETCH_REFRESH_TOKEN_SUCCEEDED,
  FETCH_REFRESH_TOKEN_FAILED,
} from 'src/store/types/auth'

import { UserRole } from 'src/common/constants'

export interface IAuthStore {
  token: string,
  data: {
    supportName: string,
    supportAvatar: string,
    supportRole: UserRole,
  },
  error: any,
  pending: boolean
}

const initialState = {
  token: '',
  data: {},
  supportConnected: false,
  error: null,
  pending: false,
}

const authData = (state = initialState, action: Action) => {
  switch (action.type) {
    case FETCH_AUTH_DATA_PENDING: {
      return {
        ...state,
        pending: true,
        error: null,
      }
    }

    case FETCH_AUTH_DATA_SUCCEEDED: {
      const authData: { name: string, avatar: string, role: UserRole } = jwt_decode(action.payload)

      return {
        ...state,
        token: action.payload,
        data: {
          ...state.data,
          supportName: authData.name,
          supportAvatar: authData.avatar,
          supportRole: authData.role,
        },
        pending: false,
        error: null,
      }
    }

    case FETCH_AUTH_DATA_FAILED: {
      return {
        ...state,
        error: action.payload,
        pending: false,
      }
    }

    case FETCH_REFRESH_TOKEN_SUCCEEDED: {
      const authData: { name: string, avatar: string, role: UserRole } = jwt_decode(action.payload)

      return {
        ...state,
        token: action.payload,
        data: {
          ...state.data,
          supportName: authData.name,
          supportAvatar: authData.avatar,
          supportRole: authData.role,
        },
        error: null,
      }
    }

    case FETCH_REFRESH_TOKEN_FAILED: {
      return {
        ...state,
        error: action.payload,
      }
    }

    case AUTH_LOG_OUT: {
      localStorage.removeItem('authToken')

      return {
        ...initialState,
      }
    }

    default:
      return state
  }
}

export default persist('authData', authData)
