import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  messagesListWrapper: {
    overflowY: 'scroll',
    padding: '0 30px',
  },

  noMessagesText: {
    alignSelf: 'center',
    display: 'block',
    marginBottom: '30px',
    color: '#4A4A4A',
    fontSize: '18px',
    lineHeight: '20px',
    textAlign: 'center',
    opacity: 0.7,
  },
})

export default makeStyles(styles)
