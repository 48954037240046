import { FC } from 'react'

import useStyles from './styles'

interface ITumbler {
  checked: boolean
}

const Tumbler: FC<ITumbler> = ({
  checked
}) => {
  const classes = useStyles()

  return (
    <div className={classes.tumblerWrapper}>
      <label className={classes.tumbler}>
        <input type="checkbox" onChange={()=>{}} checked={checked} className={classes.tumblerInput}/>
        <span className={classes.slider}></span>
      </label>
    </div>
  )
}

export default Tumbler
