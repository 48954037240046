import { ReactComponent as FileSuccessUpload } from 'src/media/images/chatFileSuccessIcon.svg'
import { ReactComponent as FilePending } from 'src/media/images/chatFilePendingIcon.svg'
import { ReactComponent as FileErrorUpload } from 'src/media/images/chatFileErrorIcon.svg'

import { FilesStatus } from './types'

import type { IAdaptedFiles } from './types'

export const statusIcons = {
  [FilesStatus.PENDING]: <FilePending />,
  [FilesStatus.SUCCESS]: <FileSuccessUpload />,
  [FilesStatus.ERROR]: <FileErrorUpload />,
}

export const adapter = (files: File[], status: FilesStatus) => {
  return files.map((file: File, index) => ({
    id: index,
    name: file.name,
    path: '',
    size: file.size,
    status: status,
  }))
}

export const singleUploadFile = (filesAdapted: IAdaptedFiles[], fileName: string, status: FilesStatus, resp: any = undefined) => {  
  return filesAdapted.map((file: IAdaptedFiles) => {
    const spreadFile = resp ? { ...resp } : { ...file }

    if(file.name === fileName) {
      return {
        ...spreadFile,
        status: status,
      }
    }

    return file
  })
}

export const truncateFileName = (name: string) => {
  if (name.length > 35) {
    return `${name.slice(0, 25)}...${name.slice(-7)}`
  }

  return name
}